export enum SwatchShape {
  CIRCLE = 'circle',
  SQUARE = 'square',
}

export enum SwatchSize {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
