import fs from 'fs'
import getConfig from 'next/config'
import path from 'path'
import promClient from 'prom-client'
import url from 'url'
import { v5 as uuid, validate as uuidValidate } from 'uuid'
import * as xss from 'xss'

import {
  ConfigFileName,
  CSPMode,
  loadConfigFile,
  SiteDefinitionInterface,
} from '@thg-commerce/enterprise-config'
import {
  getSafeUrlParametersFromReq,
  getSafeUrlParametersFromWindow,
} from '@thg-commerce/enterprise-core/src/urlUtilities/urlUtilities'
import { EnterpriseThemeInterface } from '@thg-commerce/enterprise-theme'

import {
  EnterpriseRequest,
  EnterpriseRequest as Request,
} from '../Server/types'
import {
  getSessionSettingsCookie,
  SessionSettingsCookie,
} from '../utils/SessionSettingsCookie'

import { configurationStorage, themeStorage } from './storage'
import {
  BaseRequestProps,
  Configuration,
  Currency,
  NextConfig,
  RequestPropsConfig,
  ShippingDestination,
  SiteConfigInterface,
} from './types'

const recordConfigLoadMetric = <T = any>(
  fileName: string,
  callback: () => T,
) => {
  const startTime = new Date().getTime()
  const response = callback()
  const endTime = new Date().getTime()

  try {
    let histogram = promClient.register.getSingleMetric(
      'enterprise_config_load_time',
    )
    if (!histogram || !('observe' in histogram)) {
      histogram = new promClient.Histogram({
        name: 'enterprise_config_load_time',
        labelNames: ['path'],
        help:
          'This metric charts the load times of configs loaded by the ConfigurationLoader in Enterprise',
        buckets: [1, 10, 25, 50, 100, 250, 500, 1000, 10000],
      })
    }
    histogram.labels(fileName).observe(endTime - startTime)
  } catch (error) {
    console.warn(error)
  }

  return response
}

const timedReadFileSync = (path: string) => {
  const fileName = path.substring(path.lastIndexOf('/') + 1) || path

  return recordConfigLoadMetric(fileName, () => {
    const config = fs.readFileSync(path, 'utf8')
    return config
  })
}

const fetchConfigObject = (
  brand: string,
  subsite: string,
  fileName: string,
) => {
  const configDirectory = path.posix.join(
    getConfig().serverRuntimeConfig['CONFIGURATION_PATH'],
    subsite ? path.posix.join(brand, subsite) : `${brand}`,
  )
  const filePath = path.posix.join(configDirectory, `${fileName}.json`)

  if (fs.existsSync(configDirectory) && fs.existsSync(filePath)) {
    return JSON.parse(timedReadFileSync(filePath))
  }

  console.warn(
    `warn [ CONFIGURATION ] ${configDirectory}/${filePath} may not exist`,
  )

  return {}
}

const getTranslatedSubsites = (translatedSubsitesConfigPath: string) => {
  if (fs.existsSync(translatedSubsitesConfigPath)) {
    const translatedSubsites: [string, string] = JSON.parse(
      timedReadFileSync(translatedSubsitesConfigPath),
    )
    return Object.entries(translatedSubsites).sort((a, b) =>
      a[1].localeCompare(b[1]),
    )
  }
  return []
}

const generateFolderIdFromPreviewId = (
  themePreviewId: string | null,
  themeUuidNamespace: string,
) => {
  if (!themePreviewId) {
    return ''
  }

  if (!uuidValidate(themeUuidNamespace)) {
    return ''
  }

  return uuid(themePreviewId, themeUuidNamespace)
}

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P]
}

const loadConfigurationWithoutCache = (args: {
  nextConfig: NextConfig
  reqConfig: {
    brand: string
    subsite: string
    hostname: string
    secure: boolean
    host: string | undefined
  }
}): Configuration => {
  const { nextConfig, reqConfig } = args

  Object.freeze(nextConfig.publicRuntimeConfig)
  Object.freeze(nextConfig.serverRuntimeConfig)

  const config: RecursivePartial<Configuration> & NextConfig = {
    ...nextConfig,
    publicRuntimeConfig: {
      ...nextConfig.publicRuntimeConfig,
    },
    serverRuntimeConfig: {
      ...nextConfig.serverRuntimeConfig,
    },
  }

  try {
    const originUrl = xss.filterXSS(
      url.format({
        protocol: reqConfig.secure
          ? 'https'
          : config['serverRuntimeConfig']['FORCE_SSL']
          ? 'https'
          : 'http',
        host: reqConfig.host,
      }),
    )

    const siteDefinition = {
      originUrl,
      brand: reqConfig.brand,
      subsite: reqConfig.subsite,
      hostname: reqConfig.hostname,
      graphQLServerURL: `${originUrl}/graphql`,
    }

    const configDirectory = path.posix.join(
      config['serverRuntimeConfig']['CONFIGURATION_PATH'],
      siteDefinition.subsite
        ? path.posix.join(
            `${siteDefinition.brand}`,
            `${siteDefinition.subsite}`,
          )
        : `${siteDefinition.brand}`,
    )

    const shippingDestinationsPath = path.posix.join(
      configDirectory,
      `${ConfigFileName.SHIPPING_DESTINATIONS}.json`,
    )

    if (
      fs.existsSync(configDirectory) &&
      fs.existsSync(shippingDestinationsPath)
    ) {
      try {
        const shippingDestinationData: ShippingDestination[] = JSON.parse(
          timedReadFileSync(shippingDestinationsPath),
        )
        if (
          config['publicRuntimeConfig'].hasOwnProperty('shippingDestinations')
        ) {
          config['publicRuntimeConfig'][
            'shippingDestinations'
          ] = shippingDestinationData
        }
      } catch {
        config['publicRuntimeConfig']['shippingDestinations'] = []
      }
    } else {
      config['publicRuntimeConfig']['shippingDestinations'] = []

      console.warn(
        `warn [ CONFIGURATION ] ${configDirectory}/${shippingDestinationsPath} may not exist`,
      )
    }

    const data = recordConfigLoadMetric(
      `${ConfigFileName.SITE_DEFINITION}.json`,
      () => {
        return loadConfigFile<SiteDefinitionInterface>({
          brand: siteDefinition.brand,
          subsite: siteDefinition.subsite,
          fileName: ConfigFileName.SITE_DEFINITION,
        })
      },
    )

    if (
      data &&
      config['serverRuntimeConfig'].hasOwnProperty('siteDefinition')
    ) {
      config['serverRuntimeConfig']['siteDefinition'] = {
        CONTENT_GRAPHQL_SERVER_URI: data.CONTENT_GRAPHQL_SERVER_URI || null,
        graphqlApi: data.graphqlApi,
      }
    }

    if (
      data &&
      config['publicRuntimeConfig'].hasOwnProperty('siteDefinition')
    ) {
      const {
        CONTENT_GRAPHQL_SERVER_URI,
        graphqlApi,
        ...publicSiteDefinition
      } = data

      config['publicRuntimeConfig'][
        'shippingDestination'
      ] = validShippingDestination(
        config.publicRuntimeConfig.shippingDestinations,
        '',
        publicSiteDefinition.defaultLocale?.split('_')[1] || '',
      )

      config['publicRuntimeConfig']['siteDefinition'] = {
        ...publicSiteDefinition,
        ...siteDefinition,
        contentRoutePrefix: data.contentRoutePrefix || 'blog',
        GRAPHQL_SERVER_URI: config['publicRuntimeConfig']['GRAPHQL_SERVER_URI']
          ? config['publicRuntimeConfig']['GRAPHQL_SERVER_URI']
          : data['GRAPHQL_SERVER_URI'],
        defaultLocale: data.defaultLocale || 'en_GB',
        defaultSessionSettings: {
          currency: publicSiteDefinition.defaultCurrency || 'GBP',
          shippingDestination:
            config.publicRuntimeConfig.shippingDestination?.code || 'GB',
        },
      }
    }

    config['publicRuntimeConfig'][
      'basketCookieKey'
    ] = `ElysiumBasket${siteDefinition.brand}_V6`

    config['publicRuntimeConfig'][
      'opaqueCookieKey'
    ] = `Opaque_${siteDefinition.brand}_${siteDefinition.subsite}`

    const siteConfigPath = path.posix.join(
      configDirectory,
      `${ConfigFileName.SITE_CONFIG}.json`,
    )

    if (fs.existsSync(configDirectory) && fs.existsSync(siteConfigPath)) {
      const data: SiteConfigInterface = JSON.parse(
        timedReadFileSync(siteConfigPath),
      )

      // Add publicRuntimeConfig URIs to CSP
      if (
        (!process.env.CSP_MODE || process.env.CSP_MODE !== CSPMode.DISABLED) &&
        data?.csp &&
        config['publicRuntimeConfig']['IS_PRODUCTION']
      ) {
        const deepspace = (config['publicRuntimeConfig'][
          'DEEPSPACE_URL'
        ] as string).startsWith('http')
          ? config['publicRuntimeConfig']['DEEPSPACE_URL']
          : `${config.publicRuntimeConfig['siteDefinition']['originUrl']}${config['publicRuntimeConfig']['DEEPSPACE_URL']}`

        const enterpriseConnectAdditions = [deepspace]

        if (config.publicRuntimeConfig.siteDefinition.GRAPHQL_SERVER_URI) {
          enterpriseConnectAdditions.push(
            config.publicRuntimeConfig.siteDefinition.GRAPHQL_SERVER_URI,
          )
        }

        // Add sentry to CSP
        enterpriseConnectAdditions.push('https://*.ingest.sentry.io')

        const enterpriseScriptAdditions: string[] = []
        const enterpriseStyleAdditions: string[] = []

        if (
          (config.publicRuntimeConfig['siteDefinition'][
            'originUrl'
          ] as string).includes('localhost')
        ) {
          enterpriseConnectAdditions.push('localhost:*')
        }

        if (config.publicRuntimeConfig['assetPrefix']) {
          const assetURI = new URL(config.publicRuntimeConfig['assetPrefix'])

          enterpriseConnectAdditions.push(
            `${assetURI.protocol}//${assetURI.hostname}`,
          )
          enterpriseScriptAdditions.push(
            `${assetURI.protocol}//${assetURI.hostname}`,
          )
          enterpriseStyleAdditions.push(
            `${assetURI.protocol}//${assetURI.hostname}`,
          )
        }

        if (typeof data.csp['connect-src'] !== 'undefined') {
          data.csp['connect-src'].push(...enterpriseConnectAdditions)
        } else {
          data.csp['connect-src'] = enterpriseConnectAdditions
        }

        if (typeof data.csp['script-src'] !== 'undefined') {
          data.csp['script-src'].push(...enterpriseScriptAdditions)
        } else {
          data.csp['script-src'] = enterpriseScriptAdditions
        }

        if (typeof data.csp['style-src'] !== 'undefined') {
          data.csp['style-src'].push(...enterpriseStyleAdditions)
        } else {
          data.csp['style-src'] = enterpriseStyleAdditions
        }
      }

      if (config['publicRuntimeConfig'].hasOwnProperty('siteConfig')) {
        // TODO: validate object against a schema
        config['publicRuntimeConfig']['siteConfig'] = {
          ...data,
        }
      }
    } else {
      console.warn(
        `warn [ CONFIGURATION ] ${configDirectory}/${siteConfigPath} may not exist`,
      )
    }

    const subSitesPath = path.posix.join(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        `${siteDefinition.brand}`,
      ),
      `${ConfigFileName.SUBSITES}.json`,
    )
    if (fs.existsSync(configDirectory) && fs.existsSync(subSitesPath)) {
      const data = JSON.parse(timedReadFileSync(subSitesPath))

      if (config['publicRuntimeConfig'].hasOwnProperty('subsites')) {
        // TODO: validate object against a schema
        config['publicRuntimeConfig']['subsites'] = {
          ...data,
        }
      }
    } else {
      console.warn(
        `warn [ CONFIGURATION ] ${configDirectory}/${subSitesPath} may not exist`,
      )
    }

    const hreflangsPath = path.posix.join(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        `${siteDefinition.brand}`,
      ),
      `${ConfigFileName.HREFLANGS}.json`,
    )

    if (fs.existsSync(configDirectory) && fs.existsSync(hreflangsPath)) {
      const data = JSON.parse(timedReadFileSync(hreflangsPath))
      if (config['publicRuntimeConfig'].hasOwnProperty('hreflangs')) {
        // TODO: validate object against a schema
        config['publicRuntimeConfig']['hreflangs'] = { ...data }
      }
    }

    const sessionSettingsPath = path.posix.join(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        `${siteDefinition.brand}`,
      ),
      `${ConfigFileName.SESSION_SETTINGS}.json`,
    )

    if (fs.existsSync(configDirectory) && fs.existsSync(sessionSettingsPath)) {
      const data = JSON.parse(timedReadFileSync(sessionSettingsPath))

      if (config['publicRuntimeConfig'].hasOwnProperty('sessionSettings')) {
        config['publicRuntimeConfig']['sessionSettings'] = { ...data }
      }
    }

    const subsites = config['publicRuntimeConfig']['subsites']

    if (subsites) {
      const subsiteDomains = {}

      Object.keys(subsites).forEach((subsite) => {
        const subsiteDefPath = path.posix.join(
          path.posix.join(
            config['serverRuntimeConfig']['CONFIGURATION_PATH'],
            `${siteDefinition.brand}`,
          ),
          `${subsites[subsite]}`,
          `${ConfigFileName.SITE_DEFINITION}.json`,
        )
        if (fs.existsSync(subsiteDefPath)) {
          const siteDef = JSON.parse(timedReadFileSync(subsiteDefPath))
          Object.assign(subsiteDomains, { [subsite]: siteDef.domain })
        } else {
          console.warn(`warn [ CONFIGURATION ] ${subsiteDefPath} may not exist`)
        }
      })

      config['publicRuntimeConfig']['subsiteDomains'] = subsiteDomains

      config['publicRuntimeConfig']['countryDomainMap'] = Object.keys(
        subsites,
      ).reduce((map, subsiteCountryCode) => {
        map[
          fetchConfigObject(
            siteDefinition.brand,
            subsites[subsiteCountryCode]!,
            ConfigFileName.SITE_DEFINITION,
          ).domain
        ] = subsiteCountryCode
        return map
      }, {})
    }

    const productChoiceConfigPath = path.posix.join(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        `${siteDefinition.brand}`,
      ),
      `${reqConfig.subsite}`,
      `${ConfigFileName.PRODUCT_CHOICE_TYPES}.json`,
    )

    if (fs.existsSync(productChoiceConfigPath)) {
      config.productChoiceTypeMap = JSON.parse(
        timedReadFileSync(productChoiceConfigPath),
      )
    }

    const internationalOverlayPath = path.posix.join(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        siteDefinition.brand,
      ),
      `${ConfigFileName.INTERNATIONAL_OVERLAY}.json`,
    )
    if (fs.existsSync(internationalOverlayPath)) {
      config.publicRuntimeConfig.internationalOverlay = JSON.parse(
        timedReadFileSync(internationalOverlayPath),
      )
    }

    const dataLayerMap = path.posix.join(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        siteDefinition.brand,
      ),
      reqConfig.subsite,
      `${ConfigFileName.DATA_LAYER_MAP_FILE}.json`,
    )

    if (fs.existsSync(dataLayerMap)) {
      config.dataLayerMap = JSON.parse(timedReadFileSync(dataLayerMap))
    }
    const productDescriptionKeysConfigPath = path.posix.join(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        `${siteDefinition.brand}`,
      ),
      `${reqConfig.subsite}/${ConfigFileName.PRODUCT_DESCRIPTION_KEYS}.json`,
    )

    if (fs.existsSync(productDescriptionKeysConfigPath)) {
      config.productDescriptionKeys = JSON.parse(
        timedReadFileSync(productDescriptionKeysConfigPath),
      )
    }

    const contentKeysConfigPath = path.posix.join(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        `${siteDefinition.brand}`,
      ),
      `${reqConfig.subsite}/${ConfigFileName.CONTENT_KEYS}.json`,
    )
    if (fs.existsSync(contentKeysConfigPath)) {
      config.contentKeys = JSON.parse(timedReadFileSync(contentKeysConfigPath))
    }

    const translatedSubsitesConfigPath = path.posix.join(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        siteDefinition.brand,
      ),
      `${ConfigFileName.TRANSLATED_SUBSITES}.json`,
    )
    config['publicRuntimeConfig'][
      'translatedCountryDomain'
    ] = getTranslatedSubsites(translatedSubsitesConfigPath)

    return {
      ...config,
      isCached: false,
    } as Configuration
  } catch (e) {
    throw e
  }
}

export const loadConfiguration = async (
  nextConfig: NextConfig,
  req: EnterpriseRequest | Request,
): Promise<Configuration> => {
  const reqConfig = {
    brand: req.brand || 'whitelabel',
    subsite: req.subsite || 'en',
    hostname: req.hostname,
    secure: req.secure,
    host: req.get('host'),
  }

  const key = `${reqConfig.brand}_${reqConfig.subsite}`

  const cachedConfiguration = await configurationStorage.get(key)

  if (typeof cachedConfiguration === 'undefined') {
    const configuration = loadConfigurationWithoutCache({
      nextConfig,
      reqConfig,
    })

    configurationStorage.set(
      key,
      configuration,
      300000, // 5 minutes
    )

    configuration.isCached = false

    return configuration
  }

  cachedConfiguration.isCached = true

  return cachedConfiguration
}

export const loadTheme = async (
  config: Configuration,
  previewId?: string,
  concessionCode?: string,
): Promise<EnterpriseThemeInterface | undefined> => {
  const siteDefinition = config.publicRuntimeConfig.siteDefinition

  const keyParts = [siteDefinition.brand, siteDefinition.subsite]

  previewId && keyParts.push(previewId)
  concessionCode && keyParts.push(concessionCode)

  const key = keyParts.join('_')

  const cachedTheme = await themeStorage.get(key)

  if (typeof cachedTheme === 'undefined') {
    const theme = loadThemeWithoutCache(config, previewId, concessionCode)

    themeStorage.set(
      key,
      theme,
      300000, // 5 minutes
    )

    return theme
  }

  return cachedTheme
}

export const loadThemeWithoutCache = (
  config: Configuration,
  previewId?: string,
  concessionCode?: string,
): EnterpriseThemeInterface | undefined => {
  const siteDefinition = config.publicRuntimeConfig.siteDefinition
  let resolvedPreviewFilePath: string | undefined
  try {
    if (previewId) {
      const previewPath = path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        `${siteDefinition.brand}`,
        'preview',
      )

      const themePreviewId = generateFolderIdFromPreviewId(
        previewId,
        config?.serverRuntimeConfig?.THEME_UUID_NAMESPACE,
      )

      if (themePreviewId && fs.existsSync(previewPath)) {
        const previewDirFiles: string[] = fs.readdirSync(previewPath)

        // Validate path exists and is in preview directory
        if (previewDirFiles.find((fileName) => fileName === themePreviewId)) {
          resolvedPreviewFilePath = `${siteDefinition.brand}/preview/${themePreviewId}`
        } else {
          console.debug(
            `debug [ ${siteDefinition.brand}:${siteDefinition.subsite} ] : generated uuid ${themePreviewId} from provided previewId ${previewId} does not correspond to any preview file`,
          )
        }
      }
    }
  } catch (error) {
    console.error(
      `error [ ${siteDefinition.brand}:${
        siteDefinition.subsite
      } ] : ${JSON.stringify(error)}`,
    )
  }

  const concessionThemeExists =
    concessionCode &&
    fs.existsSync(
      path.posix.join(
        config['serverRuntimeConfig']['CONFIGURATION_PATH'],
        `${siteDefinition.brand}`,
        'concessions',
        `${concessionCode}`,
        `${ConfigFileName.THEME}.json`,
      ),
    )

  const themePath = path.posix.join(
    config['serverRuntimeConfig']['CONFIGURATION_PATH'],
    typeof resolvedPreviewFilePath === 'string'
      ? resolvedPreviewFilePath
      : concessionThemeExists
      ? path.posix.join(
          `${siteDefinition.brand}`,
          'concessions',
          `${concessionCode}`,
        )
      : siteDefinition.subsite
      ? path.posix.join(`${siteDefinition.brand}`, `${siteDefinition.subsite}`)
      : `${siteDefinition.brand}`,
    `${ConfigFileName.THEME}.json`,
  )

  const configDirectory = path.posix.join(
    config['serverRuntimeConfig']['CONFIGURATION_PATH'],
    siteDefinition.subsite
      ? path.posix.join(`${siteDefinition.brand}`, `${siteDefinition.subsite}`)
      : `${siteDefinition.brand}`,
  )

  if (fs.existsSync(configDirectory) && fs.existsSync(themePath)) {
    try {
      const theme = JSON.parse(timedReadFileSync(themePath))
      if (config['publicRuntimeConfig'].hasOwnProperty('theme')) {
        return theme
      }
    } catch {
      console.warn(
        `warn [ CONFIGURATION ] ${configDirectory}/${themePath} can not load theme file`,
      )
    }
  } else {
    console.warn(
      `warn [ CONFIGURATION ] ${configDirectory}/${themePath} may not exist`,
    )
  }
  return undefined
}

export const loadRequestProps = async (
  nextConfig: NextConfig,
  req: Request,
): Promise<BaseRequestProps | (BaseRequestProps & RequestPropsConfig)> => {
  const config = await loadConfiguration(nextConfig, req)
  const requestConfig = {
    ...req.config,
  }

  const { publicRuntimeConfig } = config
  const { siteDefinition } = publicRuntimeConfig
  const { defaultLocale, siteId, channel, defaultCurrency } = siteDefinition

  const pageProps = getPagePropsForRequest(req)

  return {
    ...(pageProps ? { pageProps } : {}),
    defaultLocale,
    siteId,
    requestConfig,
    channel,
    currency:
      req.config.sessionSettings?.currency || (defaultCurrency as Currency),
    shippingDestination: {
      name: '',
      code:
        req.config.sessionSettings?.shippingDestination ||
        defaultLocale.split('_')[1] ||
        'GB',
    },
  }
}

const getPagePropsForRequest = (req: Request) => {
  const pageProps: { returnToUrl: string } = { returnToUrl: '' }

  const urlParams = getSafeUrlParametersFromReq<{ returnTo: string }>(req, [
    'returnTo',
  ])

  if (Object.values(urlParams).length === 0) {
    return pageProps
  }

  if (typeof urlParams.returnTo === 'string' && urlParams.returnTo.length > 0) {
    pageProps.returnToUrl = urlParams.returnTo
  }

  return pageProps
}

export const getShippingDestination = (
  subsite: string,
  shippingDestinations: ShippingDestination[],
  defaultLocale: string,
  location: string,
) => {
  const currentShippingDestination = getCurrentShippingDestination(
    subsite,
    location,
  )

  return validShippingDestination(
    shippingDestinations,
    currentShippingDestination,
    defaultLocale,
  )
}

const getCurrentShippingDestination = (subSite: string, location: string) => {
  if (typeof window !== 'undefined') {
    const { shippingcountry } = getSafeUrlParametersFromWindow<{
      shippingcountry: string
    }>(window, 'shippingcountry')
    if (shippingcountry) {
      return shippingcountry.toUpperCase()
    }
  }

  return (
    getSessionSettingsCookie(SessionSettingsCookie.Shipping, subSite) ||
    location
  )
}

export const validShippingDestination = (
  availableShippingDestinations: ShippingDestination[],
  shippingDestination: string,
  defaultShippingDestination: string,
): ShippingDestination => {
  let matchedShippingDestination: ShippingDestination | undefined
  let matchedDefaultShippingDestination: ShippingDestination | undefined

  availableShippingDestinations.forEach((destination) => {
    if (destination.code.toLowerCase() === shippingDestination.toLowerCase()) {
      matchedShippingDestination = destination
    }

    if (
      destination.code.toLowerCase() ===
      defaultShippingDestination.toLowerCase()
    ) {
      matchedDefaultShippingDestination = destination
    }
  })

  return (
    matchedShippingDestination ||
    matchedDefaultShippingDestination || { code: 'GB', name: 'United Kingdom' }
  )
}
