import * as React from 'react'

import { ElevationLevel, mq, spacing } from '@thg-commerce/gravity-theme'

import { styled, withTheme } from '../theme'
import { CombinedThemeInterface } from '../'

const StyledHeader = styled.div`
  height: 87px;
  z-index: 1;
  color: white;
  text-align: center;
  -webkit-backface-visibility: hidden;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 16px;

    position: sticky;
  }

  &::before {
    top: 55px;
    ${ElevationLevel(2)}
  }

  &::after {
    background: ${(props) => props.theme.colors.palette.greys.lighter};
    top: 0;
    z-index: 2;
  }
`

const StyledHeaderCover = styled.div`
  background: ${(props) => props.theme.patterns.simpleHeader.backgroundColor};
  height: 71px;
  padding: 20px;
  position: sticky;
  top: 0px;
  margin-top: -16px;
  z-index: 3;
`

const HeaderContentArea = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  max-width: ${(props) => props.theme.site.siteWidth};
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding: 0 ${spacing(2)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    justify-content: flex-start;
  }
`

const LogoLink = styled.a`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 ${spacing(1)};

  svg,
  img {
    height: ${(props) => props.theme.patterns.simpleHeader.simpleLogo.height};
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.palette.greys.darker};
    outline-offset: -2px;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: 0 ${spacing(2)};

    svg {
      min-width: initial;
      width: initial;
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    flex: 0;
    margin: ${(props) =>
        props.theme.patterns.simpleHeader.simpleLogo.verticalMargin}
      0;
    flex-basis: 25%;
    justify-content: flex-start;
  }
`

interface SimpleHeaderI18nText {
  logoAriaLabel: string
}

interface Props {
  i18nText: SimpleHeaderI18nText
  theme: CombinedThemeInterface
  setHeight: Function
  hideBranding?: boolean
}

/** @component */
export const SimpleHeader = withTheme((props: Props) => {
  // const headerRef = React.useRef<HTMLDivElement>(null)
  // const [height, setHeight] = React.useState(0)

  // const updateHeight = () => {
  //   if (
  //     headerRef.current &&
  //     height !== headerRef.current.getBoundingClientRect().height
  //   ) {
  //     setHeight(headerRef.current.getBoundingClientRect().height)
  //     props.setHeight(headerRef.current.getBoundingClientRect().height)
  //   }
  // }

  // React.useEffect(() => {
  //   updateHeight()
  // }, [])

  // @ts-ignore
  // React.useEffect(() => {
  //   if (typeof Window !== 'undefined') {
  //     if (headerRef != null && headerRef.current != null) {
  //       window.addEventListener('resize', updateHeight)

  //       return () => {
  //         window.removeEventListener('resize', updateHeight)
  //       }
  //     }
  //   }
  // }, [typeof Window !== 'undefined' && window.innerWidth])

  return (
    <StyledHeader data-testid={`simple-header-container`}>
      <StyledHeaderCover data-testid={`simple-header-cover`}>
        <HeaderContentArea>
          {!props.hideBranding && (
            <LogoLink
              aria-label={props.i18nText.logoAriaLabel || 'Home Page'}
              data-testid="logo-link"
              href="/"
              dangerouslySetInnerHTML={{
                __html:
                  (props.theme.patterns.simpleHeader.type !== 'alternate' &&
                    props.theme.logo.logoUri &&
                    `<img src="${props.theme.logo.logoUri}" alt="" width="auto" height={${props.theme.patterns.simpleHeader.simpleLogo.height}} />`) ||
                  (props.theme.logo.logoAlternateUri &&
                    `<img src="${props.theme.logo.logoAlternateUri}" alt="" width="auto" height={${props.theme.patterns.simpleHeader.simpleLogo.height}} />`) ||
                  props.theme.logo.logoSVG ||
                  '',
              }}
            />
          )}
        </HeaderContentArea>
      </StyledHeaderCover>
    </StyledHeader>
  )
})

export default SimpleHeader
