import { Property } from 'csstype'

import { css, styled } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, Text } from '@thg-commerce/gravity-theme'

import { SwatchShape, SwatchSize } from './types'

export interface SwatchStyledProps {
  background: string
  selected: boolean
  swatchShape: SwatchShape
  swatchSize: SwatchSize
  disabled?: boolean
  borderColor?: string
  noBorder?: boolean
}

export interface SwatchStyleInterface {
  borderColor?: Property.Color
  backgroundColor?: Property.Color
}

const formatSwatchCount = (
  colours: number,
  threshold: number,
  i18nText?: {
    moreText?: string
    moreTextAlt?: string
  },
) => {
  const remaining = colours - threshold
  return colours > threshold
    ? `content: "+ ${remaining} ${i18nText?.moreText}${
        remaining === 1 ? '' : i18nText?.moreTextAlt
      }"`
    : `content: ""`
}

export const SwatchesLeftText = styled.div`
  ${Text('small', 'default')};
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${(props) => props.theme.colors.palette.greys.dark};
`

export const SwatchesLeftNumber = styled.span<{
  colours: number
  i18nText?: {
    moreText?: string
    moreTextAlt?: string
  }
}>`
  ::after {
    ${(props) => formatSwatchCount(props.colours, 4, props.i18nText)}
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ::after {
      ${(props) => formatSwatchCount(props.colours, 5, props.i18nText)}
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ::after {
      ${(props) => formatSwatchCount(props.colours, 7, props.i18nText)}
    }
  }
`

export const SwatchElementContainer = styled.div<{
  swatchSize: SwatchSize
  persistOnOneRow?: boolean
}>`
  margin: ${(props) =>
    props.swatchSize === SwatchSize.ExtraSmall ||
    props.swatchSize === SwatchSize.Small
      ? '0'
      : props.swatchSize === SwatchSize.Medium
      ? `0 ${spacing(0.75)} ${spacing(0.75)} 0`
      : `0 ${spacing(1)} ${spacing(1)} 0`};

  ${(props) => props.persistOnOneRow && PersistOnOneRowStyle}
`

const PersistOnOneRowStyle = css`
  &:nth-of-type(n + 5) {
    display: none;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    &:nth-of-type(n + 5) {
      display: block;
    }
    &:nth-of-type(n + 6) {
      display: none;
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    &:nth-of-type(n + 6) {
      display: block;
    }
    &:nth-of-type(n + 8) {
      display: none;
    }
  }
`

export const SwatchContainer = styled.div<{
  hideColours?: boolean
  showOnlyTwoRows?: boolean
  hideSwatches?: boolean
  paddingBottom?: number
}>`
  width: 100%;
  display: ${(props) => (props.hideSwatches ? 'none' : 'flex')};
  flex-wrap: wrap;
  ${(props) =>
    !props.showOnlyTwoRows &&
    props.paddingBottom &&
    `padding-bottom: ${spacing(props.paddingBottom || 2.5)};`}

  ${(props) =>
    props.hideColours &&
    `
      ${mq(props.theme.breakpointUtils.map, 'xs')} {
        max-height: 112px;
        overflow: hidden;
      }
      ${mq(props.theme.breakpointUtils.map, 'sm')} {
        max-height: none;
        overflow: visible;
        padding-bottom: ${spacing(props.paddingBottom || 2.5)};
      }
    `}
`
export const ExpandButton = styled.button`
  padding: 0 0 ${spacing(2)};
  text-decoration: underline
    ${(props) => props.theme.colors.palette.greys.darker};
  ${(props) =>
    Text(props.theme.formgroup.label.scale, props.theme.formgroup.label.style)}
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`

const circleSwatchAttribute = css`
  border-radius: 50%;
`

const selectedAttribute = css`
  background-color: #fff;
  border: 2px solid
    ${(props) =>
      props.theme.elements.colorSwatches?.borderColor ||
      props.theme.colors.palette.brand.base};
`

export const SwatchElementButton = styled.button<SwatchStyledProps>`
  display: inline-flex;
  ${(props) =>
    props.swatchShape === SwatchShape.CIRCLE && circleSwatchAttribute}

  &:focus-visible {
    outline: none;
    & > span {
      border: 2px solid
        ${(props) =>
          props.theme.elements.colorSwatches?.borderColor ||
          props.theme.colors.palette.brand.base};
    }
  }
`

export const OuterSwatchElement = styled.span<SwatchStyledProps>`
  display: inline-flex;

  ${(props) =>
    props.swatchSize === SwatchSize.ExtraSmall
      ? `
      height: 24px;
      width: 24px;
    `
      : props.swatchSize === SwatchSize.Small
      ? `
      height: 32px;
      width: 32px;
    `
      : props.swatchSize === SwatchSize.Medium
      ? `
      height: 40px;
      width: 40px;
    `
      : `
      height: 48px;
      width: 48px;
    `}

  ${(props) =>
    props.swatchShape === SwatchShape.CIRCLE && circleSwatchAttribute}

  ${(props) => props.selected && selectedAttribute}

  &:hover {
    background-color: ${(props) =>
      props.selected
        ? props.theme.elements.colorSwatches?.backgroundColor ||
          props.theme.colors.palette.brand.lighter
        : props.theme.elements.colorSwatches?.backgroundColor ||
          props.theme.colors.palette.brand.lightest};
    border: ${(props) => (props.selected ? '3' : '2')}px solid
      ${(props) =>
        props.theme.elements.colorSwatches?.borderColor ||
        props.theme.colors.palette.brand.base};
  }

  &:focus-visible {
    outline: none;
    background-color: ${(props) =>
      props.selected
        ? props.theme.colors.palette.brand.lighter
        : props.theme.colors.palette.brand.lightest};
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  &:active {
    background-color: ${(props) =>
      props.selected
        ? props.theme.elements.colorSwatches?.backgroundColor ||
          props.theme.colors.palette.brand.dark
        : props.theme.elements.colorSwatches?.backgroundColor ||
          props.theme.colors.palette.brand.base};
    border: 2px solid
      ${(props) =>
        props.theme.elements.colorSwatches?.borderColor ||
        props.theme.colors.palette.brand.base};
  }
`

interface DisabledStyleProps {
  borderWidth: string
  elementSize: string
  swatchShape: SwatchShape
}

export const InnerSwatchElement = styled.span<
  SwatchStyledProps & DisabledStyleProps
>`
  margin: auto;
  ${(props) => `
    height: ${props.elementSize};
    width: ${props.elementSize};
    `}
  background: ${(props) => props.background};

  ${(props) =>
    props.swatchShape === SwatchShape.CIRCLE && circleSwatchAttribute}
  border: ${(props) =>
    props.noBorder
      ? `0.5px solid ${props.theme.colors.palette.tertiary.base}`
      : `1px solid ${
          props.borderColor ||
          props.theme.elements.colorSwatches?.borderColor ||
          props.theme.colors.palette.brand.base
        }`};

  &:focus-visible {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`
export const SwatchDisableWrapper = styled.div<{
  borderWidth: string
  swatchSize?: SwatchSize
  noBorder?: boolean
}>`
  position: absolute;
  z-index: 5;
  ${(props) =>
    props.noBorder
      ? `
    top: 3px;
    left: 3px;
    `
      : `
    top: calc(${props.borderWidth}/2);
    left: calc(${props.borderWidth}/2);
  `}

  width: ${(props) =>
    props.swatchSize === SwatchSize.Medium
      ? `calc(${props.borderWidth}*11)`
      : `calc(${props.borderWidth}*11)`};
  height: ${(props) =>
    props.swatchSize === SwatchSize.Medium
      ? `calc(${props.borderWidth}*11)`
      : `calc(${props.borderWidth}*11)`};

  svg {
    width: 100%;
    height: 100%;
  }
`
