import * as React from 'react'

import {
  ButtonState,
  styling,
} from '@thg-commerce/gravity-elements/Button/Button'
import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

interface SafeHtmlProps {
  tagsToRemove?: string[]
  removeTagsAndContent?: boolean
  onTag?: (tag: string, html: string, options: OnTagOptions) => string | null
  __dangerouslyAllowedTags?: string[]
  __dangerouslyAllowedAttrs?: {
    [key: string]: string[]
  }
  className?: string
  'data-testid'?: string
  renderedAs?: 'p' | 'h1' | 'h2' | 'span'
}

export interface OnTagOptions {
  isWhite: boolean
  isClosing: boolean
  position: number
  sourcePosition: number
}

const StyledText = styled.div`
  ${(props) =>
    Text(
      props.theme.titleSubtext.font.entry,
      props.theme.titleSubtext.font.style,
    )}

  * {
    ${(props) =>
      Text(
        props.theme.titleSubtext.font.entry,
        props.theme.titleSubtext.font.style,
      )}

    b, strong {
      ${(props) => Text(props.theme.titleSubtext.font.entry, 'alternate')}
    }
    color: inherit;
  }

  b,
  strong {
    ${(props) => Text(props.theme.titleSubtext.font.entry, 'alternate')}
  }

  p {
    margin: ${spacing(1)} 0;

    ${(props) =>
      props.theme?.titleSubtext?.links.default.textDecoration &&
      `a {
        text-decoration: ${props.theme.titleSubtext.links.default.textDecoration};
      }`}
  }

  ul {
    list-style: initial;
    margin-top: ${spacing(1)};
    margin-left: ${spacing(2)};
  }

  ol {
    list-style: auto;
    margin-top: ${spacing(3)};
    margin-left: ${spacing(2)};
  }

  table {
    display: table;
    margin-top: ${(props) =>
      spacing(props.theme.widget.simpleText.description.table?.marginTop || 7)};
    width: 100%;
    overflow-x: auto;

    thead {
      ${Text('bodyText', 'alternate')}

      th {
        ${Text('bodyText', 'alternate')}
        padding: ${spacing(1)};
        border: 1px solid ${(props) => props.theme.colors.palette.greys.light};
      }
    }

    tr {
      background-color: ${(props) => props.theme.colors.palette.greys.white};
    }

    tr:nth-child(even) {
      background-color: ${(props) => props.theme.colors.palette.greys.lighter};
    }

    td {
      padding: ${spacing(1)};
      border: 1px solid ${(props) => props.theme.colors.palette.greys.light};
    }
  }
  a {
    ${Text('bodyText', 'alternate')}
    ${(props) =>
      styling({ theme: props.theme, sizing: 'regular', emphasis: 'low' })};
    &:hover {
      ${(props) =>
        styling(
          { theme: props.theme, sizing: 'regular', emphasis: 'low' },
          ButtonState.Hover,
        )};
    }

    &:focus {
      ${(props) =>
        styling(
          { theme: props.theme, sizing: 'regular', emphasis: 'low' },
          ButtonState.Focus,
        )};
    }
    &:active {
      ${(props) =>
        styling(
          { theme: props.theme, sizing: 'regular', emphasis: 'low' },
          ButtonState.Active,
        )};
    }
    &:disabled {
      ${(props) =>
        styling(
          { theme: props.theme, sizing: 'regular', emphasis: 'low' },
          ButtonState.Disabled,
        )};
    }
  }
`

export const SafeHtml = React.forwardRef(
  (props: SafeHtmlProps & { content: string }, ref?: any) => {
    return (
      <StyledText
        ref={ref}
        dangerouslySetInnerHTML={{ __html: props.content }}
        className={props.className}
        data-testid={props['data-testid'] || 'styled-text'}
        as={props.renderedAs}
      />
    )
  },
)
