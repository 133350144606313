import * as React from 'react'
import loadable from '@loadable/component'
import { Property } from 'csstype'
import dynamic from 'next/dynamic'
import { v4 as uuid } from 'uuid'

import { styled, useTheme } from '@thg-commerce/gravity-system/theme'
import {
  css,
  Padding,
  padding,
  Text,
  TextStyling,
} from '@thg-commerce/gravity-theme'

import { AccordionThemeInterface } from './theme'

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const ChevronDown = dynamic<React.SVGProps<SVGSVGElement>>(() =>
  import('@thg-commerce/gravity-icons/src/components/ChevronDown').then(
    (mod) => mod.SvgChevronDown,
  ),
)

export enum AccordionBorderPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  UNDER = 'under',
  FULL = 'full',
  NONE = 'none',
}

interface AccordionProps {
  title: React.ReactNode | string
  subtitleElement?: React.ReactNode
  id: string
  headerTextColor?: Property.Color
  chevronIconColor?: Property.Color
  isOpen?: boolean
  borderPlacement?: AccordionBorderPlacement
  onClick?: (id: string) => void
  onOpenAccordion?: (id: string) => void
  children: React.ReactChildren | React.ReactNode | React.ReactElement | Element
  themeOverride?: AccordionThemeInterface
  focusRef?: React.RefObject<HTMLDivElement>
}

const noPadding = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

export const Title = styled.label<{
  expandedAccordion: boolean
  textStyle: TextStyling
  borderPlacement?: AccordionBorderPlacement
  padding?: Padding
}>`
  ${(props) => Text(props.textStyle.entry, props.textStyle.style)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  text-align: left;
  ${(props) => padding(props.padding || noPadding)};

  ${(props) => {
    const borderBottom =
      props.borderPlacement === AccordionBorderPlacement.BOTTOM ||
      props.borderPlacement === AccordionBorderPlacement.UNDER
        ? `border-bottom: 1px solid ${props.theme.colors.palette.greys.light};`
        : ''
    const borderTop =
      props.borderPlacement === AccordionBorderPlacement.TOP
        ? `border-top: 1px solid ${props.theme.colors.palette.greys.light};`
        : ''
    return `${borderBottom} ${borderTop}`
  }}

  color: ${(props) => props.textStyle.textColor};

  ${(props) =>
    props.expandedAccordion &&
    props.borderPlacement === AccordionBorderPlacement.UNDER &&
    'border: none;'};
`

const Content = styled.div<{
  expandedAccordion: boolean
  padding: Padding
  borderPlacement?: AccordionBorderPlacement
}>`
  display: none;
  max-height: 0;
  visibility: hidden;
  ${(props) => padding(props.padding)};

  ${(props) =>
    props.expandedAccordion &&
    css`
      display: block;
      max-height: 100%;
      visibility: initial;
      ${props.borderPlacement === AccordionBorderPlacement.UNDER &&
      `border-bottom: 1px solid ${props.theme.colors.palette.greys.dark};`}
    `}
`

const ChevronContainer = styled.div<{
  expandedAccordion: boolean
}>`
  width: 24px;
  height: 24px;
  ${(props) => props.expandedAccordion && 'transform: rotate(180deg);'}
`

const StyledButton = styled.button`
  width: 100%;

  &:focus-visible {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

const getTextTheme = (
  headerTextColor?: Property.Color,
  themeOverride?: AccordionThemeInterface,
) => ({
  entry: themeOverride?.title.textStyling?.entry || 'bodyText',
  style: themeOverride?.title.textStyling?.style || 'alternate',
  textColor:
    headerTextColor || themeOverride?.title.textStyling?.textColor || 'inherit',
  transform: themeOverride?.title.textStyling?.transform || 'none',
  textDecoration: themeOverride?.title.textStyling?.textDecoration || 'none',
})

const getContentPadding = (themeOverrideContent, defaultThemeContent) =>
  themeOverrideContent?.padding || defaultThemeContent?.padding || noPadding

const renderChevron = (chevronTheme, iconColor) => {
  if (chevronTheme?.svgPath) {
    return (
      <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        viewBox={chevronTheme.viewBox}
        width={chevronTheme.width}
        height={chevronTheme.height}
      >
        <path d={chevronTheme.svgPath} fillRule="evenodd" />
      </SvgIcon>
    )
  }
  return <ChevronDown aria-hidden="true" focusable="false" fill={iconColor} />
}

const handleOnClick = (
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>,
  buttonId: string,
  onOpenAccordion?: (id: string) => void,
) => {
  setExpanded((prev) => !prev)
  if (onOpenAccordion) {
    onOpenAccordion(buttonId)
  }
}

export const Accordion: React.FunctionComponent<AccordionProps> = (props) => {
  const [expanded, setExpanded] = React.useState(props.isOpen || false)
  const defaultTheme = useTheme()
  const id = uuid()
  const buttonId = `${id}-button`
  const borderPlacement =
    props.borderPlacement || AccordionBorderPlacement.BOTTOM
  const chevronTheme =
    props.themeOverride?.chevronIcon ||
    defaultTheme.components.accordion.chevronIcon

  return (
    <div id={`${props.id}-${id}`} ref={props.focusRef} data-testid="Accordion">
      <StyledButton
        id={buttonId}
        onClick={() =>
          handleOnClick(setExpanded, buttonId, props.onOpenAccordion)
        }
        aria-expanded={expanded}
      >
        <Title
          data-testid="accordion-title"
          htmlFor={buttonId}
          expandedAccordion={expanded}
          borderPlacement={borderPlacement}
          textStyle={getTextTheme(props.headerTextColor, props.themeOverride)}
          padding={
            props.themeOverride?.title.padding ||
            defaultTheme.components.accordion.title.padding
          }
        >
          {props.title}
          <ChevronContainer expandedAccordion={expanded}>
            {renderChevron(chevronTheme, props.chevronIconColor)}
          </ChevronContainer>
        </Title>
        {props.subtitleElement}
      </StyledButton>
      <Content
        tabIndex={0}
        expandedAccordion={expanded}
        borderPlacement={borderPlacement}
        padding={getContentPadding(
          props.themeOverride?.content,
          defaultTheme?.components.accordion.content,
        )}
      >
        {props.children}
      </Content>
    </div>
  )
}
