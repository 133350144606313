import { ZIndexLevel } from '@thg-commerce/gravity-theme'

interface HeaderZIndexLevelType {
  Triangle: ZIndexLevel
  Tooltip: ZIndexLevel
  Header: ZIndexLevel
  DesktopNavOverlay: ZIndexLevel
  Overlay: ZIndexLevel
  Dropdown: ZIndexLevel
  Flyout: ZIndexLevel
  Background: ZIndexLevel
}

/** Components across header use the following references to Gravity Z Index Levels for setting z-index values, in order to maintain consistency across the pattern. */
export const HeaderZIndexLevel: Readonly<HeaderZIndexLevelType> = {
  Triangle: ZIndexLevel.Lowest,
  Tooltip: ZIndexLevel.Low,
  Header: ZIndexLevel.High,
  DesktopNavOverlay: ZIndexLevel.Base5,
  Overlay: ZIndexLevel.Higher,
  Dropdown: ZIndexLevel.Highest,
  Flyout: ZIndexLevel.Highest + 10,
  Background: ZIndexLevel.Lowest,
}

export enum DropdownOptions {
  BASKET = 'basket',
  ACCOUNT = 'account',
  SEARCH = 'search',
}

export type HeaderSlot =
  | HeaderSlotLink
  | HeaderSlotDropdown
  | HeaderSlotModal
  | HeaderSlotIcon

export enum HeaderSlotType {
  LINK = 'LINK',
  DROPDOWN = 'DROPDOWN',
  MODAL = 'MODAL',
  ICON = 'ICON',
}

export enum HeaderSlotDropdownType {
  ACCOUNT = 'ACCOUNT',
  BASKET = 'BASKET',
}

export enum HeaderSlotModalType {
  SESSION_SETTINGS = 'SESSION_SETTINGS',
}

export interface HeaderSlotLink {
  textProperty: string
  type: HeaderSlotType.LINK
  urlProperty: string
}

export interface HeaderSlotDropdown {
  textProperty: string
  type: HeaderSlotType.DROPDOWN
  dropdownType: HeaderSlotDropdownType
}

export interface HeaderSlotModal {
  textProperty: string
  type: HeaderSlotType.MODAL
  modalType: HeaderSlotModalType
}

export interface HeaderSlotIcon {
  type: HeaderSlotType.ICON
  iconName: string
}

export type PromotionalProducts = {
  contentType: string
  index: number
  content: {
    heading: string
    content: {
      image: {
        url: string
        alt: string
      }
      link: string
      fallbackText: string
      label: string
      openLinkInNewWindow: boolean
      textColour: {
        colour: string
        palette: string
        __typename?: string
      }
      backgroundColour: {
        colour: string
        palette: string
        __typename?: string
      }
    }[]
  }
}
