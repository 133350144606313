import * as React from 'react'

import { Margin } from '@thg-commerce/gravity-theme/margin'
import { Padding } from '@thg-commerce/gravity-theme/padding'

import { IconStyling } from '../Header/theme'
import { Font } from '../index'
import { useTheme } from '../theme'

import { MarketedSpecialOfferDirection } from './MarketedSpecialOffer/MarketedSpecialOffer'
import {
  MarketedSpecialOffer,
  MarketedSpecialOfferSkeleton,
} from './MarketedSpecialOffer'
import { MarketedSpecialOfferModal } from './MarketedSpecialOfferModal'

export interface LandingPageLink {
  text: string
  url: string
  openExternally: boolean
  noFollow: boolean
}

export interface Images {
  largeProduct: string
}

export interface Rrp {
  displayValue: string
}

export interface FreeGiftProduct {
  images: Images[]
  title: string
  price: {
    rrp: Rrp
  }
}

export interface CloseI18nText {
  closeAriaLabel: string
  closeLabel: string
}

export interface I18nText {
  closeI18nText: CloseI18nText
  freeGiftMessage?: string
  rrpText?: string
}

export interface MarketedSpecialOfferSummaryContainerProps {
  title: string
  description?: string
  border?: { width: string; type: string }
  landingPageLink?: LandingPageLink
  freeGiftProduct?: FreeGiftProduct
  loading?: boolean
  i18nText: I18nText
  showIcon?: boolean
  onlyDisplayOfferBadge?: boolean
  reduceBottomMarginContainer?: boolean
  shouldRemoveScrollLock?: boolean
  className?: string
  badgeStyleOverride?: {
    icon?: IconStyling
    textStyle?: Font
    padding?: Padding
    border?: { width: string; type: string }
    margin?: Margin
    textAlign?: string
  }
  containerStyleOverride?: {
    border?: {
      width: string
      type: string
    }
    margin?: Margin
  }
  modalStyleOverride?: {
    display?: string
    maxWidth?: string
    margin?: Margin
    textStyle?: Font
    textAlign?: string
    descriptionColorText?: string
  }
  handleEvents?: () => void
  isInView?: boolean
  hideCTAOnMarketSpecialOffer?: boolean
}

export const MarketedSpecialOfferSummaryContainer = React.forwardRef(
  (props: MarketedSpecialOfferSummaryContainerProps, ref?: any) => {
    const [
      marketedSpecialOfferOpen,
      setMarketedSpecialOfferOpen,
    ] = React.useState(false)
    const theme = useTheme()

    if (props.loading) {
      return <MarketedSpecialOfferSkeleton />
    }

    return (
      <React.Fragment>
        <MarketedSpecialOffer
          className={props.className}
          title={props.title}
          description={props.description}
          cta={
            props.landingPageLink && {
              text: props.landingPageLink.text,
              href: props.landingPageLink.url,
              openExternally: props.landingPageLink.openExternally,
              noFollow: props.landingPageLink.noFollow,
            }
          }
          handleEvents={props.handleEvents}
          isInView={props.isInView}
          freeGiftProduct={props.freeGiftProduct}
          i18nFreeGiftMessage={props.i18nText?.freeGiftMessage}
          direction={MarketedSpecialOfferDirection.Column}
          imageWidth={144}
          showIcon={props.showIcon}
          onlyDisplayOfferBadge={props.onlyDisplayOfferBadge}
          onBadgeClick={(event) => {
            event?.stopPropagation()
            event?.preventDefault()
            setMarketedSpecialOfferOpen(true)
          }}
          badgeStyle={{
            ...theme.patterns.marketedSpecialOffer.badge,
            ...(props.badgeStyleOverride || {}),
          }}
          containerStyle={{
            ...theme.patterns.marketedSpecialOffer.container,
            ...(props.containerStyleOverride || {}),
          }}
          modalStyle={{
            ...theme.patterns.marketedSpecialOffer.modal,
            ...(props.modalStyleOverride || {}),
          }}
          reduceBottomMarginContainer={props.reduceBottomMarginContainer}
          hideCTAOnMarketSpecialOffer={props.hideCTAOnMarketSpecialOffer}
          ref={ref}
        />

        {marketedSpecialOfferOpen && (
          <MarketedSpecialOfferModal
            title={props.title}
            description={props.description}
            cta={
              props.landingPageLink && {
                text: props.landingPageLink.text,
                href: props.landingPageLink.url,
                openExternally: props.landingPageLink.openExternally,
                noFollow: props.landingPageLink.noFollow,
              }
            }
            freeGiftProduct={props.freeGiftProduct}
            i18nText={props.i18nText}
            open={marketedSpecialOfferOpen}
            onClose={() => {
              setMarketedSpecialOfferOpen(false)
            }}
            shouldRemoveScrollLock={props.shouldRemoveScrollLock}
            maxWidth={
              props.modalStyleOverride?.maxWidth ??
              theme.patterns.marketedSpecialOffer.modal?.maxWidth
            }
            textAlign={
              props.modalStyleOverride?.textAlign ??
              theme.patterns.marketedSpecialOffer?.modal?.textAlign
            }
            badgeStyle={{
              ...{
                ...theme.patterns.marketedSpecialOffer.badge,
                border: undefined,
              },
              ...(props.badgeStyleOverride || {}),
            }}
            modalStyle={{
              ...theme.patterns.marketedSpecialOffer.modal,
              ...(props.modalStyleOverride || {}),
            }}
            margin={{
              ...theme.patterns.marketedSpecialOffer.modal,
              ...(props.modalStyleOverride?.margin || {}),
            }}
          />
        )}
      </React.Fragment>
    )
  },
)
