import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import axios from 'axios'

import { addToDataLayer } from '@thg-commerce/enterprise-metrics'
import { Customer } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Customer'
import {
  Feature,
  MainCustomerQueryVariables,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { createSubscribableRef } from '@thg-commerce/enterprise-utils'

import { useSiteConfig } from '../ConfigurationLoader'
import { useEnterpriseContext } from '../EnterpriseContext'
import { Customer as CUSTOMER_QUERY } from '../graphql/Customer.graphql'

import { CustomerContext, CustomerMetaData } from './CustomerContext'

export const CustomerProvider: React.FunctionComponent<{
  children: React.ReactNode
}> = (props) => {
  const siteConfig = useSiteConfig()
  const { horizonFeatures } = useEnterpriseContext()
  const customerMetaData = createSubscribableRef<CustomerMetaData | undefined>({
    customerLocation: 'unknown',
  })

  const { data, loading } = useQuery<
    {
      customer: Customer
    },
    MainCustomerQueryVariables & { enableHashedEmail: boolean }
  >(CUSTOMER_QUERY, {
    variables: {
      enableWishlists: horizonFeatures?.includes(Feature.Wishlist) || false,
      enableLoyaltyAccountDashboard:
        horizonFeatures?.includes(Feature.Loyalty) || false,
      enableHashedEmail: siteConfig.useHashedEmail || false,
    },
    ssr: false,
  })

  const {
    value: [_, setCustomerMetaData],
    subscribe,
    unsubscribe,
  } = customerMetaData

  React.useEffect(() => {
    axios
      .get<CustomerMetaData>('/e2/customer-metadata', {
        validateStatus: () => true,
        timeout: 1000,
      })
      .then((response) => {
        if (response.status !== 200 || !response.data) {
          return
        }
        setCustomerMetaData(response.data)
      })
      .catch(() => {
        // Fail silently
      })
  }, [])

  React.useEffect(() => {
    const callback = (value: CustomerMetaData | undefined) => {
      if (!value) {
        return
      }

      addToDataLayer({
        key: 'visitorLocation',
        value: value.customerLocation,
      })
    }

    subscribe(callback)

    return () => {
      unsubscribe(callback)
    }
  }, [])

  React.useEffect(() => {
    if (!data?.customer) {
      return
    }

    addToDataLayer({
      key: 'visitorLoginState',
      value: 'loggedin',
    })
    addToDataLayer({
      key: 'visitorEmailAddress',
      value: data.customer.hashedEmail,
    })
    addToDataLayer({
      key: 'visitorType',
      value: data.customer.hashedEmail !== '' ? 'Existing' : 'unknown',
    })
  }, [data])

  return (
    <CustomerContext.Provider
      value={data?.customer || (loading ? null : undefined)}
    >
      {props.children}
    </CustomerContext.Provider>
  )
}

export const useLoginCheck = () => {
  return typeof React.useContext(CustomerContext)?.firstName === 'string'
}
