import * as React from 'react'

import { SafeHtml } from '@thg-commerce/gravity-elements'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { spacing, Text } from '@thg-commerce/gravity-theme'

export interface FooterNotesProps {
  footerNotesTermsPolicyText: string
}

const FooterNotesContainer = styled.div`
  width: 100%;
  display: inline-block;
  padding: ${spacing(2)};
  margin: auto;
  text-align: center;
  background-color: ${(props) =>
    props.theme.patterns.footer.footerNotes.backgroundColor};
  ${(props) =>
    `border-top: ${props.theme.patterns.footer.footerNotes.separator.width} solid
      ${props.theme.patterns.footer.footerNotes.separator.color};`}
`

const StyledSafeHtml = styled(SafeHtml)`
  ${(props) =>
    Text(
      props.theme.patterns.footer.footerNotes.font.entry ?? 'bodyText',
      props.theme.patterns.footer.footerNotes.font.style ?? 'default',
    )};
  color: ${(props) => props.theme.patterns.footer.footerNotes.font.textColor};
  text-transform: ${(props) =>
    props.theme.patterns.footer.footerNotes.font.transform};

  a {
    color: ${(props) => props.theme.patterns.footer.footerNotes.font.textColor};

    &:hover {
      background-color: ${(props) =>
        props.theme.patterns.footer.footerNotes.hoverBackgroundColor};
      color: ${(props) =>
        props.theme.patterns.footer.footerNotes.hoverTextColor};
      text-decoration: ${(props) =>
        props.theme.patterns.footer.footerNotes.hoverTextDecoration};
    }

    &:focus-visible {
      color: ${(props) =>
        props.theme.patterns.footer.footerNotes.focusTextColor};
      border: ${(props) =>
        `${props.theme.patterns.footer.footerNotes.borderFocusWidth} 
        solid 
        ${
          props.theme.patterns.footer.footerNotes.borderFocusColor ||
          props.theme.colors.palette.brand.base
        }`};
    }
  }
`

export const FooterNotes = (props: FooterNotesProps) => {
  if (!props.footerNotesTermsPolicyText) {
    return null
  }

  return (
    <FooterNotesContainer>
      <StyledSafeHtml content={props.footerNotesTermsPolicyText} />
    </FooterNotesContainer>
  )
}
