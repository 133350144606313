import * as React from 'react'
import dynamic from 'next/dynamic'
import ResizeObserver from 'resize-observer-polyfill'

import { Button } from '@thg-commerce/gravity-elements'
import {
  css,
  ElevationLevel,
  mq,
  spacing,
  styled,
} from '@thg-commerce/gravity-theme'

export enum Appearance {
  light,
  dark,
  default,
}

enum Direction {
  top,
  bottom,
  left,
  right,
}

type Position = [Direction, Direction]
type ElementProp = {
  height: number
  width: number
  top: number
  left: number
  right: number
  marginTop: string | number
  marginBottom: string | number
}

const toolTipWidth = 340
const toolTipWidthMobile = 220
const toolTipMinHeight = 200
const defaultTargetSize = 34
const tooltipAndElementSpacing = 10

const CloseIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/IconDelete'),
)

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  outline: none;
`

const backgroundColourValue = css<{
  appearance?: Appearance
}>`
  ${(props) =>
    props.appearance === Appearance.light
      ? props.theme.colors.palette.greys.white
      : props.appearance === Appearance.dark
      ? props.theme.colors.palette.greys.darker
      : props.theme.colors.palette.accent.darker}
`

const leftStyle = css<{
  targetProp: ElementProp
}>`
  right: 0;

  &::after {
    right: ${({ targetProp }) => targetProp.width / 2 - 10}px;
    left: auto;
  }
`

const rightStyle = css<{
  targetProp: ElementProp
}>`
  left: 0;

  &::after {
    left: ${({ targetProp }) => targetProp.width / 2 - 10}px;
    right: auto;
  }
`

const bottomStyle = css<{
  targetProp: ElementProp
}>`
  top: ${({ targetProp }) => targetProp.height + tooltipAndElementSpacing}px;
`

const topStyle = css<{
  targetProp: ElementProp
  appearance?: Appearance
}>`
  bottom: ${({ targetProp }) => targetProp.height + tooltipAndElementSpacing}px;

  &::after {
    right: 0;
    left: auto;
    top: auto;
    border-top-color: ${backgroundColourValue};
    border-bottom-color: transparent;
    bottom: -${spacing(2)};
  }
`

const topRightStyle = css<{
  targetProp: ElementProp
}>`
  &::after {
    left: ${({ targetProp }) => targetProp.width / 2 - 10}px;
    right: auto;
  }
`

const topLeftStyle = css<{
  targetProp: ElementProp
}>`
  &::after {
    right: ${({ targetProp }) => targetProp.width / 2 - 10}px;
    left: auto;
  }
`

const TooltipContainer = styled.div<{
  position: Position
  targetProp: ElementProp
  appearance?: Appearance
}>`
  ${ElevationLevel(2)};
  background-color: ${backgroundColourValue};
  color: ${(props) =>
    props.appearance === Appearance.light
      ? props.theme.colors.palette.greys.darker
      : props.theme.colors.palette.greys.white};
  border-radius: 4px;
  width: max-content;
  max-width: ${toolTipWidthMobile}px;
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    max-width: ${toolTipWidth}px;
  }
  position: absolute;
  z-index: 9;
  display: flex;
  align-items: center;
  gap: ${spacing(1)};
  padding: ${spacing(2)};
  &::after {
    content: '';
    border: 10px solid transparent;
    border-bottom-color: ${backgroundColourValue};
    position: absolute;
    top: -${spacing(2)};
    left: ${({ targetProp }) => targetProp.width / 2 - 10}px;
  }

  ${({ position }) => {
    const [positionY, positionX] = position
    return css`
      ${positionX === Direction.left && leftStyle}
      ${positionX === Direction.right && rightStyle}
      ${positionY === Direction.bottom && bottomStyle}
      ${positionY === Direction.top && topStyle}
      ${positionY === Direction.top &&
      positionX === Direction.right &&
      topRightStyle}
      ${positionY === Direction.top &&
      positionX === Direction.left &&
      topLeftStyle}
    `
  }}
`

const StyledButton = styled(Button)`
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    padding: 0;
  }
`

interface TooltipProps {
  content: React.ReactElement
  triggerContent: React.ReactElement
  appearance?: Appearance
  onChange?: (open: boolean) => void
  onClose?: () => void
  i18nCloseButtonText: string
  className?: string
}

const contentPosition = ({ left, top }): Position => {
  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight

  const right = screenWidth - left

  const shouldShowOnLeft = left > right
  const shouldShowOnTop = screenHeight - top < toolTipMinHeight

  return [
    shouldShowOnTop ? Direction.top : Direction.bottom,
    shouldShowOnLeft ? Direction.left : Direction.right,
  ]
}

export const Tooltip = React.forwardRef((props: TooltipProps, ref: any) => {
  const [open, setOpen] = React.useState<boolean>(false)

  const close = () => {
    tooltipRef?.current?.children[0]?.['focus']()
    setOpen(false)
  }

  React.useImperativeHandle(ref, () => ({
    close() {
      open && setOpen(false)
    },
  }))

  const [trigger, setTrigger] = React.useState<HTMLDivElement>()
  const [targetProp, setTargetProp] = React.useState<ElementProp>({
    height: 0,
    width: 0,
    left: 0,
    top: 0,
    right: 0,
    marginTop: 0,
    marginBottom: 0,
  })
  const tooltipRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!tooltipRef?.current) {
      return
    }

    let resizeObserver: ResizeObserver | undefined
    resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const {
        height = defaultTargetSize,
        width = defaultTargetSize,
        left,
        top,
        right,
      } = entries[0]?.contentRect
      setTargetProp({
        height,
        width,
        left,
        top,
        right,
        marginTop: 0,
        marginBottom: 0,
      })
    })
    resizeObserver.observe(tooltipRef.current)

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [open])

  const [position, setPosition] = React.useState<Position>([
    Direction.bottom,
    Direction.right,
  ])

  React.useEffect(() => {
    props.onChange?.(open)
  }, [open])

  const onClick = (event) => {
    event.persist && event.persist()
    if (
      trigger === event.currentTarget ||
      trigger === tooltipRef!.current ||
      trigger === undefined
    ) {
      const { left, top } = tooltipRef!.current!.getBoundingClientRect()
      setPosition(contentPosition({ left, top }))
      event.stopPropagation && event.stopPropagation()
      setTrigger(event.currentTarget || tooltipRef!.current)
      setOpen(!open)
    }
  }

  return (
    <Wrapper
      className={props.className}
      ref={tooltipRef}
      aria-live="polite"
      data-testid="tooltip"
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        event.persist()
        if (event.key === 'Esc' || event.key === 'Escape') {
          if (open) event.stopPropagation()
          setOpen(false)
          tooltipRef?.current?.children[0]?.['focus']()
        }
      }}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        if (!tooltipRef?.current?.contains(event.relatedTarget as Node)) {
          setTimeout(() => {
            setOpen(false)
            props.onClose?.()
          }, 100)
        }
      }}
    >
      {React.cloneElement(props.triggerContent, {
        onClick,
        ...props.triggerContent.props,
        'aria-expanded': open,
      })}

      {open && (
        <TooltipContainer
          data-testid="tooltip-container"
          position={position}
          targetProp={targetProp}
          appearance={props.appearance}
          aria-expanded={true}
        >
          <div data-testid="tooltip-content">{props.content}</div>
          <StyledButton
            data-testid="tooltip-close-button"
            emphasis="low"
            type="button"
            sizing="micro"
            ariaLabel={props.i18nCloseButtonText}
            onClick={() => close()}
          >
            <CloseIcon />
          </StyledButton>
        </TooltipContainer>
      )}
    </Wrapper>
  )
})
