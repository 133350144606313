import * as React from 'react'
import { Property } from 'csstype'
import { v4 as uuid } from 'uuid'

import { useSiteConfig, useTheme } from '@thg-commerce/enterprise-core'
import { Modal, ModalProps } from '@thg-commerce/gravity-elements'
import { useScrollLock } from '@thg-commerce/gravity-system'
import { Margin } from '@thg-commerce/gravity-theme'

import { ModalContext } from '../ModalProvider'

export interface ModalRendererProps {
  returnToRef?: React.RefObject<HTMLElement>
  onClose?: (() => Promise<boolean>) | (() => void)
  onDelayedClose?: () => void
  onStayOpen?: () => void
  delayClose?: boolean
  /** Callback function when the close modal button is clicked */
  onCloseEventEmitter?: () => void
  renderContent: (close: () => void) => React.ReactNode
  /** If included this is used as the close button Icon */
  'data-testid'?: string
  /** Used to account for header offset when using modals */
  headerOffset?: number
  /** Used to a deactivate the focus trap and allow the click event to do its thing. **/
  clickOutsideDeactivates?: boolean
  /** If true when modal closes it will not remove the scroll lock **/
  shouldRemoveScrollLock?: boolean
  /** Used to pass i18n text to close button */
  closeI18nText: {
    closeAriaLabel?: string
    closeLabel?: string
  }
  gridColSpan: number[]
  /** Used to display header and footer of modal */
  showHeader?: boolean
  showFooter?: boolean
  /** Sets whether the header is sticky or not - only has meaning when showHeader is true */
  stickyHeader?: boolean
  /** Text for cancel and confirm buttons */
  buttonText?: {
    confirmButton: string
    cancelButton: string
  }
  contentPadding?: string
  /** Actions for buttons */
  confirmAction?: () => void
  cancelAction?: () => void
  leftAlignButtons?: boolean
  removeCloseButton?: boolean
  modalMaxWidth?: string
  footerPadding?: string
  content?: ModalProps['content']
  verticalAlignment?: 'top' | 'center'
  isOpen?: boolean
  gridRowStart?: number[]
  gridRowSpan?: number[]
  allowOverflow?: boolean
  removeFocusTrap?: boolean
  margin?: Margin
  modalHeight?: Property.Height<string>
}

export const ModalRenderer = (props: ModalRendererProps) => {
  const theme = useTheme()
  const context = React.useContext(ModalContext)
  const [isOpen, toggleOpen] = React.useState(props.isOpen || false)
  const id = React.useMemo(() => uuid(), [])
  const setScrollLock = useScrollLock()

  const closeModal = React.useCallback(
    (close?: () => void) => {
      context.closeModal(id)
      close && close()
      toggleOpen(false)
      typeof document !== 'undefined' &&
        typeof window !== 'undefined' &&
        setScrollLock(false, document, window)
    },
    [context, id, setScrollLock],
  )

  const onClose = React.useCallback(async () => {
    if (props.delayClose && props.onClose) {
      const confirmClose = await props.onClose()
      if (confirmClose) {
        closeModal(props.onDelayedClose && props.onDelayedClose)
      }
      props.onStayOpen && props.onStayOpen()
    } else {
      closeModal(props.onClose && props.onClose)
    }
  }, [closeModal, props])

  const onConfirm = () => {
    closeModal()
    props.confirmAction && props.confirmAction()
  }

  React.useEffect(() => {
    context.showModal({
      id,
      onClose,
      stateToggle: toggleOpen,
    })
  }, [context, id, onClose])
  const { removeFocusTrap } = useSiteConfig()
  return (
    <Modal
      removeFocusTrap={props.removeFocusTrap || removeFocusTrap || false}
      data-testid={props['data-testid']}
      open={isOpen}
      onClose={() => {
        onClose()
      }}
      onCloseEventEmitter={props.onCloseEventEmitter}
      headerOffset={props.headerOffset ?? theme.site.headerHeight}
      gridColSpan={props.gridColSpan}
      returnToRef={props.returnToRef || React.createRef()}
      i18nText={props.closeI18nText}
      clickOutsideDeactivates={props.clickOutsideDeactivates}
      shouldRemoveScrollLock={props.shouldRemoveScrollLock}
      showHeader={props.showHeader}
      showFooter={props.showFooter}
      buttonText={props.buttonText}
      confirmAction={onConfirm}
      cancelAction={props.cancelAction}
      leftAlignButtons={props.leftAlignButtons}
      removeCloseButton={props.removeCloseButton}
      contentPadding={props.contentPadding}
      allowOverflow={props.allowOverflow}
      footerPadding={props.footerPadding}
      stickyHeader={props.stickyHeader}
      content={props.content}
      modalMaxWidth={props.modalMaxWidth}
      verticalAlignment={props.verticalAlignment}
      gridRowStart={props.gridRowStart}
      gridRowSpan={props.gridRowSpan}
      margin={props.margin}
      gridItemHeight={props.modalHeight}
    >
      {props.renderContent(onClose)}
    </Modal>
  )
}
