import * as React from 'react'

export interface CustomerMetaData {
  customerLocation: string
}

interface CustomerContext {
  firstName?: string
  email?: string
  wishlist?: {
    itemCount: number
    skus: number[]
  }
  emailMarketingPreference?: boolean
  smsMarketingPreference?: boolean
  loyalty?: {
    pointsToNextTier?: number | null
  }
}

export const CustomerContext = React.createContext<
  // Added null as default to indicate it's not yet set and undefined if they aren't logged in. Means we'll know that we're still loading the customer
  CustomerContext | undefined | null
>(null)
