import { Property } from 'csstype'

import { Margin } from '@thg-commerce/gravity-theme/margin'
import { Padding } from '@thg-commerce/gravity-theme/padding'

import { IconStyling } from '../Header/theme'
import { Font } from '../index'

export interface MarketedSpecialOfferInterface {
  badge: {
    icon?: IconStyling
    textStyle: Font
    padding: Padding
    margin: Margin
    border?: { width: string; type: string }
    textAlign?: string
    borderRadius: string
  }
  container: {
    border?: {
      width: string
      type: string
    }
    margin?: Margin
  }
  modal?: {
    display?: string
    direction?: string
    alignment?: string
    width?: string
    maxWidth?: string
    margin?: Margin
    textStyle?: Font
    textAlign?: string
    descriptionColorText?: Property.Color
  }
}

export const theme: MarketedSpecialOfferInterface = {
  badge: {
    textStyle: {
      entry: 'bodyText',
      style: 'default',
      transform: 'none',
      textDecoration: 'none',
    },
    padding: {
      top: [1],
      right: [1],
      bottom: [1],
      left: [1],
    },
    margin: {
      top: 0,
      bottom: 0,
    },
    textAlign: 'start',
    borderRadius: '0',
  },
  container: {
    border: {
      width: '1px',
      type: 'solid',
    },
    margin: {
      top: 0,
    },
  },
  modal: {
    display: 'block',
    width: '100%',
    margin: {
      left: 0,
      right: 0,
    },
    textStyle: {
      entry: 'large1',
      style: 'alternate',
      transform: 'none',
      textDecoration: 'none',
    },
    textAlign: 'start',
    descriptionColorText: '#666666',
  },
}
