import * as React from 'react'

export const MatalanShareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M12.4297 2.19531L16.4922 6.25781C16.7266 6.49219 16.7266 6.92188 16.4922 7.15625C16.2578 7.39062 15.8281 7.39062 15.5938 7.15625L12.625 4.14844V14.5C12.625 14.8516 12.3125 15.125 12 15.125C11.6484 15.125 11.375 14.8516 11.375 14.5V4.14844L8.36719 7.15625C8.13281 7.39062 7.70312 7.39062 7.46875 7.15625C7.23438 6.92188 7.23438 6.49219 7.46875 6.25781L11.5312 2.19531C11.7656 1.96094 12.1953 1.96094 12.4297 2.19531ZM4.5 3.25H5.75C6.0625 3.25 6.375 3.5625 6.375 3.875C6.375 4.22656 6.0625 4.5 5.75 4.5H4.5C3.79688 4.5 3.25 5.08594 3.25 5.75V19.5C3.25 20.2031 3.79688 20.75 4.5 20.75H19.5C20.1641 20.75 20.75 20.2031 20.75 19.5V5.75C20.75 5.08594 20.1641 4.5 19.5 4.5H18.25C17.8984 4.5 17.625 4.22656 17.625 3.875C17.625 3.5625 17.8984 3.25 18.25 3.25H19.5C20.8672 3.25 22 4.38281 22 5.75V19.5C22 20.9062 20.8672 22 19.5 22H4.5C3.09375 22 2 20.9062 2 19.5V5.75C2 4.38281 3.09375 3.25 4.5 3.25Z"
      fill="#383838"
    ></path>{' '}
  </svg>
)
export default MatalanShareIcon
