import * as React from 'react'
import getConfig from 'next/config'

import {
  Country,
  Currency,
  Flag,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Extensions } from '@thg-commerce/enterprise-network/types'
import { useSubscribableRef } from '@thg-commerce/enterprise-utils/src/SubscribableRef'

import { EnterpriseContext } from './EnterpriseContext'
import { HorizonSessionSettings } from './types'

export const useExtensions = () => {
  const { extensionsRef } = React.useContext(EnterpriseContext)
  return useSubscribableRef(extensionsRef)
}

export const useExperiments = () => {
  const extensions = useExtensions()
  const experiments = React.useMemo(() => extensions?.experiments || {}, [
    extensions,
  ])

  const [currentExperiments, setCurrentExperiments] = React.useState(
    experiments,
  )

  React.useEffect(() => {
    if (!extensions?.ray && currentExperiments !== experiments) {
      setCurrentExperiments(experiments)
    }
  }, [extensions, experiments, currentExperiments])

  return currentExperiments
}

export const useFlags = () => {
  const extensions = useExtensions()

  if (extensions?.flags?.length) {
    return extensions?.flags
  }

  const { publicRuntimeConfig } = getConfig()
  return publicRuntimeConfig.FLAGS || []
}

export const useHeaderHeight = () => {
  const { headerHeightRef } = React.useContext(EnterpriseContext)
  return useSubscribableRef(headerHeightRef)
}

export const useHorizonSessionSettings = (): HorizonSessionSettings => {
  const { currency, shippingDestination } = React.useContext(EnterpriseContext)
  // console.log('>>>>>>>currency', currency)
  // console.log('>>>>>>>shippingDestination', shippingDestination)

  return {
    currency: currency as Currency,
    shippingDestination: shippingDestination?.code as Country,
  }
}

export const useEnterpriseContext = () => {
  return React.useContext(EnterpriseContext)
}

export const useTheme = () => {
  const { theme } = useEnterpriseContext()
  return theme
}
