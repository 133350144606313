import * as React from 'react'
import { useApolloClient } from '@apollo/react-hooks'

import {
  EnterpriseContext,
  i18n,
  useHorizonSessionSettings,
  useLogger,
  useSiteConfig,
  useToastPresenter,
  useTransmit,
} from '@thg-commerce/enterprise-core'
import { useBackendEventNormaliser } from '@thg-commerce/enterprise-metrics'
import { BackendMetricArguments } from '@thg-commerce/enterprise-metrics/src/backend_event'
import type { EnhancedTrackingEvent } from '@thg-commerce/enterprise-metrics/src/data_layer'
import {
  productEventsCategory,
  pushFulfilmentData,
  pushToDataLayer,
  pushToEnhancedDataLayer,
  pushToEventGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import {
  AddToBasketData,
  AddToBasketProduct,
  AddToBasketVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/AddToBasket'
import {
  Feature,
  FulfilmentMethod,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Beacon } from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'
import { Extensions } from '@thg-commerce/enterprise-network/types'
import { ProductType } from '@thg-commerce/enterprise-network/types/enterpriseTypes'
import { calculateDiscount } from '@thg-commerce/enterprise-utils'
import { ToastBannerType } from '@thg-commerce/gravity-elements'

import { BasketContext, BasketContextInterface } from '../../BasketContext'
import { useBasket } from '../useBasketId'

import { AddProductsToBasket as ADD_PRODUCTS_TO_BASKET_MUTATION } from './AddProductsToBasket.graphql'
import { CartInteractionEventType } from './initialAddEvent'
import { buildAddToBasketQuery } from './queries'

interface AddToBasketMutationData {
  addProductsToBasket: {
    id: string
    items: {
      product: {
        productCategory: string
        sku: number
        title: string
        inStock: boolean
        product: {
          sku: number
          url: string
          images: {
            largeProduct: string
          }[]
          variants: {
            sku: number
          }[]
        }
      }
      quantity: number
      totalStandardPrice: {
        amount: string
      }
    }[]
    standardPrice: {
      amount: string
    }
    totalQuantity: number
    extensions: Extensions
  }
}

export interface UseAddToBasketOptions {
  errorHandler?: (error: Error) => void
  forceAddToBasket?: boolean
  initialSelectedOptions?: { [optionKey: string]: string }
  setSelectedOptionsCallback?: (selectedOptions) => void
  setSelectedVariantCallback?: (selectedVariant) => void
  setOptionsCallback?: (options) => void
  fullProductData?: boolean
  displayNotifyWhenInStock?: boolean
}

export type AddToBasketItem = {
  sku: string
  quantity: number
}

interface AddToCartTrackingEvent extends EnhancedTrackingEvent {
  ecommerce: {
    currencyCode: string
    add: {
      products: {
        name: string
        id: number | string
        price: string
        quantity: number
        brand: { name: string }
      }[]
    }
  }
}

export enum InteractionLocation {
  WISHLIST = 'wishlist',
  PDP = 'pdp',
  BASKET = 'basket',
  FREQUENTLY_BOUGHT_TOGETHER = 'frequentlyBoughtTogether',
  PRODUCT_LIST = 'productList',
}

export const useAddToBasket = (options?: UseAddToBasketOptions) => {
  const {
    presentAddedToBasketModal,
    presentQuickBuyModal,
    presentAuroraQuickBuyModal,
    basketIdRef,
  } = React.useContext(BasketContext)
  const { extensionsRef, horizonFeatures } = React.useContext(EnterpriseContext)
  const {
    enhancedEcommerceEnabled,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    qubit,
    useGA4EnhancedEcom,
    enableSponsoredAds,
    showParentTitleAddToCartGA4,
    alternateProductKeysForCategories = [],
    enableClickAndCollect,
  } = useSiteConfig()
  const sessionSettings = useHorizonSessionSettings()
  const apolloClient = useApolloClient()
  const logger = useLogger()
  const presentToast = useToastPresenter()
  const normaliseBackendEvent = useBackendEventNormaliser()
  const { basket } = useBasket()
  const transmit = useTransmit()
  const clickAndCollectEnabled =
    (horizonFeatures?.includes(Feature.ClickAndCollect) &&
      enableClickAndCollect) ||
    false

  const processing = React.useRef(false)

  const errorMessage = i18n('basket.addtobasket.error.text')

  const [getBasketId, setBasketId] = basketIdRef.value

  const {
    value: [getExtensions],
  } = extensionsRef

  const getProducts = (
    data: {
      [key: string]: AddToBasketData
    },
    skus: AddToBasketItem[],
  ) => {
    const products: AddToBasketProduct[] = []

    if (data) {
      for (const [_, addToBasketProduct] of Object.entries(data)) {
        products.push(addToBasketProduct.product)
      }
    } else {
      throw Error(
        `Unrecoverable state ${JSON.stringify({
          skus: skus.map((sku) => sku.sku),
        })}`,
      )
    }
    return products
  }
  const isInvalidateProduct = (product: AddToBasketProduct) => {
    return (
      product.sku &&
      !options?.forceAddToBasket &&
      product.productType === ProductType.COMPLEX
    )
  }

  const transmitCartInteractionEvent = (
    eventData: {
      fromRecommendations?: boolean
      location?: string
    },
    buildTransmitMessage: (
      subtype: CartInteractionEventType['subtype'],
    ) => BackendMetricArguments<CartInteractionEventType>,
  ) => {
    transmit({
      type: 'cart_interaction_event',
      payload: normaliseBackendEvent<CartInteractionEventType>(
        buildTransmitMessage('initial_add'),
      ),
    })

    if (eventData.fromRecommendations) {
      transmit({
        type: 'cart_interaction_event',
        payload: normaliseBackendEvent<CartInteractionEventType>(
          buildTransmitMessage('add_from_recommendation'),
        ),
      })
    }

    if (eventData.location === InteractionLocation.WISHLIST) {
      transmit({
        type: 'cart_interaction_event',
        payload: normaliseBackendEvent<CartInteractionEventType>(
          buildTransmitMessage('wishlist_add'),
        ),
      })
    }
  }

  const handleQuickbuyModal = ({
    presentQuickBuyModal,
    presentAuroraQuickBuyModal,
    options,
    fromRecommendations,
    onBasketChangeBeacon,
    trackingUrl,
    sku,
    quantity,
    enableMobilePDPStickyAddToBasket,
  }: {
    presentQuickBuyModal: BasketContextInterface['presentQuickBuyModal']
    presentAuroraQuickBuyModal: BasketContextInterface['presentAuroraQuickBuyModal']
    sku: string
    quantity: number
    fromRecommendations: boolean
    options?: UseAddToBasketOptions
    onBasketChangeBeacon?: Beacon
    trackingUrl?: string
    enableMobilePDPStickyAddToBasket?: boolean
  }) => {
    if (
      options?.initialSelectedOptions &&
      Object.keys(options.initialSelectedOptions).length
    ) {
      presentAuroraQuickBuyModal?.current({
        fromRecommendations,
        onBasketChangeBeacon,
        trackingUrl,
        sku,
        quantity,
        enableMobilePDPStickyAddToBasket,
        initialSelectedOptions: options.initialSelectedOptions,
        setSelectedOptionsCallback: options?.setSelectedOptionsCallback,
        setSelectedVariantCallback: options?.setSelectedVariantCallback,
        setOptionsCallback: options?.setOptionsCallback,
        fullProductData: options?.fullProductData,
        displayNotifyWhenInStock: options?.displayNotifyWhenInStock,
      })
    } else {
      presentQuickBuyModal?.current(
        sku,
        quantity,
        fromRecommendations,
        onBasketChangeBeacon,
        trackingUrl,
        enableMobilePDPStickyAddToBasket,
      )
    }
  }

  const pushToEventGA4Handler = (
    products: AddToBasketProduct[],
    eventData: {
      fromRecommendations?: boolean
      location?: string
      item_list?: string
    },
  ) => {
    const [product] = products

    useGA4EnhancedEcom &&
      pushToEventGA4({
        event: 'ecom_event',
        event_name: 'add_to_cart',
        interactionLocation: eventData?.location || '',
        ecommerce: {
          currencyCode: sessionSettings.currency,
          items: [
            {
              index: 0,
              item_name: showParentTitleAddToCartGA4
                ? product.parentTitle
                : product.title,
              item_id:
                (useExternalIdentifier || useExternalIdentifierInSchema) &&
                product.parentExternalIdentifier
                  ? product.parentExternalIdentifier
                  : product.sku.toString(),
              item_variant:
                (useExternalIdentifier || useExternalIdentifierInSchema) &&
                product.externalIdentifier
                  ? product.externalIdentifier
                  : product.sku.toString(),
              item_list_name: eventData?.item_list || product.parentTitle,
              discount: calculateDiscount(
                product?.rrp.amount,
                product?.price.amount,
              ),
              price: parseFloat(product.price.amount) || undefined,
              item_brand: product.brand,
              quantity: product.quantity,
              item_category: product.productCategory,
              item_external_id:
                product.externalIdentifier || product.sku.toString(),
              item_currency: sessionSettings.currency,
              item_page: eventData?.location || '',
              ...(product.content?.length &&
                productEventsCategory(
                  product.content,
                  alternateProductKeysForCategories,
                )),
            },
          ],
        },
      })
  }

  const execute = async (
    skus: AddToBasketItem[],
    eventData: {
      fromRecommendations?: boolean
      location?: string
      item_list?: string
    } = {},
    fulfilmentType: FulfilmentMethod = FulfilmentMethod.HomeDelivery,
    storeId?: string,
    onBasketChangeBeacon?: Beacon,
    trackingUrl?: string,
    productContent: ProductContentItem[] = [],
    enableMobilePDPStickyAddToBasket?: boolean,
  ) => {
    if (processing.current) {
      return
    }

    processing.current = true

    try {
      skus.forEach((sku) => {
        if (sku.quantity < 1) {
          throw Error('Invalid product quantity. Must be > 0')
        }
      })

      const { data } = await apolloClient.query<
        { [key: string]: AddToBasketData },
        Omit<AddToBasketVariables, 'sku' | 'quantity'>
      >({
        query: buildAddToBasketQuery(skus),
        variables: {
          ...sessionSettings,
          clickAndCollectEnabled,
          productContentKeys: alternateProductKeysForCategories,
        },
      })

      const products = getProducts(data, skus)

      const isSingleProduct = products.length === 1

      if (isSingleProduct && isInvalidateProduct(products[0])) {
        handleQuickbuyModal({
          presentQuickBuyModal,
          presentAuroraQuickBuyModal,
          options,
          onBasketChangeBeacon,
          enableMobilePDPStickyAddToBasket,
          trackingUrl,
          fromRecommendations: Boolean(eventData?.fromRecommendations),
          sku: products[0].sku.toString(),
          quantity: skus[0].quantity,
        })
        processing.current = false
        return
      }

      products.forEach((product) => {
        if (product && isInvalidateProduct(product)) {
          throw Error(
            `Unrecoverable state ${JSON.stringify({
              skus: skus.map((sku) => sku.sku),
            })}`,
          )
        }
      })

      const { data: mutationData } = await apolloClient.mutate<
        AddToBasketMutationData
      >({
        mutation: ADD_PRODUCTS_TO_BASKET_MUTATION,
        variables: {
          sessionSettings,
          basketId: getBasketId(),
          items: skus,
          fulfilmentInput: {
            storeId,
            method: fulfilmentType,
          },
        },
      })

      if (mutationData?.addProductsToBasket?.id) {
        const resultExtensions =
          mutationData.addProductsToBasket.extensions || getExtensions()

        const buildTransmitMessage = (
          subtype: CartInteractionEventType['subtype'],
        ): BackendMetricArguments<CartInteractionEventType> => {
          const extensions = getExtensions()
          return {
            basket,
            eventData: {
              subtype,
              type: 'cart_interaction_event',
              items: products.map((product) => ({
                quantity: product.quantity,
                product_group: {
                  id: product.parentSku ?? product.sku,
                  selected_variant: {
                    price: {
                      currency: sessionSettings.currency,
                      value: Number(product.price.amount),
                    },
                    sku: product.sku,
                  },
                  total_variants: product.variantCount,
                },
              })),
            },
            experiments: resultExtensions?.experiments,
            rays: resultExtensions?.ray ? [resultExtensions.ray] : undefined,
            requestData: {
              ...(resultExtensions?.LoggerLinkData || {
                start_timestamp: Date.now(),
                duration_ms: 0,
              }),
              url: trackingUrl ?? window.location.href,
            },
          }
        }

        if (enhancedEcommerceEnabled) {
          pushToEnhancedDataLayer<AddToCartTrackingEvent>({
            event: 'addToCart',
            ecommerce: {
              currencyCode: sessionSettings.currency,
              add: {
                products: products.map((product) => {
                  const homeDeliveryAvailable = product?.eligibleForFulfilmentMethods
                    ? Boolean(
                        product?.eligibleForFulfilmentMethods?.includes(
                          FulfilmentMethod.HomeDelivery,
                        ),
                      )
                    : true
                  const clickAndCollectAvailable = product?.eligibleForFulfilmentMethods
                    ? Boolean(
                        product?.eligibleForFulfilmentMethods?.includes(
                          FulfilmentMethod.CollectInStore,
                        ),
                      )
                    : false

                  const productViewData = pushFulfilmentData(
                    product.inStock,
                    homeDeliveryAvailable,
                    clickAndCollectAvailable,
                    fulfilmentType,
                  )
                  return {
                    quantity: product.quantity,
                    name: product.title,
                    id:
                      (useExternalIdentifier ||
                        useExternalIdentifierInSchema) &&
                      product.externalIdentifier
                        ? product.externalIdentifier
                        : product.sku.toString(),
                    price: product.price.amount,
                    brand: product.brand,
                    category: product.productCategory,
                    ...(product.content?.length &&
                      productEventsCategory(
                        product.content,
                        alternateProductKeysForCategories,
                      )),
                    ...(clickAndCollectEnabled && { ...productViewData }),
                  }
                }),
              },
            },
          })
        }

        pushToEventGA4Handler(products, eventData)

        transmitCartInteractionEvent(eventData, buildTransmitMessage)

        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'success',
            eventCategory: 'Product | AddToBasket',
            eventLabel: products[0].sku.toString(),
            eventLabelValue: undefined,
          },
        })

        if (window.uv && qubit?.enabled) {
          window.uv.emit('ecBasketItemAction', {
            action: 'add',
            product: {
              productId:
                products[0].parentSku?.toString() ?? products[0].sku.toString(),
              sku: products[0].sku.toString(),
            },
            quantity: products[0].quantity,
          })
        }

        if (enableSponsoredAds && onBasketChangeBeacon) {
          navigator.sendBeacon(onBasketChangeBeacon.url)
        }

        setBasketId(mutationData.addProductsToBasket.id)

        if (isSingleProduct) {
          presentAddedToBasketModal?.current(
            String(products[0].sku),
            products[0].quantity,
          )
        }

        processing.current = false
        return
      }
    } catch (error) {
      logger.warn(
        `An error occurred when adding a product to basket: ${error.message}`,
      )

      if (options?.errorHandler) {
        options.errorHandler(error)
      } else {
        presentToast({
          message: errorMessage,
          bannerType: ToastBannerType.DANGER,
        })
      }

      processing.current = false
    }
  }

  return {
    execute,
  }
}
