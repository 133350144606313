import * as React from 'react'

import { styled } from '@thg-commerce/gravity-theme'

export interface GridItemProps {
  children: React.ReactNode
  /** Defines the column where the item starts  */
  colStart?: number[] | number
  /** Defines how many columns the item spans (should not be used with `colEnd` - but does take precedence) */
  colSpan?: number[] | number
  /** Defines the row where the item starts  */
  rowStart?: number[] | number
  /** Defines which of the standardised breakpoints will be used by the GridItem  */
  breakpoints?: string[]
  rowSpan?: number[] | number
  theme?
  className?: string
  style?: React.CSSProperties
  tabIndex?: number
  onKeyDown?: (event: React.KeyboardEvent) => void
  gridItemRef?: React.Ref<HTMLDivElement>
  justifySelf?: string
}

interface ItemProps {
  children: React.ReactNode
  /** Defines the column where the item starts  */
  colStart?: number[] | number
  /** Defines how many columns the item spans (should not be used with `colEnd` - but does take precedence) */
  colSpanNumber?: number[] | number
  /** Defines the row where the item starts  */
  rowStart?: number[] | number
  /** Defines which of the standardised breakpoints will be used by the GridItem  */
  breakpoints?: string[]
  rowSpanNumber?: number[] | number
  theme?
  className?: string
  style?: React.CSSProperties
}

interface MediaQueryArgs {
  lowerBreakpoint
  breakpoint
  colStart?
  colSpan?
  rowStart?
  rowSpan?
}

const gridColumnStartCalculation = (colStart: number) => {
  if (colStart || colStart === 0) {
    return `${colStart}`
  }
  return ``
}

const gridColumnEndCalculation = (colSpan: number) => {
  if (colSpan || colSpan === 0) {
    return `span ${colSpan}`
  }
  return `span 1`
}

const gridRowStartCalculation = (rowStart: number) => {
  if (rowStart || rowStart === 0) {
    return `${rowStart}`
  }
  return ``
}

const gridRowEndCalculation = (rowSpan: number) => {
  if (rowSpan || rowSpan === 0) {
    return `span ${rowSpan}`
  }
  return `span 1`
}

const createMediaQuery = (mediaQueryArgs: MediaQueryArgs, props: ItemProps) => {
  return `@media (min-width: ${
    props.theme.breakpointUtils.map[mediaQueryArgs.breakpoint]
  }px)  {
      -ms-grid-column: ${mediaQueryArgs.colStart || ''};
      -ms-grid-column-span: ${mediaQueryArgs.colSpan || 1};
      grid-column-start: ${gridColumnStartCalculation(mediaQueryArgs.colStart)};
      grid-column-end: ${gridColumnEndCalculation(mediaQueryArgs.colSpan)};
      -ms-grid-row: ${mediaQueryArgs.rowStart || ''};
      -ms-grid-row-span: ${mediaQueryArgs.rowSpan || 1};
      grid-row-start: ${gridRowStartCalculation(mediaQueryArgs.rowStart)};
      grid-row-end: ${gridRowEndCalculation(mediaQueryArgs.rowSpan)};
    }`
}

const warningMessage = (incorrectProp: string) => {
  console.warn(
    `You may have used ${incorrectProp} incorrectly. ${incorrectProp} if used to represent a set of custom breakpoints, it should be the same length as the breakpoints you have defined, otherwise it should match the default breakpoints defined within @thg-commerce/gravity-theme/grid. Please review our docs for Grid - http://thg.design`,
  )
}

const GridItemStyling = (props: ItemProps) => {
  let styles = ``
  const breakpointArray = props.breakpoints
    ? props.breakpoints
    : props.theme.grid.breakpoints
  const colStartArray =
    typeof props.colStart === 'number'
      ? breakpointArray.map(() => props.colStart)
      : props.colStart

  colStartArray &&
    colStartArray.length !== breakpointArray.length &&
    warningMessage('colStart')

  const colSpanArray =
    typeof props.colSpanNumber === 'number'
      ? breakpointArray.map(() => props.colSpanNumber)
      : props.colSpanNumber

  colSpanArray &&
    colSpanArray.length !== breakpointArray.length &&
    warningMessage('colSpan')

  const rowStartArray =
    typeof props.rowStart === 'number'
      ? breakpointArray.map(() => props.rowStart)
      : props.rowStart

  rowStartArray &&
    rowStartArray.length !== breakpointArray.length &&
    warningMessage('rowStart')

  const rowSpanArray =
    typeof props.rowSpanNumber === 'number'
      ? breakpointArray.map(() => props.rowSpanNumber)
      : props.rowSpanNumber

  rowSpanArray &&
    rowSpanArray.length !== breakpointArray.length &&
    warningMessage('rowSpan')

  for (let index = 0; index < breakpointArray.length; index += 1) {
    const argumentsObject: MediaQueryArgs = {
      lowerBreakpoint: index === 0 ? 0 : breakpointArray[index - 1],
      breakpoint: breakpointArray && breakpointArray[index],
      colStart: colStartArray && colStartArray[index],
      colSpan: colSpanArray && colSpanArray[index],
      rowStart: rowStartArray && rowStartArray[index],
      rowSpan: rowSpanArray && rowSpanArray[index],
    }
    styles = `${styles}  ${createMediaQuery(argumentsObject, props)}`
  }
  return styles
}

const Item = styled.div<ItemProps>`
  ${(props) => GridItemStyling(props)};
`

export const GridItem = (props: GridItemProps) => {
  const { colSpan: colSpanNumber, rowSpan: rowSpanNumber, ...rest } = props
  return (
    <Item
      colSpanNumber={colSpanNumber}
      rowSpanNumber={rowSpanNumber}
      {...(props.gridItemRef && { ref: props.gridItemRef })}
      {...rest}
    >
      {props.children}
    </Item>
  )
}

export default GridItem
