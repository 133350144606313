import { Property } from 'csstype'
import * as styledComponents from 'styled-components'

import {
  MessageTextInterface,
  TitleSubtextInterface,
} from './configStringStyles/titleSubtext'
import { button, ButtonInterface } from './elements/button'
import { cookiemodal, CookieModalInterface } from './elements/cookiemodal'
import { formgroup, FormGroupInterface } from './elements/formgroup'
import { paragraph, ParagraphInterface } from './elements/paragraph'
import { separator, SeparatorInterface } from './elements/separator'
import { surface, SurfaceInterface } from './elements/surface'
import { Text, typography, TypographyInterface } from './typography/typography'
import { border } from './border'
import {
  BreakpointsInterface,
  breakpointUtils,
  dangerous_mqMax,
  mq,
} from './breakpoints'
import { colors, PaletteColorsInterface } from './colors'
import { gradient, opacity } from './colorUtils'
import { messageText, titleSubtext } from './configStringStyles'
import { elevation, ElevationLevel } from './elevation'
import { grid, GridInterface } from './grid'
import { defaultIcons, IconsInterface } from './icons'
import { logo, LogoInterface } from './logo'
import { margin } from './margin'
import { padding } from './padding'
import { site, SiteInterface } from './site'

export type { BreakpointsInterface } from './breakpoints'
export { breakpointUtils, mediaQueryRenderer } from './breakpoints'
export type { BorderValueTypes } from './elements/formgroup'
export type { IconStyling } from './icons'
export type { Margin } from './margin'
export type { Padding } from './padding'
export type { TextStyling } from './typography/typography'

const baseUnit = 8

export enum ZIndexLevel {
  Lowest = -1,
  Lower = 0,
  Low = 5,
  Base = 10,
  Base5 = 15,
  High = 20,
  Higher = 40,
  Highest = 80,
}

export type RewardsCardInterface = {
  listStyleType: string
}

const defaultRewardsCard: RewardsCardInterface = {
  listStyleType: 'disc',
}

export type StoreDetailsCardInterface = {
  maxWidth: string
}

const defaultStoreDetailsCard: StoreDetailsCardInterface = {
  maxWidth: '424px',
}

export interface ThemeInterface {
  breakpoints: number[]
  breakpointUtils: BreakpointsInterface
  button: ButtonInterface
  titleSubtext: TitleSubtextInterface
  colors: PaletteColorsInterface
  messageText: MessageTextInterface
  elevation: {
    elevationActive: boolean
  }
  formgroup: FormGroupInterface
  grid: GridInterface
  logo: LogoInterface
  separator: SeparatorInterface
  site: SiteInterface
  space: number[]
  surface: SurfaceInterface
  typography: TypographyInterface
  paragraph: ParagraphInterface
  cookiemodal: CookieModalInterface
  icons: IconsInterface
  rewardsCard: RewardsCardInterface
  storeDetailsCard: StoreDetailsCardInterface
}

export interface SpacingBox {
  top: number
  right: number
  bottom: number
  left: number
}

export type ButtonTheme = ButtonInterface
export type ColorsTheme = PaletteColorsInterface
export type FormGroupTheme = FormGroupInterface
export type LogoTheme = LogoInterface
export type SiteTheme = SiteInterface
export type TypographyTheme = TypographyInterface

const spacing = (multiple: number) => `${baseUnit * multiple}px`

export const paddingMixin = (padding: SpacingBox) => css`
  padding: ${spacing(padding.top)} ${spacing(padding.right)}
    ${spacing(padding.bottom)} ${spacing(padding.left)};
`

const zIndex = (level: ZIndexLevel) => `z-index: ${level};`

// @deprecated - use Direction below to match flex-direction definition
export enum Orientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum Direction {
  COLUMN = 'column',
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
  COLUMN_REVERSE = 'column-reverse',
}

export enum Order {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
}

export enum HorizontalAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum KeyboardKeys {
  Esc = 'Esc',
  Escape = 'Escape',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Tab = 'Tab',
  Enter = 'Enter',
  Spacebar = ' ',
}

const theme: ThemeInterface = {
  messageText,
  breakpointUtils,
  button,
  colors,
  elevation,
  titleSubtext,
  formgroup,
  grid,
  logo,
  separator,
  site,
  surface,
  typography,
  paragraph,
  cookiemodal,
  breakpoints: breakpointUtils.keys.map((key) => breakpointUtils.map[key]),
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64], // 8px base design scale
  icons: defaultIcons,
  rewardsCard: defaultRewardsCard,
  storeDetailsCard: defaultStoreDetailsCard,
}

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  useTheme,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<
  ThemeInterface
>

export {
  border,
  colors,
  createGlobalStyle,
  css,
  dangerous_mqMax,
  elevation,
  ElevationLevel,
  gradient,
  keyframes,
  margin,
  mq,
  opacity,
  padding,
  spacing,
  styled,
  Text,
  theme,
  ThemeProvider,
  useTheme,
  withTheme,
  zIndex,
}

export type FontItem = {
  fontSize: Property.FontSize<string>
  lineHeight: Property.LineHeight<string>
  letterSpacing: Property.LetterSpacing<string>
}

export type TextEntry =
  | 'xlarge2'
  | 'xlarge1'
  | 'large2'
  | 'large1'
  | 'medium1'
  | 'bodyText'
  | 'small'
  | 'xsmall'

export type TextStyle = 'default' | 'alternate'

export type TextTransform = 'uppercase' | 'lowercase' | 'none'

export type TextDecoration = 'underline' | 'line-through' | 'none'

export type DeviceSize = 'largeDevice' | 'smallDevice'

export type {
  BreakpointArray,
  BreakpointKey,
  BreakpointType,
} from './breakpoints'
