import * as React from 'react'
import TagManager from 'react-gtm-module'
import withHydrationOnDemand from 'react-hydration-on-demand'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import App, { AppProps } from 'next/app'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { Router } from 'next/router'
import createUv from 'uv-api'
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals'

import { Message } from '@thg-commerce/deepspace-relay/src/transmit'
import { getQubitPageCategory } from '@thg-commerce/enterprise-components/Qubit/utils'
import {
  addToDataLayer,
  getDataLayer,
  getFirstLoadPerfData,
  getSpaLoadPerfData,
} from '@thg-commerce/enterprise-metrics'
import { CoreWebVitals } from '@thg-commerce/enterprise-metrics/src/perf'
import { ApolloProvider } from '@thg-commerce/enterprise-network'
import { Feature } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Extensions } from '@thg-commerce/enterprise-network/types'
import {
  EnterpriseThemeInterface,
  styled,
} from '@thg-commerce/enterprise-theme'
import { sanitizeCookie } from '@thg-commerce/enterprise-utils'
import { SubscribableRef } from '@thg-commerce/enterprise-utils/src/SubscribableRef'

import {
  EnterpriseProvider,
  MetaTagGenerator,
  pageTypeFromUrl,
  Routes,
  transmitProvider,
  uuid,
  VerificationCodeLoader,
} from '../../'
import {
  Configuration,
  Currency,
  CustomerLoginState,
  InternationalOverlay as InternationalOverlayConfig,
  RequestConfig,
  ShippingDestination,
  SiteProperties,
} from '../ConfigurationLoader/types'
import { CookieModalRendererProps } from '../CookieModalRenderer/CookieModalRenderer'
import { getCDNFontsToLoad } from '../FontLoader'
import { SimpleHeaderLayout } from '../Layouts'
import { Logger } from '../Logger/types'

import { handleAffiliateParamsCookies } from './affiliateParams'
import { EnterpriseNextPage, PageType } from './types'

const CookieModalRenderer = dynamic<CookieModalRendererProps>(() =>
  import('../CookieModalRenderer/CookieModalRenderer').then(
    (mod) => mod.CookieModalRenderer,
  ),
)

const CookieModalRendererComponent = withHydrationOnDemand({
  on: [['idle']],
})(CookieModalRenderer)

const InternationalOverlay = dynamic<any>(() =>
  import('../InternationalOverlay/InternationalOverlay').then(
    (mod) => mod.InternationalOverlay,
  ),
)

const GlobalStyle = dynamic<any>(() =>
  import(
    '@thg-commerce/gravity-system/components/GlobalStyle/GlobalStyle'
  ).then((mod) => mod.GlobalStyle),
)

const SiteContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  color: ${(props) => props.theme.colors.palette.greys.darker};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

declare global {
  interface Window {
    OptanonWrapper?: () => void
    Optanon: { OnConsentChanged: (handler: () => void) => void }
  }
}

export interface EnterpriseAppProps extends AppProps {
  apolloClient: ApolloClient<NormalizedCacheObject>
  theme: EnterpriseThemeInterface
  currentLocation: string
  internationalOverlayObject: any
  extensionsRef?: SubscribableRef<Extensions | undefined>
  logger: Logger
  config: Configuration
  internationalOverlay?: InternationalOverlayConfig
  requestConfig: RequestConfig
  isMobile: boolean
  shippingDestination: ShippingDestination
  currency: Currency
  isAmp: boolean
  Component: EnterpriseNextPage
  serviceContextProviders?: React.ComponentType<{ children: React.ReactNode }>[]
  horizonFeatures?: Feature[]
  siteProperties: SiteProperties
  pageLayoutData: any
  concessionCode?: string
  browserUrl?: string
  pageType?: PageType | undefined
  hashedEmail?: string
  customerReceiveNewsletter: boolean
  customerLoginState?: CustomerLoginState
}

const CORE_WEB_VITAL_ACCESSORS = [
  {
    accessor: onFCP,
    field: 'firstContentfulPaint',
  },
  {
    accessor: onLCP,
    field: 'largestContentfulPaint',
  },
  {
    accessor: onCLS,
    field: 'cumulativeLayoutShift',
  },
  {
    accessor: onFID,
    field: 'firstInputDelay',
  },
  {
    accessor: onTTFB,
    field: 'timeToFirstByte',
  },
  { accessor: onINP, field: 'interactionToNextPaint' },
]

export class EnterpriseApp extends App<
  EnterpriseAppProps,
  any,
  { metricNonce: string }
> {
  state = {
    metricNonce:
      (typeof window !== 'undefined'
        ? sanitizeCookie('metric_nonce')
        : undefined) || uuid.generate(),
  }
  private recordedCoreWebVitals: CoreWebVitals = {}
  private perfEventSent = false

  private transmit: (message: Message) => void
  private boundHandleLoad = this.handleLoad.bind(this)
  private boundExtensionsChanged = this.extensionsChanged.bind(this)

  constructor(props) {
    super(props)
    const {
      brand,
      subsite,
      originUrl,
    } = props.config.publicRuntimeConfig.siteDefinition
    this.transmit = transmitProvider({ brand, subsite, originUrl })
  }

  private getExtensions(): Extensions | undefined {
    let extensions: Extensions | undefined
    if (this.props.extensionsRef) {
      const {
        value: [getExtensions],
      } = this.props.extensionsRef
      extensions = getExtensions()
    }
    return extensions
  }

  navigated(_) {
    this.transmit({
      type: 'userExT',
      payload: {},
    })
  }

  handleLoad() {
    const { siteDefinition, siteConfig } = this.props.config.publicRuntimeConfig
    const { gtmContainerId, qubit } = siteConfig

    const extensions = this.getExtensions()
    if (!extensions) {
      this.props.logger.warn('Extensions is undefined on App mount')
    }

    if (getConfig().publicRuntimeConfig['IS_PRODUCTION']) {
      const dataLayer = getDataLayer(
        window,
        siteDefinition,
        Routes,
        this.state.metricNonce,
        this.props.isMobile,
        extensions,
        true,
      )

      const currentDataLayer = window.dataLayer || []
      if (
        currentDataLayer[0]?.visitorLoginState ||
        currentDataLayer[0]?.pageTitle
      ) {
        currentDataLayer[0] = { ...currentDataLayer[0], ...dataLayer }
      } else {
        currentDataLayer.unshift(dataLayer)
      }
      const visitorId = sanitizeCookie('chumewe_user')

      if (
        qubit?.enabled &&
        qubit?.smartServeSourceURL &&
        typeof window !== 'undefined'
      ) {
        window.uv = window.uv || createUv()
        window.uv?.emit('ecView', {
          type: window.dataLayer[0]?.pageCategory
            ? getQubitPageCategory(window.dataLayer[0].pageCategory)
            : undefined,
          language: `${siteDefinition.subsite}-${siteDefinition.defaultSessionSettings.shippingDestination}`.toLowerCase(),
          currency: siteDefinition.defaultSessionSettings.currency,
          country: siteDefinition.defaultSessionSettings.shippingDestination,
        })

        window.uv?.emit('ecUser', {
          user: {
            id: visitorId,
          },
        })
      }

      TagManager.initialize({
        dataLayer: currentDataLayer,
        gtmId: gtmContainerId || 'GTM-W2MHS8X',
      })

      Router.events.on('routeChangeComplete', (_) => {
        getDataLayer(
          window,
          siteDefinition,
          Routes,
          this.state.metricNonce,
          this.props.isMobile,
          extensions,
        )

        const metricNonce = uuid.generate()
        this.setState({ metricNonce })
        const { subsite } = siteDefinition
        const performanceStats = getSpaLoadPerfData(
          siteDefinition,
          subsite,
          metricNonce,
        )
        this.transmit({
          type: 'perf',
          payload: performanceStats,
        })
      })
      addToDataLayer({
        key: 'mobile',
        value: this.props.isMobile ? 'yes' : 'no',
      })
    }

    this.transmit({
      type: 'userExT',
      payload: {},
    })
  }

  recordCoreWebVitals() {
    CORE_WEB_VITAL_ACCESSORS.forEach((accessor) => {
      accessor.accessor(({ value }) => {
        this.recordedCoreWebVitals[accessor.field] = Math.trunc(value)
      })
    })
  }

  reportPerfEvent() {
    if (this.perfEventSent) {
      return
    }

    const { customerLocation } = this.props.requestConfig
    const { siteDefinition } = this.props.config.publicRuntimeConfig
    const { subsite } = siteDefinition

    const performanceStats = getFirstLoadPerfData(
      siteDefinition,
      customerLocation,
      subsite,
      this.state.metricNonce,
      this.recordedCoreWebVitals,
    )

    this.transmit({
      type: 'perf',
      payload: performanceStats,
    })

    this.perfEventSent = true
  }

  extensionsChanged(extensions: Extensions | undefined) {
    if (extensions) {
      const { config } = this.props
      const { siteDefinition } = config.publicRuntimeConfig

      getDataLayer(
        window,
        siteDefinition,
        Routes,
        this.state.metricNonce,
        this.props.isMobile,
        extensions,
      )
    }
  }

  componentDidMount() {
    const { publicRuntimeConfig } = this.props.config

    this.handleLoad()
    this.recordCoreWebVitals()

    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.reportPerfEvent()
      }
    })

    // NOTE: Safari does not reliably fire the `visibilitychange` event when the
    // page is being unloaded. If Safari support is needed, you should also flush
    // the queue in the `pagehide` event.
    window.addEventListener('pagehide', () => this.reportPerfEvent())

    if (
      getConfig().publicRuntimeConfig['IS_PRODUCTION'] &&
      this.props.extensionsRef
    ) {
      const { subscribe: extensionsChanged } = this.props.extensionsRef
      extensionsChanged(this.boundExtensionsChanged)
    }

    handleAffiliateParamsCookies(publicRuntimeConfig)

    if (publicRuntimeConfig.siteConfig.showOptanonFooterLink) {
      window.OptanonWrapper = () => {
        window.Optanon.OnConsentChanged(() => {
          handleAffiliateParamsCookies(publicRuntimeConfig)
        })
      }
    }

    if (
      publicRuntimeConfig.siteConfig.qubit?.enabled &&
      window.location.hash.includes('#qb_opts')
    ) {
      // This is necessary to enable recommendations previews with ESI component
      const newUrl = window.location.href.replace('#qb_opts', '?qb_opts')
      window.location.assign(newUrl)
    }
  }

  componentWillUnmount() {
    if (this.props.extensionsRef) {
      const { unsubscribe } = this.props.extensionsRef
      unsubscribe(this.boundExtensionsChanged)
    }

    if (typeof window !== 'undefined') {
      window.removeEventListener('load', this.boundHandleLoad)
    }
  }

  render() {
    const {
      Component,
      pageProps,
      apolloClient,
      extensionsRef,
      logger,
      config,
      requestConfig,
      serviceContextProviders,
      horizonFeatures,
      siteProperties,
      pageLayoutData,
      theme,
      pageType,
      customerReceiveNewsletter,
      customerLoginState,
    } = this.props
    const { publicRuntimeConfig, serverRuntimeConfig: _, ...appConfig } = config
    const {
      siteDefinition,
      siteConfig,
      subsiteDomains,
      hreflangs,
      ENABLE_DYNATRACE_RUXITAGENT,
    } = publicRuntimeConfig
    const { brand, subsite, originUrl } = siteDefinition
    const { previewId, showKeys, customerLocation } = requestConfig

    const Layout: any = Component.Layout || SimpleHeaderLayout

    const { fontFaces, fontsToPreload } = getCDNFontsToLoad(theme)
    return (
      <React.Fragment>
        <React.StrictMode>
          <ApolloProvider client={apolloClient} config={publicRuntimeConfig}>
            <EnterpriseProvider
              logger={logger}
              client={apolloClient}
              previewId={previewId}
              theme={theme}
              showKeys={showKeys}
              brand={brand}
              subsite={subsite}
              currentLocation={customerLocation}
              extensionsRef={extensionsRef}
              metricNonce={this.state.metricNonce}
              config={config}
              requestConfig={requestConfig}
              serviceContextProviders={serviceContextProviders}
              shippingDestination={this.props.shippingDestination}
              currency={this.props.currency}
              appConfig={appConfig}
              horizonFeatures={horizonFeatures}
              siteProperties={siteProperties}
              pageType={pageType}
              customerReceiveNewsletter={customerReceiveNewsletter}
              customerLoginState={customerLoginState}
            >
              <Head>
                <meta
                  name="generator"
                  content={`${publicRuntimeConfig['APP_NAME']} v${publicRuntimeConfig['APP_VERSION']}`}
                />
                {MetaTagGenerator(
                  {
                    origin: originUrl,
                    path: this.props.browserUrl || this.props.router.asPath,
                    pageType: pageTypeFromUrl(this.props.router.asPath),
                  },
                  siteDefinition,
                  siteConfig,
                  subsiteDomains,
                  hreflangs,
                )}

                {fontsToPreload.map(({ url, fontType }, index) => (
                  <link
                    rel="preload"
                    as="font"
                    href={url}
                    crossOrigin="anonymous"
                    type={`font/${fontType}`}
                    key={`font-to-preload-${index}`}
                  />
                ))}
                {ENABLE_DYNATRACE_RUXITAGENT &&
                  siteConfig.dynatraceRuxitAgentUrl && (
                    <script
                      type="text/javascript"
                      src={siteConfig.dynatraceRuxitAgentUrl}
                    />
                  )}
                {siteConfig.hasInternationalOverlay ? (
                  <link
                    rel="preload"
                    as="fetch"
                    href={`${originUrl}/e2/operation/international-overlay`}
                    crossOrigin="anonymous"
                  />
                ) : null}
              </Head>
              <GlobalStyle additionalStyles={fontFaces} />
              <VerificationCodeLoader />
              <SiteContainer>
                {siteConfig.enableCookieMessage && (
                  <CookieModalRendererComponent atTop={false} />
                )}
                <Layout
                  pageLayoutData={pageLayoutData}
                  pageType={pageType}
                  pageProps={pageProps}
                  $window={typeof window !== 'undefined' ? window : undefined}
                >
                  <Component
                    {...pageProps}
                    apolloClient={apolloClient}
                    theme={theme}
                  />
                </Layout>
                {siteConfig.hasInternationalOverlay ? (
                  <InternationalOverlay />
                ) : null}
              </SiteContainer>
            </EnterpriseProvider>
          </ApolloProvider>
          <span data-testid="x-brand" style={{ display: 'none' }}>
            {brand}
          </span>
        </React.StrictMode>
      </React.Fragment>
    )
  }
}
