export interface AvailableRoutes {
  AccountHome: string
  AccountHomeNew: string
  AccountRewards: string
  Basket: string
  EnterpriseBasket: string
  Login: string
  Logout: string
  CheckoutStart: string
  HomePage: string
  Home: string
  PetAndLitterProfile: string
  PasswordReset: string
  Register: string
  RequestSocialLinkVerificationEmail: string
  SocialAuthentication: string
  SocialRedirecting: string
  SocialOptIn: string
  ValidateEmail: string
  NewsletterSignUp: string
  AccountOrderHistory: string
  RewardPoints: string
  MemberPerks: string
  MyReferrals: string
  AccountMarketingPreferences: string
  Order: string
  OrderDetails: string
  OrderPaymentProblem: string
  AddReview: string
  ReportReview: string
  HelpfulReview: string
  AccountLink: string
  EmailPreferences: string
  EmailUnsubscribe: string
  AccountDetails: string
  AccountDetailsNew: string
  PaymentCards: string
  PaymentCardsNew: string
  AddressBook: string
  AddressBookNew: string
  EditAddressBook: string
  EditAddressBookNew: string
  AccountCredit: string
  AccountCreditNew: string
  OrderHistory: string
  AccountSubscriptions: string
  AccountNewMessage: string
  AccountViewMessages: string
  Search: string
  SocialLinks: string
  HelpCentre: string
  AccountSubscriptionDetails: string
  Brands: string
  MyProfile: string
  Wishlists: string
  Blog: string
  BlogAuthor: string
  BlogSearch: string
  BlogSlug: string
  LandingPath: string
  Maintenance: string
  AccountOrders: string
  AccountOrdersPage: string
  Product: string
  ProductReview: string
  ProductAddReview: string
  PersonaliseYourBox: string
  AccountSubscriptionsHistory: string
  AccountSubscriptionsHistoryPage: string
  AccountSubscription: string
  AccountSubscriptionsOrderHistory: string
  AccountSubscriptionEdit: string
  AccountSubscriptionAddon: string
  AccountSubscriptionCancel: string
  AccountSubscriptionDelay: string
  AccountSubscriptionEditBillingDate: string
  AccountSubscriptionDelayCancel: string
  AccountChangeAddress: string
  AccountChangePaymentMethod: string
  AccountSubscriptionChangeFrequency: string
  AccountMessages: string
  AccountViewMessage: string
  AccountCreateMessage: string
  AccountOrderCancel: string
  AccountSocialLinks: string
  AccountWishlists: string
  NewsletterSignUpNew: string
  AccountSmsOptOut: string
  AccountOrderCancelNew: string
  AccountProfilesNew: string
  AccountProfiles: string
  ChangeAddress: string
  AccountOrderAddReview: string
  AccountOrderInvoice: string
  AccountMyReturns: string
  ReturnDetails: string
  ReturnDetailsLabel: string
  AccountLoyaltyCard: string
  Stores: string
  StoresRegion: string
  StoresCity: string
  StoreLocator: string
  StoreDetails: string
  StoreDetailsNew: string
  RegistrationInfo: string
}

export const Routes: AvailableRoutes = {
  Wishlists: '/wishlists.account',
  MyProfile: '/my.profile',
  AccountHome: '/accountHome.account',
  AccountHomeNew: '/account/home',
  AccountRewards: '/account/rewards',
  Basket: '/my.basket',
  EnterpriseBasket: '/basket',
  Login: '/login.jsp',
  Logout: '/logout',
  PetAndLitterProfile: '/account/pet-and-litter-profile',
  CheckoutStart: `/checkoutStart.account`,
  HomePage: '/',
  Home: '/home',
  PasswordReset: '/password.reset',
  Register: '/accountCreate.account',
  RequestSocialLinkVerificationEmail:
    '/requestSocialLinkVerificationEmail.account',
  SocialAuthentication: '/socialAuthentication.account',
  SocialOptIn: '/socialOptIn.account',
  SocialRedirecting: '/social/redirecting',
  ValidateEmail: '/validateEmail.account',
  NewsletterSignUp: '/emailSignUp.account',
  AccountOrderHistory: '/accountOrderHistory.account',
  RewardPoints: '/rewardPoints.account',
  MemberPerks: '/members-perks.list',
  MyReferrals: '/myreferrals.list',
  AccountMarketingPreferences: '/account/marketing-preferences',
  Order: '/accountOrder.account',
  OrderDetails: '/account/orders/:orderNumber',
  OrderPaymentProblem: '/resolveCardProblem.account',
  AddReview: '/addReview.account',
  ReportReview: '/reportReview.account',
  HelpfulReview: '/helpfulReview.account',
  AccountLink: '/performAccountLink.account',
  EmailPreferences: '/accountCommunicationPreferences.account',
  EmailUnsubscribe: '/account/email-unsubscribe',
  AccountDetails: '/accountSettings.account',
  AccountDetailsNew: '/account/details',
  PaymentCards: '/listCards.account',
  PaymentCardsNew: '/account/cards',
  AddressBook: '/addressBook.account',
  EditAddressBook: '/editAddress.account',
  AddressBookNew: '/account/addresses',
  EditAddressBookNew: '/account/addresses/edit',
  AccountCredit: '/accountCredit.account',
  AccountCreditNew: '/account/credit',
  OrderHistory: '/accountOrderHistory.account',
  AccountSubscriptions: '/subscriptions.account',
  AccountNewMessage: '/customerQuery.account',
  AccountViewMessages: '/accountMessages.account',
  Search: '/elysium.search',
  SocialLinks: '/socialLinks.account',
  HelpCentre: '/help-centre.list',
  AccountSubscriptionDetails: '/subscriptionDetail.account',
  Brands: '/brands.list',
  Blog: '/blog',
  BlogAuthor: '/blog/author/:slug',
  BlogSearch: '/blog/search',
  BlogSlug: '/blog/:slug(/*)?',
  LandingPath: '/landing/:path(/*)?',
  Maintenance: '/maintenance',
  AccountOrders: '/account/orders',
  AccountOrdersPage: '/account/orders/page/:pageNumber',
  PersonaliseYourBox: '/personalise-your-box',
  Product: '/product/:sku',
  ProductAddReview: '/product/addReview',
  ProductReview: '/product/:sku/reviews',
  AccountSubscriptionsHistory: '/account/subscriptions',
  AccountSubscriptionsOrderHistory:
    '/account/subscriptions/:subscriptionId/orderHistory',
  AccountSubscriptionsHistoryPage: '/account/subscriptions/page/:pageNumber',
  AccountSubscription: '/account/subscriptions/:subscriptionId',
  AccountSubscriptionEdit: '/account/subscriptions/:subscriptionId/edit',
  AccountSubscriptionAddon: '/account/subscriptions/:subscriptionId/addon',
  AccountSubscriptionCancel: '/account/subscriptions/:subscriptionId/cancel',
  AccountSubscriptionDelay: '/account/subscriptions/:subscriptionId/delay',
  AccountSubscriptionEditBillingDate:
    '/account/subscriptions/:subscriptionId/editbillingdate',
  AccountSubscriptionDelayCancel:
    '/account/subscriptions/:subscriptionId/canceldelay',
  AccountChangeAddress: '/chooseAddress.account',
  AccountChangePaymentMethod: '/updatePaymentCard.account',
  AccountSubscriptionChangeFrequency:
    '/account/subscriptions/:subscriptionId/changefrequency',
  AccountMessages: '/account/messages',
  AccountViewMessage: '/account/messages/:messageId',
  AccountCreateMessage: '/account/messages/create',
  AccountOrderCancel: '/accountOrderCancel.account',
  AccountSocialLinks: '/account/social-links',
  NewsletterSignUpNew: '/account/email-sign-up',
  AccountSmsOptOut: '/account/sms-opt-out',
  AccountWishlists: '/account/wishlists',
  AccountOrderCancelNew: '/account/orders/:orderNumber/cancel',
  AccountProfilesNew: '/account/profiles/:profileId',
  AccountProfiles: '/(.*).profile',
  ChangeAddress: '/account/subscriptions/:subscriptionId/changeaddress',
  AccountOrderAddReview: '/account/orders/:orderNumber/review',
  AccountOrderInvoice: '/account/orders/:orderNumber/:shipmentNumber/invoice',
  AccountLoyaltyCard: '/account/loyalty',
  AccountMyReturns: '/account/returns',
  ReturnDetails: '/account/returns/:returnNumber',
  ReturnDetailsLabel: '/account/returns/:returnNumber/label',
  Stores: '/stores/:country',
  StoresRegion: '/stores/:country/:region',
  StoresCity: '/stores/:country/:region/:town',
  StoreLocator: '/store-locator',
  StoreDetails: '/store/:town/:store',
  StoreDetailsNew: '/stores/:country/:region/:town/:store',
  RegistrationInfo: '/registration-info.list',
}

export const getContentRoutePathname = (
  prefix: string,
  route: string,
): string => {
  let pathname = route
  if (pathname.startsWith('/blog')) {
    pathname = pathname.replace('/blog', '')
  }
  const preparedContentRoutePath = prefix.replace(/^[\\/]+|[\\/]+$/g, '')
  const preparedPathname = pathname.replace(/^[\\/]+|[\\/]+$/g, '')

  return preparedPathname
    ? `/${preparedContentRoutePath}/${preparedPathname}/`
    : `/${preparedContentRoutePath}/`
}

export const LIST_EXTENSION = '.list'

export const accountAuthenticatedRoutes = [
  Routes.ProductAddReview,
  Routes.Wishlists,
  Routes.AccountRewards,
  Routes.MyProfile,
  Routes.AccountMarketingPreferences,
  Routes.AccountHome,
  Routes.PetAndLitterProfile,
  Routes.AccountHomeNew,
  Routes.SocialOptIn,
  Routes.AccountOrderHistory,
  Routes.RewardPoints,
  Routes.AddReview,
  Routes.ReportReview,
  Routes.HelpfulReview,
  Routes.AccountLink,
  Routes.EmailPreferences,
  Routes.AccountDetails,
  Routes.AccountDetailsNew,
  Routes.PaymentCards,
  Routes.PaymentCardsNew,
  Routes.AddressBook,
  Routes.AddressBookNew,
  Routes.EditAddressBook,
  Routes.EditAddressBookNew,
  Routes.AccountCredit,
  Routes.OrderHistory,
  Routes.AccountNewMessage,
  Routes.AccountViewMessages,
  Routes.AccountSubscriptions,
  Routes.AccountSubscriptionsHistory,
  Routes.AccountSubscriptionsHistoryPage,
  Routes.AccountOrders,
  Routes.AccountOrdersPage,
  Routes.OrderDetails,
  Routes.AccountSubscription,
  Routes.AccountSubscriptionsOrderHistory,
  Routes.AccountSubscriptionEdit,
  Routes.AccountSubscriptionAddon,
  Routes.AccountSubscriptionDetails,
  Routes.AccountSubscriptionCancel,
  Routes.AccountSubscriptionDelay,
  Routes.AccountSubscriptionEditBillingDate,
  Routes.AccountSubscriptionDelayCancel,
  Routes.Order,
  Routes.AccountSubscriptionChangeFrequency,
  Routes.CheckoutStart,
  Routes.AccountMessages,
  Routes.AccountViewMessage,
  Routes.AccountCreateMessage,
  Routes.AccountCreditNew,
  Routes.SocialLinks,
  Routes.AccountSocialLinks,
  Routes.AccountWishlists,
  Routes.AccountOrderCancelNew,
  Routes.AccountProfilesNew,
  Routes.AccountProfiles,
  Routes.ChangeAddress,
  Routes.AccountOrderInvoice,
  Routes.AccountMyReturns,
  Routes.ReturnDetails,
  Routes.ReturnDetailsLabel,
  Routes.AccountLoyaltyCard,
]

export const publicAccessRoutes = [
  Routes.Login,
  Routes.Register,
  Routes.PasswordReset,
  Routes.SocialAuthentication,
  Routes.SocialRedirecting,
  Routes.RegistrationInfo,
]
