import { Property } from 'csstype'

import { TextEntry, TextStyle } from '@thg-commerce/gravity-theme'

import { colors } from '../colors'

export interface StateInterface {
  color: Property.Color
  textDecoration: Property.TextDecoration<string>
}

export interface LinkInterface {
  default: StateInterface
  hover: StateInterface
  active: StateInterface
  disabled: StateInterface
}

export interface TextInterface {
  entry: TextEntry
  style: TextStyle
}
export interface TitleSubtextInterface {
  font: TextInterface
  links: LinkInterface
}

export interface MessageTextInterface {
  font: {
    entry: TextEntry
    style: TextStyle
  }
}

export const titleSubtext: TitleSubtextInterface = {
  font: {
    entry: 'bodyText',
    style: 'default',
  },
  links: {
    default: {
      color: colors.palette.brand.base,
      textDecoration: 'underline',
    },
    hover: {
      color: colors.palette.brand.light,
      textDecoration: 'underline',
    },
    active: {
      color: colors.palette.brand.darker,
      textDecoration: 'underline',
    },
    disabled: {
      color: colors.palette.greys.light,
      textDecoration: 'underline',
    },
  },
}

export const messageText: MessageTextInterface = {
  font: {
    entry: 'bodyText',
    style: 'alternate',
  },
}
