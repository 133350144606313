import {
  CSPMode,
  DataLayerMap,
  HeaderSlot,
  ProductChoiceTypeMap,
  SiteDefinitionInterface,
} from '@thg-commerce/enterprise-config'
import {
  Country,
  Feature,
  ProductRecommendationType as ProductRecommendation,
  ReviewSort,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Extensions } from '@thg-commerce/enterprise-network/types'
import { EnterpriseThemeInterface } from '@thg-commerce/enterprise-theme'

export enum ApplyCodeToBasketState {
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  REFERRAL_CODE_INVALID = 'REFERRAL_CODE_INVALID',
  REFERRAL_CODE_APPLIED = 'REFERRAL_CODE_APPLIED',
  REFERRAL_CODE_EXPIRED = 'REFERRAL_CODE_EXPIRED',
  REFERRER_NOT_ELIGIBLE = 'REFERRER_NOT_ELIGIBLE',
  DISCOUNT_CODE_INVALID = 'DISCOUNT_CODE_INVALID',
  DISCOUNT_CODE_EXPIRED = 'DISCOUNT_CODE_EXPIRED',
}

export type SiteProperties = {
  [key: string]: { value: string; location: string }
}

export interface SessionSettings {
  [key: string]: {
    name: string
    defaultSubsite: Subsite
    subsites: Subsite[]
  }
}

export interface Subsite {
  code: string
  defaultCurrency: string
  currencies: string[]
  domain: string
}

export interface ShippingDestination {
  code: string
  name: string
}

export type Currency = string

export enum ProductDescriptionStyle {
  ACCORDION = 'ACCORDION',
  TABS = 'TABS',
}

export enum ProductSummaryLayout {
  PRIORITY_TO_DETAILS = 'PRIORITY_TO_DETAILS',
  PRIORITY_TO_CAROUSEL = 'PRIORITY_TO_CAROUSEL',
}

export enum ProductRecommendationType {
  FREQUENTLY_BOUGHT_TOGETHER = 'FREQUENTLY_BOUGHT_TOGETHER',
  PERSONALISED = 'PERSONALISED',
  POST_ADD_TO_BASKET = 'POST_ADD_TO_BASKET',
  DEFAULT = 'DEFAULT',
  NONE = 'NONE',
}

export enum ProductRecommendationsAboveReviewsLayout {
  PRODUCT_RECOMMENDATIONS_ONE = 'PRODUCT_RECOMMENDATIONS_ONE',
  PRODUCT_RECOMMENDATIONS_TWO = 'PRODUCT_RECOMMENDATIONS_TWO',
  BOTH = 'BOTH',
  NONE = 'NONE',
}

export enum ProductDescriptionDisplay {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum QubitMode {
  PREVIEW = 'PREVIEW',
  SAMPLE = 'SAMPLE',
  LIVE = 'LIVE',
  NULL = 'NULL',
}

export enum CustomerLoginState {
  LOGGED_IN = 'logged_in',
  RECOGNISED = 'recognised',
  NOT_RECOGRNISED = 'not_recognised',
}

export type TrustPilotProps = {
  enableTrustBox?: boolean
  enableTrustPilot?: boolean
  enableAccountTrustPilot?: boolean
  templateId?: string
  locale?: string
  width?: string
  height?: string
  businessId?: string
  theme?: string
  type?: string
  link?: string
}

export interface SiteConfigInterface {
  inclusionSiteCodes?: string[]
  exclusionSiteCodes?: string[]
  hidePDPBuyingComponents?: boolean
  displayMasterSKUTitle?: boolean
  enablePapOnRecommendations?: boolean
  enableQuickbuyOnlyOnMobile?: boolean
  showLinkedorChildSKUinSchema?: boolean
  reviewSortMostToLeastPositiveVotes?: boolean
  euCountriesCodesList?: string
  overlayInclusionList?: string
  restOfWorldLocale?: string
  euLocale?: string
  useAlternateBasketItemStyle?: boolean
  enableInfiniteSliding?: boolean
  allowAlternateTermsAndConditions?: boolean
  overlayExclusionList?: string
  AlexaVerificationCode?: string
  reviewInitialSort?: ReviewSort
  GoogleWebmasterVerificationCode?: string
  YouTubeVerificationCode?: string
  NaverVerificationCode?: string
  BingVerificationCode?: string
  ShenmaVerificationCode?: string
  PinterestVerificationCode?: string
  BaiduVerificationId?: string
  YandexVerificationId?: string
  showPetAndLitterProfiles?: boolean
  iosAppIdentifier?: string
  androidAppIdentifier?: string
  androidAppCertFingerprint?: string
  useWidgetForAccreditationIcons?: boolean
  subsites: object
  hreflangs: object
  subsiteDomains: object
  csp: {
    [key: string]: string[]
  }
  contentCsp?: {
    [key: string]: string[]
  }
  FacebookMetaAdmins?: string
  FacebookAppId?: string
  SocialOgTitle?: string
  hideCookieIcon?: boolean
  hasInternationalOverlay?: boolean
  headerSlots?: HeaderSlot[]
  submenuSlots?: HeaderSlot[]
  shippingDestination?: ShippingDestination
  internationalOverlay?: {
    redirectDomain: string
    subsiteIdentifier: string
    currencyWithIcon: string
    flagIcon: string
    redirectDefaultLocale: string
  }
  cookieModalOptions?: {
    showModal: boolean
  }
  submitBelowTCs?: boolean
  hasReversedRegister?: boolean
  useGuestCheckoutLayout?: 'invertRegister' | 'removeRegister' | 'default'
  dynamicNewsletterSubscription?: boolean
  availableLocationsForCookies?: string
  enableCookieMessage?: boolean
  enableMobilePDPStickyAddToBasket?: boolean
  enablePriceOnMobilePDPStickyAddToBasket?: boolean
  listAttackStrategy?: string
  showFooterSubsiteSelector?: boolean
  hideCopyrightLogo?: boolean
  useIngenuityLogo?: boolean
  hasFooterCopyrightAboveLogo?: boolean
  hasFooterCopyrightIcons?: boolean
  showAccountOrders?: boolean
  showAccountReferrals?: boolean
  hasMemberPerks?: boolean
  enableWishlists?: boolean
  enableSelfReferencingCanonicalTag?: boolean
  enableWishlistsGlobal?: boolean
  enableWishlistOnBasketItem?: boolean
  countryDomainMap: { [keys: string]: string }
  translatedCountryDomain: [string, string][]
  signUpRedirect?: string
  signUpOpenInNewTab?: boolean
  hideStoreLocatorOpeningTimes?: boolean
  useDarkSchemeForEastendFooter?: boolean
  enableOrderReviewsFeatureRatings?: boolean
  enableReviewFormFeatureRatings?: boolean
  enableOrderReviewForm?: boolean
  enableOrderReviewLink?: boolean
  enableClickAndCollectTracking?: boolean
  enablePushNotifyWhenInStock?: boolean
  useSingleStarRatings?: boolean
  hasNewReferrals?: string
  hasLoyaltyEnabled?: boolean
  hasWishlist?: string
  hideAccountTransactionalElements?: boolean
  hideEmailPreferencesSiteWide?: boolean
  useCustomNewsletterSignUpLink?: string
  hasDynamicHeaderDropdown?: boolean
  helpCentreImprovementsOnAccountHome?: boolean
  hideLiveChatOnAccountHomeMobile?: boolean
  hideLiveChatOnAccountHomeDesktop?: boolean
  hasLivePerson?: boolean
  hasAdaChatbot?: boolean
  facebookUrl?: string
  hidePaymentIconsInFooter?: boolean
  powerReviewSingleProductApiUrl?: string // TODO: CSBOM-404 to add power review proteties to an object
  powerReviewSingleProductApiTimeout?: number
  hullabalookApiTimeout?: number
  twitterUrl?: string
  selectedProfileKey?: string
  useAlternativeCategoryPageLayout?: boolean
  trustPilot?: TrustPilotProps
  enableAccountTrustPilot?: boolean
  hasSessionSettings?: boolean
  hasSoldByThgHeaderLogo?: boolean
  showResponsiveSubMenuSecondLink?: boolean
  showResponsiveSubMenuFirstLink?: boolean
  showResponsiveSubMenuThirdLink?: boolean
  openBuyNowPayLaterLinkInNewTab?: boolean
  showResponsiveSubMenuFourthLink?: boolean
  hideListProductActions: boolean
  useFrictionlessCheckout?: boolean
  ProtectedSiteAuthorizationHash?: string
  ProtectedSiteAuthorizationType?: string
  hasWhatsAppCard?: boolean
  enableNewAccountHome?: boolean
  hasLoyaltyRedeemablePoints?: boolean
  enhancedEcommerceEnabled?: boolean
  enableLayoutESI: boolean
  buyNowPayLaterProvidersEnabled?: boolean
  showProductStockInformation?: boolean
  showProductStockInformationSoldOut?: boolean
  hasCustomStockMessage?: boolean
  showProductStockInformationPrefix?: boolean
  showProductStockInformationSuffix?: boolean
  displayFrequentlyBoughtRecommendations?: boolean
  productTagsKeys?: string[]
  showProductDeliveryAndReturnsInformation?: boolean
  productPageReviewsEnabled?: boolean
  productDescriptionStyle?: ProductDescriptionStyle
  productSummaryLayout?: ProductSummaryLayout
  outOfStockProductRecommendationsEnabled?: boolean
  showProductPageFreeDeliveryText?: boolean
  liveChatEnabled?: boolean
  hideAccountViewMessages?: boolean
  productRecommendationOne?: ProductRecommendationType
  productRecommendationTwo?: ProductRecommendationType
  manualRecommendationType: ProductRecommendation
  productRecommendationsAboveReviewsLayout?: ProductRecommendationsAboveReviewsLayout
  enablePlpProductBlockVariantImage?: boolean
  enableLowestNumberAsInitialVariant?: boolean
  productDescriptionDisplay?: ProductDescriptionDisplay
  showOptanonFooterLink?: boolean
  youtubeConsentRequired?: boolean
  restrictCookiesIfNoOptanonCookie?: boolean
  hideMarketingOptOutList?: boolean
  hideListPageProductActions?: boolean
  hideListPageProductPrice?: boolean
  marketingConsentDefaultValue?:
    | 'I_DO_NOT_CONSENT_TO_RECEIVING_MARKETING_MATERIAL'
    | 'I_CONSENT_TO_RECEIVING_MARKETING_MATERIAL'
  showMarketedSpecialOfferIcon?: boolean
  productDetailKeyAdditionList?: string[]
  hideProductRecommendations?: boolean
  productDetailKeySubtractionList?: string[]
  productContentKeyList?: string[]
  basketHideInitialPriceColumn?: boolean
  gtmContainerId?: string
  productItemsPerPage?: number
  displaySavingsPricePLP?: boolean
  hasDiscountSortOption?: boolean
  hasNewestSortOption?: boolean
  canReturnOldOrders?: boolean
  enableConcessionProductOverride?: boolean
  canRebuyOldOrders?: boolean
  orderNotCancellable?: boolean
  hideProductListTotalResults?: boolean
  hideProductListReadMore?: boolean
  hideProductListFooterReadMore?: boolean
  hideProductListReviewRating?: boolean
  hideProductListProductBlockButton?: boolean
  hideProductListImageRollover?: boolean
  hideProductListWishlists?: boolean
  showProductListProductBlockIcons?: boolean
  hasClickAndCollect?: boolean
  enableHorizontalFacets?: boolean
  showPdpLinkWhenOutOfStock?: boolean
  productListReviewRatingThreshold?: number
  hasWaterfallMenu?: boolean
  responsiveFlyoutMenu_hasStoreIcon?: boolean
  flyoutMenuShowWishlistIcon?: boolean
  provenanceBrandBundleUrl?: string
  provenanceProductBundleUrl?: string
  provenanceSdkUrl?: string
  provenanceSdkIntegrityHash?: string
  showProductProvenance?: boolean
  powerReviewApiUrl?: string
  powerReviewUiUrl?: string
  powerReviewApiKey?: string
  powerReviewMerchantGroupId?: string
  powerReviewMerchantId?: string
  showPowerReview?: boolean
  showPowerReviewE2?: boolean
  facebookTrackingEnabled: boolean
  googleTrackingEnabled: boolean
  awinAffiliateEnabled: boolean
  cjAffiliateUTSEnabled: boolean
  cjAffiliateEnabled: boolean
  hasZipPayUsp: boolean
  hasStoreUsp: boolean
  hasClickAndCollectUsp: boolean
  storyStreamJavaScriptBaseUrl?: string
  storyStreamPdpLayoutClass?: string
  storyStreamPdpAppId?: string
  storyStreamPdpIntegrityHashKey?: string
  showDeliveryAndReturnsInfoInAccordion?: boolean
  hasShoeSizeMeId?: string
  productPageShowFreeDelivery?: boolean
  showOutOfStockNotification?: boolean
  hideEstimatedDelivery?: boolean
  enabledSubscriptionEditFrequency?: boolean
  paymentResolutionViaCustomerService?: boolean
  secondHeaderSlotUrl: string
  cspMode?: CSPMode
  cspReportToUrl?: string
  previewQuickbuy?: boolean
  showPdpReviewsAboveRecommendations?: boolean
  enableImageSwatches?: boolean
  enabledProductSizeDropdown?: boolean
  enabledProductTypeRadio?: boolean
  enabledProductFitDropdown?: boolean
  showProductBlockBrandTitle?: boolean
  showProductBlockBrandLogo?: boolean
  enableProductBrandPageLink?: boolean
  basketShowTotalRrpDiscountPerItem?: boolean
  hideProductListSwatch?: boolean
  enablePreselectedProductVariant?: boolean
  addressBookShowDefaultAddress: boolean
  productPageShowDeliveryInfoBoxes?: boolean
  showDeliveryCalculatedAtCheckoutMessage?: boolean
  showBackwardNavigationForBreadcrumbs?: boolean
  showBasketItemOutOfStockMessage?: boolean
  delayEmailReengagementModal?: number
  showMyReturnsSection: boolean
  showPostalMarketingPreferences: boolean
  provenanceAccessToken?: string
  enableMissingOrdersButton?: boolean
  hideAddressCompanyNameField?: boolean
  showSavingsAmountOnSubscriptionCard?: boolean
  qubit?: {
    enabled: boolean
    placements: {
      urgencyMessagingId: string
      badgingId: string
      personalisedContentId?: string
    }
    personalisedContentRowOnDesktop?: number
    personalisedContentRowOnMobile?: number
    smartServeSourceURL: string
    API: string
    callbackURL: string
    tallyAPI: string
    mode: QubitMode
    trackingId: string
    productPageRecommendationsPlacementId?: string
    basketPageRecommendationsPlacementId?: string
    previewHost?: string
    showUrgencyMsgCloseButton?: boolean
  }
  productPageAttributeOptionKeys?: string[]
  enableGoogleAutoTranslation: boolean
  productPageOptionKeysWithAttribute?: string[]
  useExternalIdentifier?: boolean
  newHeader?: boolean
  useHashedEmail?: boolean
  discussionCategories?: string[]
  deliveryContentKeys?: string[]
  enablePDPGalleryZoom?: boolean
  enablePDPGalleryZoomDesktop?: boolean
  showExposedSearchBarOnMobile?: boolean
  newHeaderUseAlternateGrid?: boolean
  enableStickyFacetsOnMobile?: boolean
  enableBackToSearchWithinBreadCrumbs?: boolean
  PDPDisplayMasterProductTitle?: boolean
  pdpDefaultSubscribeAndSave?: boolean
  showTopProductBrandLogoAndTitleOnMobile?: boolean
  socialShareIcons?: string[]
  enableDefaultHrefLang?: boolean
  headerPromotionUrl?: string
  headerPromotionUrlTwo?: string
  headerPromotionUrls?: { url: string; color: string }[]
  showProductDetails?: boolean
  showDropdownProductVariants?: boolean
  showExternalIdentifierInProductSummary?: boolean
  showOnlyProductSKU?: boolean
  enableEqualColumnSpread?: boolean
  headerSectionPath?: string
  footerSectionPath?: string
  paritySubsiteNavigationPath?: string
  productPageMaxReviewsDisplayed: number
  addBuyNowPayLaterContainerTitle: boolean
  enableThreeTierNav?: boolean
  enableAlternateNavGrid?: boolean
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  enableStoreLocator?: boolean
  enableProductPersonalisation?: boolean
  enableProductPersonalisationInterface?: boolean
  facetsDisplayedOnProductList?: number
  videoAccordionBelowProductDescription: boolean
  papMessageTopPosition?: boolean
  invoicesEnabled?: boolean
  showPapMessageAboveBNPL?: boolean
  showPapMessageInProductSummary?: boolean
  showOnePAPmessageOnPDP?: boolean
  enableProductDescriptionTab?: boolean
  useExternalIdentifierInSchema?: boolean
  showProductSummaryPriceOnSingleLine?: boolean
  hasOneProductPerRowMobilePLP?: boolean
  hasImagesCarouselOnMobilePLP?: boolean
  removeFocusTrap?: boolean
  disableDeliveryInformationInfo?: boolean
  geocodeApiKeyClickAndCollect?: string
  enableSmallQuickBuyButton?: boolean
  hasFourProductsPerRowDesktopPLP?: boolean
  enableProductListGridSelector?: boolean
  enableStickyFacetsOnDesktop?: boolean
  recommendationItemsLimit?: number
  useGA4EnhancedEcom?: boolean
  enableProductRecommendationGrid?: boolean
  showResponsiveSubMenuStoreLocator?: boolean
  enableStoreLocatorIconOnMobileHeader?: boolean
  reorderInfoMessage?: boolean
  enablePricePerUnit?: boolean
  hideSearchPrice?: boolean
  showFlagsOnProduct?: boolean
  clearSelectedTopLevelNavigationItemOnLogoClick?: boolean
  enableQuantityOnManualRecs?: boolean
  enableHomePageBreadcrumb?: boolean
  hideBreadcrumbsOnProductPage?: boolean
  hideBreadcrumbsOnLandingPage?: boolean
  enablePDPStickyScroll?: boolean
  geocodeApiKeyStoreMap?: string
  enablePapOnManualRecs?: boolean
  enableSponsoredAds?: boolean
  openAllVerticalFacets?: boolean
  inlineQuickBuyButton?: boolean
  showFullNameFieldOnWaitlist?: boolean
  displayBasketWithStandardPrice?: boolean
  displayBasketWithOrderSummaryTitle?: boolean
  enableProductQuantityInStockAvailability?: boolean
  primaryNavImages?: string[]
  enableIndexableFacetsPagination?: boolean
  refreshOnMobileFacetChange?: boolean
  disableRecommendationFirstImageAndTitle?: boolean
  enableVipPrice?: boolean
  enableAuroraProduct?: boolean
  enableAuroraReview?: boolean
  enableAuroraSearch?: boolean
  enableAuroraLanding?: boolean
  displayFreeGiftSavingMessage?: boolean
  enableProductOptionRadio?: boolean
  enableProductAmountRadio?: boolean
  displayPriceAboveQuantity?: boolean
  showOOSMessagePDP?: boolean
  enablePromoMessageOnProductDescription?: boolean
  showBrandLogoOnProductPage?: boolean
  alternateResultsPosition?: boolean
  hideProductShowCaseMarketedSpecialOfferBadge?: boolean
  hideRecentlyViewedProductsRailPDP?: boolean
  enableRecaptchaNet?: boolean
  allowedCountryForStoreLocator?: string
  showClickAndCollectProductInfoBox?: boolean
  showClickAndCollectProductTab?: boolean
  enableEllipsisBreadCrumbs?: boolean
  showDropShipReturnText?: boolean
  enableHeroProductImageTag?: boolean
  enableAccountRewardsPage?: boolean
  enableStyleOverrideSixItemCategoryWidget?: boolean
  moveProductDescriptionAccordionToLHS?: boolean
  alternateProductKeysForSwatches?: string[]
  alternateProductKeysForCategories?: string[]
  departmentContentValueForSwatches?: string
  showOutOfStockAsDisabledOnFrequentlyBoughtTogether?: boolean
  disableClickOnProductTitleReviewPage?: boolean
  showTermsConditionTextOnLogin?: boolean
  showTermsConditionTextOnEmailEngagement?: boolean
  showEmailReengagementModalImage?: boolean
  showParentTitleAddToCartGA4?: boolean
  enableRecentSearches?: boolean
  enablePapOverlay?: boolean
  papProductItemHighlightedTitleList?: string[]
  enablePromotionalSearch?: boolean
  enableTrendingSearch?: boolean
  showLoyaltyPointsToNextTier?: boolean
  dynatraceRuxitAgentUrl?: string
  enableEllipsisBreadCrumbsPLP?: boolean
  enableHelpCentreContact?: boolean
  enableABTasty?: boolean
  lockABTasty?: boolean
  ABTastyValue?: string
  asyncABTastyScript?: boolean
  disableColorDropdown?: boolean
  hideMarketedSpecialBadge?: boolean
  hideCTAOnMarketSpecialOffer?: boolean
  enableCanonicalIndexableFacetsTag?: boolean
  showProductInfoDetails?: boolean
  showHullabalookRecommendations?: boolean
  hullabalookRecsExperienceLabel?: string
  showHullablookCTAButton?: boolean
  linkedSkusEnabled?: boolean
  enablePersistentSearch?: boolean
  showAllImageSwatches?: boolean
  showOutOfStockVariants?: boolean
  displaySwatchesOnOneRow?: boolean
  hideThreeTierTab?: boolean
  hideModalBulletPoint?: boolean
  enableSavePrice?: boolean
  enableSelectedNavItem?: boolean
  enablePowerReviewsSchemaRemoval?: boolean
  widgetSimpleTextHideMarginBottom?: boolean
  widgetButtonCollectionHidePaddingBottom?: boolean
  enableSkuOnPowerReviews?: boolean
  enableLinkPrefetch?: boolean
  siteRequiresLogin?: boolean
  linkPrefetchPercentageRollout?: number
  signUpPageBackgroundImage?: string
  styleSheetOnPowerReviews?: string
  enableAlternateSwatch?: boolean
  hideFocusOnLevelOneNav?: boolean
  showPetProfilesOnly?: boolean
  enableMasonryGridMenu?: boolean
  enableDropshipMessageOnBasket?: boolean
  storeLocatorCountry?: string
  enableContactUsInfo?: boolean
  enableFBTProductOptionPlacement?: boolean
  FBTHalfPageWidth?: boolean
  enableAlternateMobileFacet?: boolean
  showUSPDeliveryInformation?: boolean
  enableSubscribeAndSaveInlinePricing?: boolean
  showFrequentlyBoughtBelowAccordion?: boolean
  enablePDPGalleryOverflow?: boolean
  hideCarouselButtonOnMobile?: boolean
  enableScrollableList?: boolean
  enableAlternateBreadcrumbStyle?: boolean
  enableAlternateStoreUrl?: boolean
  enableNoResultsPLP?: boolean
  enableInAppShare?: boolean
  enableExternalCreateMessage?: boolean
  enablePDPStoreStock?: boolean
  enableClickAndCollect?: boolean
  enableScrollToTopBtn?: boolean
  useAfterpayUS?: boolean
  enableFullBreadcrumbsOnMobile?: boolean
}

export interface PublicConfiguration {
  ENABLE_LINK_PREFETCH: boolean
  ENABLE_DYNATRACE_RUXITAGENT: boolean
  APOLLO_CLIENT_TIMEOUT: string
  IGNORE_RATE_LIMITING: boolean
  APP_NAME: string
  APP_VERSION: string
  DEEPSPACE_URL: string
  IS_PRODUCTION: boolean
  ENABLE_LAYOUT_ESI: boolean
  ENABLE_TRANSMIT_BEACONS: boolean
  SITE_PROPERTIES_BUCKET: string
  PRODUCT_OPTIONS_TIMEOUT: number
  assetPrefix: string
  siteConfig: SiteConfigInterface
  siteDefinition: Omit<SiteDefinitionInterface, 'graphqlApi'>
  sessionSettings: SessionSettings
  shippingDestination: ShippingDestination
  basketCookieKey: string
  opaqueCookieKey: string
  shippingDestinations: ShippingDestination[]
  subsites: { [subsite: string]: string }
  subsiteDomains: { [subsite: string]: string }
  countryDomainMap: { [domain: string]: string }
  hreflangs: { [subsite: string]: string }
  theme: EnterpriseThemeInterface | {}
  contentKeys: {
    productBlock: string[]
    productOptions: string[]
    productDescriptionKeys: {
      items: string[]
      details: string[]
      download: string[]
      video: string[]
    }
  }
  dataLayerMap: DataLayerMap
  internationalOverlay: Record<string, InternationalOverlay>
}

export interface ServerConfiguration {
  GRAPHQL_URL: string
  siteDefinition: Pick<
    SiteDefinitionInterface,
    'CONTENT_GRAPHQL_SERVER_URI' | 'graphqlApi'
  >
  THEME_UUID_NAMESPACE: string
  APOLLO_TIMEOUT: string
  APOLLO_ENABLE_RETRIES: boolean
}

export interface Configuration {
  publicRuntimeConfig: PublicConfiguration
  serverRuntimeConfig: ServerConfiguration
  productChoiceTypeMap?: ProductChoiceTypeMap
  productDescriptionKeys?: {
    items: string[]
    details: string[]
    download: string[]
    video: string[]
  }
  isCached: boolean
  dataLayerMap?: DataLayerMap
  contentKeys: {
    productOptions: string[]
    productBlock: string[]
    productDescriptionKeys: {
      items: string[]
      details: string[]
      download: string[]
      video: string[]
    }
  }
}

export interface NextConfig {
  publicRuntimeConfig: {
    ENABLE_LAYOUT_ESI: boolean
    ENABLE_TRANSMIT_BEACONS: boolean
    DEEPSPACE_URL: string
    GRAPHQL_SERVER_URI: string
    GRAPHQL_CLIENT_OVERRIDE: boolean
    IGNORE_RATE_LIMITING: boolean
    USE_TEST_CAPTCHA: boolean
    SITE_PROPERTIES_OVERRIDE: boolean
    SITE_PROPERTIES_BUCKET: string
    APP_NAME: string
    APP_VERSION: string
    APP_GENERATOR_CONTENT: string
    CACHE_PREFIX: string
    IS_PRODUCTION: boolean
    FLAGS: string[]
    FORCE_EMPTY_WIDGET_DATA: boolean
    SENTRY_DSN: string
    RENDER_ALT_SUBSITES: boolean
    assetPrefix: string
    servicePrefix: string
    APOLLO_TIMEOUT: string
    APOLLO_ENABLE_RETRIES: boolean
    siteConfig: {
      /** loaded dynamically */
    }
    siteDefinition: {
      /** loaded dynamically */
    }
    subsites: {
      /** loaded dynamically */
    }
    hreflangs: {
      /** loaded dynamically */
    }
    theme: {
      /** loaded dynamically */
    }
    basketCookieKey: string
    shippingDestinations: ShippingDestination[]
    sessionSettings: {
      /** loaded dynamically */
    }
  }
  serverRuntimeConfig: {
    THEME_UUID_NAMESPACE: string
    CONFIGURATION_PATH: string
    GALILEO_URL: string
    ISOLATED_LOAD_TEST: boolean
    FORCE_SSL: boolean
    DISABLE_CONFIG_CACHE: boolean
    DEEPSPACE_INTERNAL_URL: string
    APOLLO_TIMEOUT: string
    APOLLO_ENABLE_RETRIES: boolean
    siteDefinition: {
      /** loaded dynamically */
    }
  }
  poweredByHeader: boolean
  devIndicators: {
    autoPrerender: boolean
    buildActivity: boolean
  }
  assetPrefix: string
  useFileSystemPublicRoutes: boolean
}

export interface InternationalOverlay {
  redirectDomain: string
  subsiteIdentifier: string
  currencyWithIcon: string
  flagIcon: string
  languageCode: string
  countryCode: string
}

export interface RequestConfig {
  viaFastly: boolean
  previewId: string
  customerLocation: string
  showKeys: boolean
  featureFlags: string[]
  csrf?: string
  applyCodeToBasketState?: ApplyCodeToBasketState
  brand?: string
  host?: string
  hostname?: string
  secure?: boolean
  subsite?: string
  concessionCode?: string
  /** determines if the request comes from the react native mobile app */
  isMobileApp?: boolean
  sessionSettings?: {
    currency: Currency
    shippingDestination: Country
  }
  enableVary: boolean
  enableBrowserPageVisits?: boolean
  allowOverrides?: boolean
  allowSitePropertyOverrides?: boolean
  userAgent?: string
}

export interface BrowserRequestConfig {
  userAgent: string
}

export interface BaseRequestProps {
  pageProps?: any
  requestConfig: RequestConfig
  shippingDestination: ShippingDestination
  currency: Currency
  siteId?: string
  defaultLocale?: string
  channel?: string
  horizonFeatures?: Feature[]
}

export interface RequestPropsConfig {
  config: {
    publicRuntimeConfig: PublicConfiguration
  }
}

type Data = {
  check: string
}

type CheckQuery = {
  data: Data
  extensions: Extensions
}

type RequestConfigQuery = {
  customer: {
    marketingPreferences: boolean | null
    hashedEmail?: string | null
  } | null
}
export interface SessionValidityResponse {
  checkResponse: CheckQuery
  requestConfig: RequestConfigQuery
}
