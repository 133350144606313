import * as React from 'react'

export const SvgAfterpayUs = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={34} height={24} viewBox="0 0 34 24" {...props}>
    <rect width={34} height={24} rx={4} fill={props.fill || '#00D64F'} />
    <path
      d="M17.8071 10.5742C20.3356 11.1161 21.4915 12.1636 21.4915 13.9336C21.4915 16.1525 19.6847 17.7904 16.8642 17.9637L16.589 19.2762C16.5621 19.4045 16.4489 19.4964 16.3179 19.4964H14.1479C13.9691 19.4964 13.8371 19.3294 13.8784 19.1554L14.2267 17.6877C12.8286 17.2899 11.7036 16.5161 11.0477 15.5609C10.9652 15.4407 10.9926 15.2764 11.1077 15.1868L12.6244 14.0072C12.7494 13.91 12.9286 13.9385 13.021 14.0671C13.8246 15.1856 15.0575 15.8481 16.5428 15.8481C17.8793 15.8481 18.8907 15.1979 18.8907 14.2587C18.8907 13.5362 18.385 13.2111 16.6873 12.8499C13.7614 12.2358 12.6055 11.1522 12.6055 9.38219C12.6055 7.32855 14.3314 5.77721 16.9349 5.57572L17.2191 4.22015C17.246 4.09187 17.3591 4 17.4902 4H19.631C19.8076 4 19.9391 4.16305 19.9017 4.33565L19.5726 5.85451C20.6929 6.19935 21.6051 6.81594 22.1729 7.5768C22.2609 7.69474 22.2383 7.86174 22.1245 7.95505L20.7408 9.08972C20.6205 9.18832 20.445 9.16679 20.3472 9.04587C19.6439 8.17604 18.5368 7.68446 17.3375 7.68446C16.001 7.68446 15.1702 8.26241 15.1702 9.09322C15.1702 9.77954 15.8204 10.1408 17.8071 10.5742Z"
      fill="white"
    />
  </svg>
)
export default SvgAfterpayUs
