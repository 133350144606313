import { CustomerLoginState } from '../ConfigurationLoader/types'

export const getCustomerLoginState = (flags: string[]): CustomerLoginState => {
  if (flags.includes('LOGGED_IN')) {
    return CustomerLoginState.LOGGED_IN
  }

  if (flags.includes('RECOGNISED')) {
    return CustomerLoginState.RECOGNISED
  }

  return CustomerLoginState.NOT_RECOGRNISED
}
