import { SiteDefinitionInterface } from '@thg-commerce/enterprise-config'
import { accountAuthenticatedRoutes } from '@thg-commerce/enterprise-core/src/Routing/Routes'
import { Flag } from '@thg-commerce/enterprise-network/src/generated/graphql'

import { getExperimentsList } from '../backend_event/experiments'
import { getPageAttribute } from '../backend_event/pageAttributes'
import { getMetaObject as getMetaObjectFirstLoad } from '../perf/firstLoad/meta'

export interface Experiment {
  name: string
  value: string
}

interface BasketProduct {
  id: string
  masterId: string
  sku: string
  name: string
  price: string
  quantity: string
}

export type CategoryType = '' | 'dept' | 'list'

export interface PageAttribute {
  categoryType: CategoryType
  concession?: string
  currency: string
  elysiumVersion?: string
  experiments: Experiment[] | {}[]
  locale: string
  mobile: string
  pageTemplatePrefix: string
  server?: string
  site: string
  subsite: string
}

interface DataLayer {
  pageCategory: 'register' | 'login' | ''
  currency: string
  site: string
  subsite: string
  locale: string
  pageTemplatePrefix: string
  experiments: Experiment[]
  pageTitle: 'Register' | 'Login' | ''
  visitorId: string
  productDetails: { [key: string]: string }[]
  mobile: 'yes' | 'no'
  visitorLoginState: string
  visitorLoginMethod: string
  visitorLocation: string
  visitorType: string
  visitorEmailAddress?: string
  basketTotal: string
  basketProducts: BasketProduct[]
  siteSearchTerm?: string
  pageAttributes?: PageAttribute[]
  hashedEmail?: string
}

type DataLayerKeys = keyof DataLayer

export const addToDataLayer = ({
  key,
  value,
}: {
  key: DataLayerKeys
  value: any
}) => {
  const dataLayerExists =
    typeof window !== 'undefined' &&
    window['dataLayer'] &&
    window['dataLayer'][0]

  if (!dataLayerExists) {
    window.dataLayer = [{}]
  }
  window['dataLayer'][0][key] = value
}

export const getDataLayer = (
  $window,
  siteDefinition: SiteDefinitionInterface,
  Routes: Routes,
  metricNonce: string,
  isMobile: boolean,
  extensions?: { flags: string[]; experiments: { [key: string]: string } },
  initial = false,
): DataLayer | void => {
  const meta = getMetaObjectFirstLoad(
    siteDefinition,
    'unknown', // not used here, but has other uses so needs a value
    siteDefinition.subsite,
    metricNonce,
  )

  const experiments = getExperimentsList(extensions?.experiments || {})

  addToDataLayer({
    key: 'pageAttributes',
    value: [
      getPageAttribute(
        meta,
        experiments,
        siteDefinition.siteCode,
        isMobile,
        '',
      ),
    ],
  })

  const routeChange = (pathName: string, _initialDataLayer?) => {
    const addToDataLayer = ({
      key,
      value,
    }: {
      key: DataLayerKeys
      value: any
    }) => {
      const dataLayerExists =
        typeof window !== 'undefined' &&
        window['dataLayer'] &&
        window['dataLayer'][0]

      if (
        typeof value === 'undefined' &&
        typeof _initialDataLayer !== 'undefined' &&
        dataLayerExists
      ) {
        delete window.dataLayer[0][key]
      } else {
        typeof _initialDataLayer !== 'undefined'
          ? (_initialDataLayer[key] = value)
          : dataLayerExists && (window['dataLayer'][0][key] = value)
      }
    }

    if (['/product', '.html'].some((route) => pathName.includes(route))) {
      addToDataLayer({ key: 'pageCategory', value: 'product' })
    }
    if (['.list'].some((route) => pathName.includes(route))) {
      addToDataLayer({ key: 'pageCategory', value: 'category' })
      addToDataLayer({
        key: 'pageTitle',
        value: `${pathName.slice(1).replace(/[\/\.]/g, ' ')}`, // removes leaing slash and separates path with spaces e.g. "refresh trending list"
      })
      addToDataLayer({
        key: 'pageAttributes',
        value: [
          getPageAttribute(
            meta,
            experiments,
            siteDefinition.siteCode,
            isMobile,
            'list',
          ),
        ],
      })
    }

    if (['.search'].some((route) => pathName.includes(route))) {
      addToDataLayer({ key: 'pageCategory', value: 'search' })
    }

    if (pathName === '/') {
      addToDataLayer({ key: 'pageCategory', value: 'home' })
      addToDataLayer({
        key: 'pageTitle',
        value: `${siteDefinition.siteName} Homepage`,
      })
      addToDataLayer({
        key: 'pageAttributes',
        value: [
          getPageAttribute(
            meta,
            experiments,
            siteDefinition.siteCode,
            isMobile,
            'dept',
          ),
        ],
      })
    }

    if (pathName.includes(Routes.Basket)) {
      addToDataLayer({ key: 'pageCategory', value: 'basket' })
      addToDataLayer({
        key: 'pageTitle',
        value: 'Basket',
      })
    }

    switch (pathName) {
      case Routes.Register:
        addToDataLayer({ key: 'pageCategory', value: 'register' })
        addToDataLayer({
          key: 'pageTitle',
          value: 'Register',
        })
        break

      case Routes.Login:
        addToDataLayer({ key: 'pageCategory', value: 'login' })
        addToDataLayer({
          key: 'pageTitle',
          value: 'Login',
        })
        break

      case Routes.AccountHome:
        addToDataLayer({ key: 'pageCategory', value: 'account-home' })
        addToDataLayer({
          key: 'pageTitle',
          value: 'Account Home',
        })
        break
      case Routes.PetAndLitterProfile:
        addToDataLayer({ key: 'pageCategory', value: 'pet-and-litter-profile' })
        addToDataLayer({
          key: 'pageTitle',
          value: 'My Pet and Litter Profile',
        })
      case Routes.MyReferrals:
        addToDataLayer({ key: 'pageCategory', value: 'category' })
        addToDataLayer({
          key: 'pageTitle',
          value: 'myreferrals List',
        })
        break
    }

    // This makes sure GTM puts the live person tag on all account pages
    if (
      accountAuthenticatedRoutes.includes(pathName) &&
      pathName !== Routes.AccountHome &&
      pathName !== Routes.MyReferrals
    ) {
      addToDataLayer({ key: 'pageCategory', value: 'other' })
      addToDataLayer({ key: 'pageTitle', value: undefined })
    }

    extensions?.flags?.includes(Flag.LoggedIn)
      ? addToDataLayer({ key: 'visitorLoginState', value: 'loggedin' })
      : addToDataLayer({ key: 'visitorLoginState', value: 'loggedout' })
  }

  if (initial) {
    // Create initial dataLayer object
    const dataLayer = {}

    dataLayer['currency'] = meta.currency
    dataLayer['site'] = siteDefinition.siteCode
    dataLayer['subsite'] = meta.subsiteCode
    dataLayer['locale'] = meta.siteDefaultLocale
    dataLayer['pageTemplatePrefix'] = '' // trader defined - blank for now - should be populated by navigation service
    dataLayer['experiments'] = experiments
    dataLayer['platformType'] = 'elysium 2'

    dataLayer['visitorLoginMethod'] = 'unknown'
    dataLayer['visitorLocation'] = meta.customerLocation

    // For the intital page
    routeChange($window.location.pathname, dataLayer)
    return dataLayer as DataLayer
  }

  // For all subsequent pages
  routeChange($window.location.pathname)
}
