import * as React from 'react'
import ReactResizeDetector from 'react-resize-detector'
import { useSwipeable } from 'react-swipeable'
import loadable from '@loadable/component'
import ResizeObserver from 'resize-observer-polyfill'

const ChevronLeft = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronLeft'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ChevronRight = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronRight'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const PauseIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Pause'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const PlayIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Play'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { Property } from 'csstype'

import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'
import {
  BreakpointArray,
  breakpointUtils,
} from '@thg-commerce/gravity-theme/breakpoints'
import { Margin } from '@thg-commerce/gravity-theme/margin'
import { Padding } from '@thg-commerce/gravity-theme/padding'

import { carouselReducer } from './reducer'
import {
  BottomControlsWrapper,
  CarouselItem,
  CarouselItemContainer,
  CarouselWrapper,
  Container,
  Control,
  ControlsContainer,
  ControlsWrapper,
  OverflowWrapper,
  PageIndicator,
  PageIndicatorGrid,
  PageIndicatorGridItem,
  PageIndicatorWrapper,
} from './styles'
import { CarouselIndicatorStyle, ChevronIconStyle } from './theme'
import {
  AmpCarouselProps,
  CarouselButtonPlacement,
  CarouselI18nText,
  CarouselReducerActionTypes,
  CarouselState,
} from './types'

export type CarouselProps = {
  items: JSX.Element[]
  i18n?: CarouselI18nText
  indicatorStyle?: CarouselIndicatorStyle
  dotHorizontalMargin?: number
  indicatorWrapperMargin?: Margin
  chevronIconStyle?: ChevronIconStyle
  // @deprecated - use controls.placement
  buttonPlacement?: CarouselButtonPlacement
  controls?: {
    visible?: BreakpointArray<boolean>
    placement?: CarouselButtonPlacement
    size?: number
    alternativeChevronStyling?: boolean
    controlBorderColour?: Property.Color
    controlHoverBackgroundColor?: Property.Color
  }
  controlSize?: number
  autoPlay?: boolean
  autoPlaySlideDuration?: number
  inactiveSlideFullOpacity?: boolean
  hideControls?: boolean[] | boolean
  loopSlides?: boolean
  slideTo?: number
  isZoomModal?: boolean
  zoomClickable?: boolean
  zoomOnClick?: (index: number) => void
  onChange?: (index: number) => void
  className?: string
  style?: any
  isAmp?: boolean
  ampProps?: AmpCarouselProps
  swipeable?: boolean
  itemGapSpacing?: number
  ignoreGapOffset?: boolean
  enableSlidePreview?: boolean
  shrinkable?: boolean
  overflow?: BreakpointArray<string>
  fullWidthCarousel?: boolean
  removeControlsOnSingleSlide?: boolean
  onSwipe?: () => void
  onNavClick?: (direction: 'left' | 'right') => void
  onThumbnailClick?: () => void
  indicatorPadding?: Padding
  enableWhiteControls?: boolean
  customTransform?: number
} & InitialCarouselState

type ElementProp = {
  height: number
  width?: number
}
interface InitialCarouselState {
  itemsPerSlide: number | number[]
  items: JSX.Element[]
  hideSlidePreview?: boolean
  hideControlsOnSingleSlide?: boolean
  slideTo?: number
  hideControls?: boolean[] | boolean
}

const MINIMUM_SWIPE_DISTANCE_REQUIRED = 75 // px

const getInitialState = (initialProps: InitialCarouselState): CarouselState => {
  let itemsPerSlide: number

  if (typeof initialProps.itemsPerSlide === 'number') {
    itemsPerSlide = initialProps.itemsPerSlide
  } else {
    let breakpointIndex = -1

    if (typeof window !== 'undefined') {
      Object.values(breakpointUtils.map).forEach((width, index) => {
        if (window.innerWidth > width) {
          breakpointIndex = index
        }
      })
    }

    itemsPerSlide =
      initialProps.itemsPerSlide[breakpointIndex] ||
      initialProps.itemsPerSlide[initialProps.itemsPerSlide.length - 1] ||
      1
  }

  const totalSlides =
    itemsPerSlide > 0 ? Math.ceil(initialProps.items.length / itemsPerSlide) : 0

  const showControls = !(
    initialProps.hideControlsOnSingleSlide && totalSlides < 2
  )

  const currentSlide = initialProps.slideTo ? initialProps.slideTo : 0

  return {
    totalSlides,
    itemsPerSlide,
    showControls,
    currentSlide,
    slideWidth: 0,
    containerWidth: 0,
    paused: false,
    autoPlay: false,
    loopSlides: false,
    slideTo: currentSlide,
  }
}

export const Carousel = ({
  itemsPerSlide,
  items,
  i18n = { leftScrollLabel: '', rightScrollLabel: '', playPauseLabel: '' },
  hideSlidePreview,
  autoPlay = false,
  indicatorStyle = 'dot',
  dotHorizontalMargin = 1,
  autoPlaySlideDuration = 5000,
  hideControlsOnSingleSlide = true,
  inactiveSlideFullOpacity = true,
  hideControls,
  loopSlides = false,
  slideTo = 0,
  onChange,
  className,
  indicatorWrapperMargin,
  fullWidthCarousel,
  removeControlsOnSingleSlide,
  indicatorPadding,
  ...props
}: CarouselProps) => {
  const [state, dispatch] = React.useReducer(carouselReducer, {
    ...getInitialState({
      items,
      itemsPerSlide,
      hideSlidePreview,
      hideControlsOnSingleSlide,
      slideTo,
      hideControls,
    }),
    onChange,
    loopSlides: autoPlay || loopSlides,
  })
  const isSwiping = React.useRef(false)
  const [swipeOffset, setSwipeOffset] = React.useState(0)
  const previousSlideTo = React.useRef(slideTo)

  const swipeHandlers = useSwipeable({
    onSwipeStart: (event) => {
      if (event.dir === 'Right' || event.dir === 'Left') {
        isSwiping.current = true
      }
    },
    onSwiping: (event) => {
      if (!isSwiping.current) {
        return
      }

      event.event.preventDefault()

      setSwipeOffset(event.deltaX)
    },
    onSwiped: () => {
      setSwipeOffset(0)
    },
    onSwipedLeft: () => {
      if (!isSwiping.current) {
        return
      }
      dispatch({ type: CarouselReducerActionTypes.SlideRight })
      isSwiping.current = false
      props.onSwipe?.()
    },
    onSwipedRight: () => {
      if (!isSwiping.current) {
        return
      }
      dispatch({ type: CarouselReducerActionTypes.SlideLeft })
      isSwiping.current = false
      props.onSwipe?.()
    },
    delta: {
      left: MINIMUM_SWIPE_DISTANCE_REQUIRED,
      right: MINIMUM_SWIPE_DISTANCE_REQUIRED,
    },
    touchEventOptions: {
      passive: false,
    },
  })

  const [containerDimensions, setContainerDimensions] = React.useState<
    ElementProp
  >({
    height: 0,
    width: 0,
  })

  const containerRef = React.createRef<HTMLDivElement>()
  const carouselItemsRef = React.useRef<(HTMLLIElement | null)[]>([])

  const pageIndicators = React.useMemo(
    () =>
      indicatorStyle === 'hidden'
        ? null
        : [...Array(state.totalSlides)].map((_, index) => (
            <PageIndicator
              data-testid={`page-indicator-${index}`}
              active={index === state.currentSlide}
              indicatorStyle={indicatorStyle}
              dotHorizontalMargin={dotHorizontalMargin}
              key={index}
              enableWhiteControls={props.enableWhiteControls}
            />
          )),
    [
      indicatorStyle,
      state.totalSlides,
      state.currentSlide,
      dotHorizontalMargin,
      props.enableWhiteControls,
    ],
  )

  const timeOutRef = React.useRef(0)

  React.useEffect(() => {
    if (autoPlay && !state.paused) {
      timeOutRef.current = setTimeout(
        () => dispatch({ type: CarouselReducerActionTypes.SlideRight }),
        autoPlaySlideDuration,
      )
      return () => clearTimeout(timeOutRef.current)
    }
    return
  }, [autoPlay, autoPlaySlideDuration, state.currentSlide, state.paused])

  React.useEffect(() => {
    if (
      slideTo !== undefined &&
      slideTo !== state.currentSlide &&
      slideTo !== previousSlideTo.current
    ) {
      previousSlideTo.current = slideTo
      dispatch({
        type: CarouselReducerActionTypes.SlideTo,
        toIndex: slideTo,
      })
    }
  }, [slideTo])

  React.useEffect(() => {
    if (!carouselItemsRef?.current) {
      return
    }

    const containerObserver: ResizeObserver | undefined = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        const { height, width } = entries[0]?.contentRect
        setContainerDimensions({ height, width })
      },
    )

    if (carouselItemsRef.current[state.currentSlide]?.firstChild) {
      containerObserver.observe(
        carouselItemsRef.current[state.currentSlide]?.firstChild as HTMLElement,
      )
    }

    return () => {
      if (containerObserver) {
        containerObserver.disconnect()
      }
    }
  }, [state.currentSlide])

  const resizeHandler = React.useCallback(
    (width) => {
      dispatch({
        hideSlidePreview,
        hideControlsOnSingleSlide,
        itemsPerSlide,
        type: CarouselReducerActionTypes.Resize,
        containerWidth: width,
        itemsLength: items.length,
      })
    },
    [hideControlsOnSingleSlide, hideSlidePreview, items.length, itemsPerSlide],
  )

  const togglePause = () => {
    if (state.paused) {
      dispatch({ type: CarouselReducerActionTypes.Pause })
      timeOutRef.current = setTimeout(
        () => dispatch({ type: CarouselReducerActionTypes.SlideRight }),
        autoPlaySlideDuration,
      )
    } else {
      dispatch({ type: CarouselReducerActionTypes.Pause })
      clearTimeout(timeOutRef.current)
    }
  }

  if (typeof itemsPerSlide === 'number' && itemsPerSlide <= 0) {
    console.warn('Carousel: no items per slide, cannot divide by 0')
    return null
  }

  const handleItemFocus = (index: number) => {
    const next = Math.floor(index / state.itemsPerSlide)

    containerRef.current && (containerRef.current.scrollLeft = 0)

    if (next !== state.currentSlide) {
      dispatch({
        type:
          next > state.currentSlide
            ? CarouselReducerActionTypes.SlideRight
            : CarouselReducerActionTypes.SlideLeft,
      })
    }
  }

  // @TODO: [REBUILD-6060] remove carousel buttonPlacement
  const controlPlacement = props.controls?.placement || props.buttonPlacement

  const Controls = (
    <ControlsContainer
      hide={!state.showControls}
      controlPlacement={controlPlacement}
      removeControls={removeControlsOnSingleSlide && !state.showControls}
    >
      {autoPlay && (
        <Control
          disabled={false}
          onClick={togglePause}
          aria-label={i18n?.playPauseLabel}
          data-testid="carousel-play-button"
          visible={props.controls?.visible || [true]}
        >
          {state.paused ? (
            <PlayIcon width="24" height="24" />
          ) : (
            <PauseIcon width="24" height="24" />
          )}
        </Control>
      )}
      <Control
        position={HorizontalAlignment.LEFT}
        disabled={autoPlay || loopSlides ? false : state.currentSlide <= 0}
        onClick={() => {
          previousSlideTo.current = previousSlideTo.current - 1
          dispatch({ type: CarouselReducerActionTypes.SlideLeft })
          props.onNavClick?.('left')
        }}
        aria-label={i18n?.leftScrollLabel}
        data-testid="carousel-control-left"
        visible={props.controls?.visible || [true]}
        size={props.controls?.size}
        controlSize={props.controlSize}
        alternativeDisabledStyling={props.controls?.alternativeChevronStyling}
        enableWhiteControls={props.enableWhiteControls}
      >
        <ChevronLeft
          style={{ verticalAlign: 'middle' }}
          width="24"
          height="24"
        />
      </Control>

      <Control
        position={HorizontalAlignment.RIGHT}
        disabled={
          autoPlay || loopSlides
            ? false
            : state.currentSlide >= state.totalSlides - 1
        }
        onClick={() => {
          previousSlideTo.current = previousSlideTo.current + 1
          dispatch({ type: CarouselReducerActionTypes.SlideRight })
          props.onNavClick?.('right')
        }}
        aria-label={i18n?.rightScrollLabel}
        data-testid="carousel-control-right"
        visible={props.controls?.visible || [true]}
        size={props.controls?.size}
        controlSize={props.controlSize}
        alternativeDisabledStyling={props.controls?.alternativeChevronStyling}
        enableWhiteControls={props.enableWhiteControls}
      >
        <ChevronRight
          style={{ verticalAlign: 'middle' }}
          width="24"
          height="24"
        />
      </Control>
    </ControlsContainer>
  )

  const carousel = (
    <React.Fragment>
      <Container
        key="carousel_container"
        ref={containerRef}
        className={className}
        {...(!props.swipeable ? {} : swipeHandlers)}
      >
        {controlPlacement === CarouselButtonPlacement.Split &&
        state.showControls ? (
          <React.Fragment>
            <Control
              disabled={autoPlay ? false : state.currentSlide <= 0}
              onClick={() => {
                previousSlideTo.current = previousSlideTo.current - 1
                dispatch({ type: CarouselReducerActionTypes.SlideLeft })
                props.onNavClick?.('left')
              }}
              aria-label={i18n?.leftScrollLabel}
              data-testid="split-control-left"
              controlBorderColour={props.controls?.controlBorderColour}
              controlHoverBackgroundColor={
                props.controls?.controlHoverBackgroundColor
              }
              buttonPlacement={controlPlacement}
              position={HorizontalAlignment.LEFT}
              visible={props.controls?.visible || [true]}
              size={props.controls?.size}
              controlSize={props.controlSize}
              alternativeDisabledStyling={
                props.controls?.alternativeChevronStyling
              }
              enableWhiteControls={props.enableWhiteControls}
              tabIndex={0}
            >
              {props.chevronIconStyle?.chevronIcon?.left?.svgPath ? (
                <SvgIcon
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox={props.chevronIconStyle.chevronIcon.viewBox}
                  width={props.chevronIconStyle.chevronIcon.width}
                  height={props.chevronIconStyle.chevronIcon.height}
                >
                  <path
                    d={props.chevronIconStyle.chevronIcon.left.svgPath}
                    fillRule="evenodd"
                  />
                </SvgIcon>
              ) : (
                <ChevronLeft width="24" height="24" />
              )}
            </Control>
            <Control
              disabled={
                autoPlay ? false : state.currentSlide >= state.totalSlides - 1
              }
              onClick={() => {
                previousSlideTo.current = previousSlideTo.current + 1
                dispatch({ type: CarouselReducerActionTypes.SlideRight })
                props.onNavClick?.('right')
              }}
              aria-label={i18n?.rightScrollLabel}
              data-testid="split-control-right"
              controlBorderColour={props.controls?.controlBorderColour}
              controlHoverBackgroundColor={
                props.controls?.controlHoverBackgroundColor
              }
              buttonPlacement={controlPlacement}
              position={HorizontalAlignment.RIGHT}
              visible={props.controls?.visible || [true]}
              size={props.controls?.size}
              controlSize={props.controlSize}
              alternativeDisabledStyling={
                props.controls?.alternativeChevronStyling
              }
              enableWhiteControls={props.enableWhiteControls}
              tabIndex={0}
            >
              {props.chevronIconStyle?.chevronIcon?.right?.svgPath ? (
                <SvgIcon
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox={props.chevronIconStyle.chevronIcon.viewBox}
                  width={props.chevronIconStyle.chevronIcon.width}
                  height={props.chevronIconStyle.chevronIcon.height}
                >
                  <path
                    d={props.chevronIconStyle.chevronIcon.right.svgPath}
                    fillRule="evenodd"
                  />
                </SvgIcon>
              ) : (
                <ChevronRight width="24" height="24" />
              )}
            </Control>
          </React.Fragment>
        ) : (
          (!controlPlacement ||
            controlPlacement === CarouselButtonPlacement.BottomRightFloat ||
            controlPlacement === CarouselButtonPlacement.MiddleRight) &&
          state.showControls && (
            <ControlsWrapper
              hideControls={hideControls}
              controlPlacement={controlPlacement}
            >
              {Controls}
            </ControlsWrapper>
          )
        )}
        <OverflowWrapper overflow={props.overflow}>
          <CarouselWrapper
            translateXCurrentSlide={
              -(props.customTransform || 100) * state.currentSlide
            }
            translateXUserSlide={swipeOffset}
            itemGapSpacing={props.ignoreGapOffset ? 0 : props.itemGapSpacing}
            itemsPerSlide={itemsPerSlide}
            itemCount={items.length}
            enableSlidePreview={props.enableSlidePreview}
            currentSlide={state.currentSlide}
            overflow={props.overflow}
          >
            <CarouselItemContainer
              itemsPerSlide={itemsPerSlide}
              itemCount={items.length}
              itemGapSpacing={props.ignoreGapOffset ? 0 : props.itemGapSpacing}
              style={{
                display: 'flex',
                height: props.shrinkable
                  ? `${
                      (carouselItemsRef.current[state.currentSlide]
                        ?.firstChild as HTMLElement | null)?.clientHeight
                    }px`
                  : '100%',
                transformStyle: 'preserve-3d',
                justifyContent: state.totalSlides < 2 ? 'center' : undefined,
              }}
              overflow={props.overflow}
            >
              {items.map((item, index) => {
                const maxIndex = Math.min(
                  state.itemsPerSlide * (state.currentSlide + 1),
                  items.length,
                )
                return (
                  <CarouselItem
                    ref={(item) => (carouselItemsRef.current[index] = item)}
                    itemsPerSlide={itemsPerSlide}
                    itemCount={items.length}
                    inactiveSlideFullOpacity={inactiveSlideFullOpacity}
                    active={index + 1 <= maxIndex}
                    hideSlidePreview={hideSlidePreview}
                    isZoomModal={props.isZoomModal}
                    zoomClickable={props.zoomClickable}
                    onClick={() =>
                      props.zoomOnClick && props.zoomOnClick(index || 0)
                    }
                    controlsHidden={
                      hideControlsOnSingleSlide && !state.showControls
                    }
                    style={{
                      height: 'auto',
                    }}
                    key={index}
                    onFocus={() => handleItemFocus(index)}
                    data-testid={`carousel-item-${index}`}
                    enableSlidePreview={props.enableSlidePreview}
                    spacing={props.itemGapSpacing}
                    overflow={props.overflow}
                    fullWidthCarousel={fullWidthCarousel}
                    isVideo={item.type === 'video'}
                  >
                    {item}
                  </CarouselItem>
                )
              })}
            </CarouselItemContainer>
          </CarouselWrapper>
        </OverflowWrapper>
      </Container>
      <PageIndicatorGrid
        columns={3}
        key="page-indicators"
        indicatorStyle={indicatorStyle}
        marginTop={containerDimensions.height}
        containerWidth={containerDimensions.width}
        margin={indicatorWrapperMargin}
        indicatorPadding={indicatorPadding}
      >
        <PageIndicatorGridItem
          colStart={
            controlPlacement === CarouselButtonPlacement.BottomReversed
              ? [3, 2, 2, 2]
              : controlPlacement === CarouselButtonPlacement.BottomRight
              ? [1, 2, 2, 2]
              : 2
          }
        >
          <PageIndicatorWrapper
            data-testid="page-indicators"
            mobileHorizontalAlignment={
              controlPlacement === CarouselButtonPlacement.BottomReversed
                ? HorizontalAlignment.RIGHT
                : controlPlacement === CarouselButtonPlacement.BottomRight
                ? HorizontalAlignment.LEFT
                : HorizontalAlignment.CENTER
            }
            hide={state.totalSlides < 2}
          >
            {pageIndicators}
          </PageIndicatorWrapper>
        </PageIndicatorGridItem>
        {(controlPlacement === CarouselButtonPlacement.BottomRight ||
          controlPlacement === CarouselButtonPlacement.BottomReversed) && (
          <BottomControlsWrapper
            horizontalAlignment={
              controlPlacement === CarouselButtonPlacement.BottomReversed
                ? HorizontalAlignment.LEFT
                : HorizontalAlignment.RIGHT
            }
            colStart={
              controlPlacement === CarouselButtonPlacement.BottomReversed
                ? 1
                : 3
            }
          >
            {Controls}
          </BottomControlsWrapper>
        )}
      </PageIndicatorGrid>
    </React.Fragment>
  )

  return (
    <ReactResizeDetector
      handleWidth
      refreshMode="debounce"
      refreshRate={100}
      onResize={resizeHandler}
    >
      {carousel}
    </ReactResizeDetector>
  )
}
