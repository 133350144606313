import * as React from 'react'
import dynamic from 'next/dynamic'

import { i18n } from '@thg-commerce/enterprise-core'
import {
  CombinedThemeInterface,
  HeaderSlotTheme,
} from '@thg-commerce/gravity-patterns'
import { styled, withTheme } from '@thg-commerce/gravity-patterns/theme'
import { VisibilityHelper } from '@thg-commerce/gravity-system/components/VisibilityHelper/VisibilityHelper'

import {
  HeaderSlot,
  HeaderSlotDropdownType,
  HeaderSlotLink,
  HeaderSlotType,
} from '../types'

const Icon = styled.svg`
  display: flex;
  justify-content: center;
`

const SoldByTHGIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/SoldByThgIngen'),
)

export interface HeaderSlotsProps {
  headerType: 'header' | 'submenu' | 'flyout'
  theme: CombinedThemeInterface
  slotConfig: HeaderSlot[]
  link?: React.ReactElement
  accountDropdown?: React.ReactElement
  basketDropdown?: React.ReactElement
  sessionSettings?: React.ReactChild
  i18nText?: { text: string; url: string }[]
}

const linkSlot = (
  slotTheme: HeaderSlotTheme,
  slot: HeaderSlotLink,
  Link?: React.ReactElement,
) => {
  const slotAriaLabel = slotTheme.hideText
    ? i18n(slot.textProperty) || slot.urlProperty
    : ''

  const link = React.cloneElement(Link || <div></div>, {
    icon: slotTheme.icon && (
      <Icon
        width={slotTheme.iconWidth || '50px'}
        height={slotTheme.iconHeight || '50px'}
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={slotTheme.viewBox || '0 0 56 48'}
      >
        <path d={slotTheme.icon}></path>
      </Icon>
    ),
    text: slotTheme.hideText ? '' : slot.textProperty,
    ariaLabel: slotAriaLabel,
    url: slot.urlProperty,
    order: slotTheme.order ? slotTheme.order : '0',
  })

  return link
}

export const buildSlot = (
  slotTheme: HeaderSlotTheme,
  slot: HeaderSlot,
  Link?: React.ReactElement,
  accountDropdown?: React.ReactChild,
  basketDropdown?: React.ReactChild,
  sessionSettings?: React.ReactChild,
) => {
  switch (slot.type) {
    case HeaderSlotType.LINK:
      return linkSlot(slotTheme, slot, Link)
    case HeaderSlotType.DROPDOWN:
      if (slot.dropdownType === HeaderSlotDropdownType.ACCOUNT) {
        return accountDropdown
      }
      if (slot.dropdownType === HeaderSlotDropdownType.BASKET) {
        return basketDropdown
      }

    case HeaderSlotType.MODAL:
      return sessionSettings

    case HeaderSlotType.ICON:
      if (slot.iconName === 'SoldByThgIngen') return <SoldByTHGIcon />
      return null
  }
}

const getTempSlot = (
  i18nText: HeaderSlotsProps['i18nText'],
  i18nTextIndex: number,
): HeaderSlotLink => {
  return {
    textProperty: (i18nText && i18nText[i18nTextIndex].text) || '',
    type: HeaderSlotType.LINK,
    urlProperty: (i18nText && i18nText[i18nTextIndex].url) || '#',
  }
}

export const HeaderSlots = withTheme((props: HeaderSlotsProps) => {
  let i18nTextIndex = 0

  const populatedSlots: HeaderSlot[] = props.slotConfig.map((slot) => {
    switch (slot.type) {
      case HeaderSlotType.LINK:
        const tempSlot = getTempSlot(props.i18nText, i18nTextIndex)
        i18nTextIndex += 1
        return tempSlot

      case HeaderSlotType.DROPDOWN:
        return {
          textProperty: i18n(slot.textProperty) || '',
          type: slot.type,
          dropdownType: slot.dropdownType,
        }

      case HeaderSlotType.MODAL:
        return {
          textProperty: i18n(slot.textProperty) || '',
          type: slot.type,
          modalType: slot.modalType,
        }

      case HeaderSlotType.ICON:
        return {
          type: slot.type,
          iconName: slot.iconName,
        }
    }
  })

  return (
    <React.Fragment>
      {populatedSlots.map((slot, index) => {
        const slotAppearance =
          props.theme.patterns.header.slotItems[props.headerType][index]
        const tempSlot = buildSlot(
          slotAppearance,
          slot,
          props.link,
          props.accountDropdown,
          props.basketDropdown,
          props.sessionSettings,
        )

        return (
          <VisibilityHelper
            key={index}
            visibilityStatus={slotAppearance.visibility}
            style={{ order: slotAppearance.order ?? index }}
          >
            {tempSlot}
          </VisibilityHelper>
        )
      })}
    </React.Fragment>
  )
})
