import { Flex } from '@thg-commerce/gravity-system'
import { css, mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'
import { BorderValueTypes } from '@thg-commerce/gravity-theme/elements/formgroup'

import { InputProps } from './types'

export const borderInfo = (type: BorderValueTypes) => {
  return (props) =>
    type !== 'focus'
      ? css`
          border-width: ${props.error
            ? '2px;'
            : props.theme.formgroup.general.border[type].width};
          border-color: ${props.error
            ? props.theme.colors.error.base
            : props.theme.formgroup.general.border[type].color};
          border-radius: ${props.theme.formgroup.general.border[type].radius};
          border-style: ${props.theme.formgroup.general.border[type].style};
        `
      : css`
          border-color: ${props.theme.formgroup.general.border[type].color};
          border-width: ${props.theme.formgroup.general.border[type].width};
          border-radius: ${props.theme.formgroup.general.border[type].radius};
          border-style: ${props.theme.formgroup.general.border[type].style};
        `
}

export const InputWrapper = styled(Flex)<InputProps>`
  background-color: #ffffff;
  flex-direction: ${(props) => (props.ltr ? 'row' : 'row-reverse')};
  padding: ${(props) =>
    props.error
      ? `0 calc(${spacing(1)} - 1px)`
      : props.isMessageBox
      ? `${spacing(1)}`
      : `0 ${spacing(1)}`};
  height: ${(props) => (props.isMessageBox ? '94px;' : '48px;')}

  ${(props) => props.isMessageBox && 'align-items: flex-start;'} 
  ${borderInfo('default')}
  margin: ${(props) => spacing(props.labelHidden ? 0 : 0.5)} 1px 0 1px;

  &:active {
    ${borderInfo('active')}
  }
  &.disabled {
    ${borderInfo('disabled')}
    cursor: not-allowed;
  }
  &:hover {
    ${borderInfo('hover')}
  }
  &:focus-visible {
    ${borderInfo('focus')}
    padding: 0px calc(${spacing(1)} - 1px);
  }
  /* DO NOT GROUP WITH ABOVE BREAKS POLYFILL FOR MSOFT BROWSERS */
  &.focus-visible {
    ${borderInfo('focus')}
    padding: 0px calc(${spacing(1)} - 1px);
  }
`

export const InputElement = styled.input<InputProps>`
  ${(props) =>
    Text(props.theme.formgroup.input.scale, props.theme.formgroup.input.style)}
  outline: none;
  border: none;
  width: 100%;
  box-shadow: 0 0 0 40px ${(props) => props.theme.formgroup.general.background}
    inset;
  ${(props) => props.isMessageBox && 'height: 38px;'}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${(props) =>
      props.theme.formgroup.input.autofillColor};
  }
`

export const InputIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing(1.5)} ${spacing(1)} ${spacing(1.5)} 0;
`

export const InputClearIconWrapper = styled(Flex)<InputProps>`
  flex-direction: ${(props) => (props.ltr ? 'row' : 'row-reverse')};
  ${(props) => (props.ltr ? 'padding-left:4px;' : 'padding-right:4px;')};
  align-items: center;
  ${(props) => props.isMessageBox && 'height: 38px;'}

  button {
    border: 2px solid transparent;
    padding: 2px;

    &:focus-visible {
      outline: none;
      ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
        border: 2px solid ${(props) => props.theme.colors.palette.brand.dark};
      }
    }
  }
`

export const InputClearIcon = styled.button`
  display: flex;
  ${(props) =>
    Text(props.theme.formgroup.icon.scale, props.theme.formgroup.icon.style)}
  color: ${(props) => props.theme.colors.palette.greys.darker};
  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
    cursor: pointer;
  }
`

export const HelperTextContainer = styled.div`
  ${(props) =>
    Text(props.theme.formgroup.error.scale, props.theme.formgroup.error.style)}
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 4px;
  color: ${(props) => props.theme.colors.palette.greys.dark};

  &.disabled {
    color: ${(props) => props.theme.colors.palette.greys.light};
    cursor: not-allowed;
  }
`
