import { Property } from 'csstype'

import { Margin, Padding, TextStyling } from '@thg-commerce/gravity-theme'

export type ChevronIconStylingType = {
  svgPath?: string
  height?: string
  width?: string
  viewBox?: string
  fillColor?: Property.Color
}

export interface AccordionThemeInterface {
  chevronIcon?: ChevronIconStylingType
  title: {
    textStyling?: TextStyling
    padding?: Padding
  }
  content?: {
    padding: Padding
  }
  hideTitle?: boolean
  margin?: Margin
}

export const theme: AccordionThemeInterface = {
  chevronIcon: {
    svgPath: '',
    width: '24px',
    height: '24px',
    viewBox: '0 0 24 24',
    fillColor: '',
  },
  title: {
    textStyling: {
      entry: 'bodyText',
      style: 'alternate',
      textDecoration: 'none',
      transform: 'none',
      textColor: '#000000',
    },
    padding: {
      top: 1,
      bottom: 1,
      right: 1,
      left: 1,
    },
  },
  content: {
    padding: {
      top: 0,
      right: 1,
      bottom: 0,
      left: 1,
    },
  },
  margin: {
    top: 2,
    bottom: 2,
    left: 'auto',
    right: 'auto',
  },
}
