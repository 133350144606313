import * as React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { getMarkupFromTree } from '@apollo/react-ssr'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { AppContext } from 'next/app'
import Head from 'next/head'

import {
  createSubscribableRef,
  SubscribableRef,
} from '@thg-commerce/enterprise-utils/src/SubscribableRef'

import { Resolvers } from '../src/ApolloProvider/resolvers'
import { OverrideClient } from '../src/ApolloProvider/utils'
import { Configuration, Extensions, Logger, NetworkAppContext } from '../types'
import { metricProperties } from '../utils/metrics'
import { graphqlUrls } from '../utils/urls'

import initApollo from './initApollo'

export interface WithApolloInitialProps {
  apolloState?: NormalizedCacheObject
  chumewe?: {
    user: string
    session: string
  }
  logger: Logger
  extensions: Extensions
  config: Configuration
  allowOverrides: boolean
  allowSitePropertyOverrides: boolean
}

export interface WithApolloAppInitialProps {
  extensionsRef?: SubscribableRef<Extensions | undefined>
  apolloState?: NormalizedCacheObject
  chumewe?: {
    user: string
    session: string
  }
  logger: Logger
  config: Configuration
}

interface WithApolloAppProps {
  apolloClient: ApolloClient<NormalizedCacheObject>
}

export const withApollo = <AppProps, InitialProps = {}>(
  App: React.ComponentType<WithApolloAppInitialProps & AppProps> & {
    getInitialProps?: (ctx: AppContext) => Promise<InitialProps>
  },
): React.FunctionComponent<
  WithApolloAppProps & WithApolloInitialProps & AppProps & InitialProps
> & {
  getInitialProps: (
    ctx: NetworkAppContext,
  ) => Promise<WithApolloInitialProps & InitialProps>
} => {
  let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

  type AllProps = WithApolloInitialProps & AppProps & InitialProps

  const WithApollo = (props: AllProps) => {
    const { publicRuntimeConfig, serverRuntimeConfig } = props.config

    const initialExtensions =
      props.allowOverrides && OverrideClient(publicRuntimeConfig)
        ? {
            experiments: {},
            flags: ['LOGGED_IN'],
            rateLimitersFiring: [],
            ray: '',
            weight: { weight: 0, maxWeight: 100 },
            LoggerLinkData: {
              start_timestamp: 0,
              duration_ms: 500,
            },
          }
        : props.extensions

    const extensionsRef = createSubscribableRef<Extensions | undefined>(
      initialExtensions,
    )

    const {
      value: [, setExtensions],
    } = extensionsRef

    if (!apolloClient || !(process as any).browser) {
      apolloClient = initApollo({
        initialState: props.apolloState,
        uris: graphqlUrls(publicRuntimeConfig, serverRuntimeConfig),
        metrics: metricProperties(publicRuntimeConfig),
        setExtensions: (extensionsData) => {
          setExtensions(extensionsData)
        },
        logger: props.logger,
        timeout:
          serverRuntimeConfig?.APOLLO_TIMEOUT ||
          publicRuntimeConfig.APOLLO_CLIENT_TIMEOUT,
        enableRetries: serverRuntimeConfig?.APOLLO_ENABLE_RETRIES,
        modifiers: {
          ignoreRateLimit: publicRuntimeConfig.IGNORE_RATE_LIMITING,
          chumewe: props.chumewe,
          overrides:
            props.allowOverrides && OverrideClient(publicRuntimeConfig),
        },
      })
      apolloClient.addResolvers(
        Resolvers(
          apolloClient,
          publicRuntimeConfig,
          props.allowOverrides,
          props.allowSitePropertyOverrides,
        ),
      )
    }

    const { _, chumewe, apolloState, extensions, ...appProps } = props
    return (
      <App
        {...(appProps as AppProps & { logger: Logger; config: Configuration })}
        extensionsRef={extensionsRef}
        apolloClient={apolloClient}
      />
    )
  }

  WithApollo.getInitialProps = async (ctx: NetworkAppContext) => {
    const { Component, router, ctx: context } = ctx
    const { logger, config: appConfig, theme } = context

    let apollo: ApolloClient<NormalizedCacheObject> | undefined
    let chumewe: { user: string; session: string } | undefined
    let extensions: Extensions | undefined
    let allowOverrides = false
    let allowSitePropertyOverrides = false

    if (context.req) {
      const {
        publicRuntimeConfig,
        serverRuntimeConfig,
        productBlockContentKeys,
      } = appConfig

      extensions = context.req.extensions

      const cookies = context.req.headers.cookie
        ?.split('; ')
        .reduce((prev, current) => {
          const [name, value] = current.split('=')
          prev[name] = value
          return prev
        }, {})

      chumewe = {
        user: cookies?.['chumewe_user'] || '',
        session: cookies?.['chumewe_sess'] || '',
      }

      allowOverrides =
        context.req.headers['x-enterprise-allow-overrides'] === '1'
      allowSitePropertyOverrides =
        context.req.headers['x-enterprise-allow-site-property-overrides'] ===
        '1'

      const opaqueToken = publicRuntimeConfig.opaqueCookieKey
        ? context.req.cookies[publicRuntimeConfig.opaqueCookieKey]
        : undefined

      apollo = initApollo({
        logger,
        initialState: {},
        uris: graphqlUrls(publicRuntimeConfig, serverRuntimeConfig),
        metrics: metricProperties(publicRuntimeConfig),
        setExtensions: (extensionsData) => (extensions = extensionsData),
        timeout:
          serverRuntimeConfig?.APOLLO_TIMEOUT ||
          publicRuntimeConfig.APOLLO_CLIENT_TIMEOUT,
        enableRetries: serverRuntimeConfig?.APOLLO_ENABLE_RETRIES,
        modifiers: {
          chumewe,
          ignoreRateLimit: publicRuntimeConfig.IGNORE_RATE_LIMITING,
          auth: context.req['auth'],
          overrides: allowOverrides && OverrideClient(publicRuntimeConfig),
          opaqueToken:
            typeof opaqueToken === 'string' &&
            publicRuntimeConfig.opaqueCookieKey
              ? {
                  value: opaqueToken,
                  cookieName: publicRuntimeConfig.opaqueCookieKey,
                }
              : undefined,
        },
        originReq: {
          userAgent: context.req.headers['user-agent'],
        },
      })

      apollo.addResolvers(
        Resolvers(
          apollo,
          publicRuntimeConfig,
          allowOverrides,
          allowSitePropertyOverrides,
          context.req.config.enableVary,
          productBlockContentKeys,
        ),
      )

      ctx.ctx.apolloClient = apollo
    }

    let appInitialProps = {} as InitialProps
    if (App.getInitialProps) {
      appInitialProps = await App.getInitialProps(ctx)
    }

    let apolloState: NormalizedCacheObject = {}
    if (apollo) {
      if (!process.env.DISABLE_APOLLO_SSR) {
        try {
          let pageProps = {}
          if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(context)
          }

          const { shippingDestination } = context.config.publicRuntimeConfig
          // Run all GraphQL queries
          await getMarkupFromTree({
            renderFunction: renderToStaticMarkup,
            tree: (
              <App
                {...({
                  pageProps,
                } as AppProps & { pageProps: any })}
                Component={Component}
                router={router}
                apolloClient={apollo}
                logger={logger}
                config={appConfig}
                requestConfig={{
                  customerLocation: 'unknown',
                  userAgent: '',
                  previewId: '',
                  showKeys: false,
                  csrf: null,
                  featureFlags: [],
                }}
                shippingDestination={shippingDestination}
                siteProperties={{}}
                theme={theme}
              />
            ),
          })
        } catch (error) {
          console.error('Error while running `getDataFromTree`', error)
        }

        apolloState = apollo.cache.extract()
      }
    }

    return {
      ...appInitialProps,
      logger,
      apolloState,
      extensions,
      chumewe,
      // ip,
      allowOverrides,
      allowSitePropertyOverrides,
      config: appConfig,
    }
  }

  WithApollo.displayName = 'withApollo(App)'

  return WithApollo
}
