import * as React from 'react'

export const SvgTrustpilot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="-8 -8 40 40" {...props}>
    <path
      d="M24.96 9.156h-9.534L12.488 0 9.535 9.156 0 9.136l7.71 5.665-2.952 9.156 7.715-5.664 7.71 5.664-2.933-9.156zm0 0"
      fill="#00b67a"
    />
    <path d="m17.906 16.871-.656-2.07-4.762 3.492zm0 0" fill="#005128" />
  </svg>
)
export default SvgTrustpilot
