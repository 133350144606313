import { ProgressIndicatorStyle } from '../ProgressIndicator'

export enum CircularIndicatorType {
  FULL = 'full-circle',
  SEMI = 'semi-circle',
}

export enum BarIndicatorPosition {
  FIXED = 'fixed',
  UNSET = 'unset',
}

export enum ProgressIndicatorType {
  CIRCULAR = 'circular',
  BAR = 'bar',
}

export interface PointsCardInterface {
  imageUrl?: string
  icon?: {
    svgPath: string
    width: string
    height: string
    viewBox: string
  }
}

export interface AccountEarnPointsInterface {
  shopCard: PointsCardInterface
  earnCard: PointsCardInterface
  redeemCard: PointsCardInterface
}

export type AccountProgressIndicatorInterface = ProgressIndicatorStyle & {
  type: ProgressIndicatorType
}

export interface AccountRewardsInterface {
  earnPoints: AccountEarnPointsInterface
  progressIndicator: AccountProgressIndicatorInterface
}

export const theme: AccountRewardsInterface = {
  earnPoints: {
    shopCard: {},
    earnCard: {},
    redeemCard: {},
  },
  progressIndicator: {
    title: {
      font: {
        entry: 'xlarge1',
        style: 'alternate',
        transform: 'none',
        textDecoration: 'none',
        textColor: 'blue',
      },
    },
    currentCountText: {
      font: {
        entry: 'large2',
        style: 'alternate',
        transform: 'none',
        textDecoration: 'none',
        textColor: '#222222',
      },
    },
    itemsText: {
      font: {
        entry: 'medium1',
        style: 'alternate',
        transform: 'none',
        textDecoration: 'none',
        textColor: '#222222',
      },
    },
    type: ProgressIndicatorType.CIRCULAR,
    circularIndicator: {
      shape: CircularIndicatorType.FULL,
      width: 128,
      height: 128,
      padding: {
        top: 1,
        right: 1,
        bottom: 1,
        left: 1,
      },
      border: {
        width: '0',
        style: 'none',
        color: 'transparent',
      },
    },
    barIndicator: {
      position: BarIndicatorPosition.UNSET,
      barHeight: 8,
      containerHeight: 'auto',
      padding: {
        top: 2,
        right: 0,
        bottom: 2,
        left: 0,
      },
      backgroundColor: '#D8D8D8',
    },
  },
}
