import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing(1)};
`

export const LoyaltyText = styled.span`
  ${Text('bodyText', 'alternate')};
`
