import { BreakpointArray, css, mediaQueryRenderer, spacing } from './'

export type MarginValue = string | number | BreakpointArray<string | number>

export interface Margin {
  top?: MarginValue
  right?: MarginValue
  bottom?: MarginValue
  left?: MarginValue
}

export type ItemMargin =
  | 'marginNone'
  | 'marginBottom'
  | 'marginTop'
  | 'marginBoth'

export const margin = (margin: Margin) =>
  css`
    ${Object.entries(margin).map(
      ([side, marginValue]: [string, MarginValue]) => {
        if (marginValue === undefined) return null
        if (Array.isArray(marginValue)) {
          return mediaQueryRenderer(
            marginValue,
            (value) =>
              `margin-${side}: ${
                typeof value === 'number' ? spacing(value) : value
              };`,
          )
        }
        return `margin-${side}: ${
          typeof marginValue === 'number' ? spacing(marginValue) : marginValue
        };`
      },
    )}
  `
