import * as React from 'react'
import { v4 as uuid } from 'uuid'

import { styled } from '@thg-commerce/gravity-elements/theme'
import { spacing, Text } from '@thg-commerce/gravity-theme'

import { visuallyHiddenMixin } from '../../VisuallyHidden'
import { RadioInputProps } from '../types'

const OptionRadioLabel = styled.label<{
  selected: boolean
  disabled?: boolean
  borderWidth?: string
  isSingleOption?: boolean
}>`
  ${(props) =>
    Text(
      'bodyText',
      props.theme.elements.squaredRadioInput.alternateStyle?.activeFontStyle ||
        'default',
    )};
  display: flex;
  flex-grow: ${(props) =>
    props.theme.elements.squaredRadioInput.alternateStyle?.flexGrow};
  flex-shrink: ${(props) =>
    props.theme.elements.squaredRadioInput.alternateStyle?.flexShrink};
  flex-basis: ${(props) =>
    props.theme.elements.squaredRadioInput.alternateStyle?.flexBasis};
  border-radius: ${(props) =>
    props.theme.elements.squaredRadioInput.alternateStyle?.borderRadius};
  max-width: ${(props) =>
    props.isSingleOption
      ? props.theme.elements.squaredRadioInput.singleOptionMaxWidth
      : '100%'};
  justify-content: center;
  align-items: center;
  margin: ${spacing(1.125)} 0 ${spacing(1.125)} 0;
  padding: ${spacing(1)};
  height: ${(props) => props.theme.elements.squaredRadioInput.height};
  min-width: ${(props) => props.theme.elements.squaredRadioInput.minWidth};
  border: 1px solid ${(props) => props.theme.colors.palette.greys.darker};
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  outline-offset: 30px;
  aspect-ratio: ${(props) =>
    props.theme.elements.squaredRadioInput.aspectRatio};
  position: relative;

  &:hover {
    cursor: pointer;
    outline: ${(props) => props.borderWidth} solid
      ${(props) =>
        !props.selected
          ? props.theme.elements.squaredRadioInput.alternateStyle?.outline ||
            props.theme.colors.palette.brand.base
          : 'none'};

    outline-offset: ${(props) =>
      props.theme.elements.squaredRadioInput.alternateStyle?.outlineOffset ||
      '3px'};
    border: ${(props) =>
      props.theme.elements.squaredRadioInput.alternateStyle?.hoverBorder ||
      '1px solid'};
  }

  ${(props) =>
    props.selected &&
    `
      border: ${
        props.theme.elements.squaredRadioInput.alternateStyle?.selectedBorder ||
        '1px solid'
      };
      padding: ${spacing(1)};
      outline: ${props.borderWidth} solid
        ${
          props.theme.elements.squaredRadioInput.alternateStyle?.outline ||
          props.theme.colors.palette.brand.base
        };
      outline-offset: ${
        props.theme.elements.squaredRadioInput.alternateStyle?.outlineOffset ||
        '3px'
      };
      ${
        props.theme.elements.squaredRadioInput.customSelectedTextColor &&
        `color:${props.theme.elements.squaredRadioInput.customSelectedTextColor};`
      }
      ${
        props.theme.elements.squaredRadioInput.customSelectedBackgroundColor &&
        `background-color:${props.theme.elements.squaredRadioInput.customSelectedBackgroundColor};`
      }
    `}

  ${(props) =>
    props.disabled &&
    `
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          to top left,
          transparent calc(50% - 1px),
          ${props.theme.colors.palette.greys.grey},
          transparent calc(50% + 1px)
        );
      }
    `}
`

const HiddenRadioButton = styled.input`
  ${visuallyHiddenMixin}

  &:focus-visible + ${OptionRadioLabel} {
    outline: 2px solid
      ${(props) =>
        props.theme.elements.squaredRadioInput.alternateStyle?.outline ||
        props.theme.colors.palette.brand.base};
    outline-offset: 3px;
  }
`

const RadioInputAsButton = styled.button`
  ${visuallyHiddenMixin}

  &:focus-visible + ${OptionRadioLabel} {
    outline: 2px solid
      ${(props) =>
        props.theme.elements.squaredRadioInput.alternateStyle?.outline ||
        props.theme.colors.palette.brand.base};
    outline-offset: 3px;
  }
`

export const SquaredRadioInput = (props: RadioInputProps) => {
  const radioId = uuid()

  const testId = props.value
    ? `-${props.value.toLowerCase().split(' ').join('-')}`
    : ''

  return (
    <React.Fragment>
      {props.alternateClassName && !props.disabled ? (
        <RadioInputAsButton
          name={props.name}
          value={props.value}
          id={radioId}
          onClick={() =>
            props.setGroupState && props.setGroupState(props.value)
          }
          disabled={props.disabled}
        >
          {props.value}
        </RadioInputAsButton>
      ) : (
        <HiddenRadioButton
          name={props.name}
          value={props.value}
          type="radio"
          id={radioId}
          onChange={() =>
            props.setGroupState && props.setGroupState(props.value)
          }
          disabled={props.disabled}
        />
      )}
      <OptionRadioLabel
        isSingleOption={props.isSingleOption}
        htmlFor={radioId}
        selected={props.value === props.radioGroupState}
        data-testid={`squared-radio-label${testId}`}
        disabled={props.useDisabledStyling ?? props.disabled}
        borderWidth={props.borderWidth}
      >
        {props.title ? props.title : props.value}
      </OptionRadioLabel>
    </React.Fragment>
  )
}
