import React from 'react'

import { styled } from '@thg-commerce/gravity-patterns/theme'
import { mq } from '@thg-commerce/gravity-theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;
  margin-bottom: 6px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-bottom: 10px;
  }
`

const Row = styled.div`
  color: ${(props) => props.theme.colors.palette.brand.light};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

interface SelectedOptionProps {
  selectedOption: { [key: string]: string }
}

export const SelectedOption: React.FC<SelectedOptionProps> = ({
  selectedOption,
}) => (
  <Container>
    {Object.entries(selectedOption).map(([key, value]) => (
      <Row key={key}>
        <strong>{key}:</strong> {value}
      </Row>
    ))}
  </Container>
)

export default SelectedOption
