import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

const ArrowTop = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/ArrowTop'),
)

const ScrollContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 8px;
  right: 8px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  border: 1px solid #000000;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: opacity 0.3s, transform 0.2s, box-shadow 0.2s;
  background-color: white;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25);
  }
`

const ScrollToTopButton: React.FC = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.pageYOffset > 600) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  React.useEffect(() => {
    window.addEventListener('scroll', toggleVisibility, { passive: true })

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  return (
    <ScrollContainer
      data-testid="scroll-to-top-btn"
      onClick={scrollToTop}
      visible={visible}
    >
      <ArrowTop />
    </ScrollContainer>
  )
}

export default ScrollToTopButton
