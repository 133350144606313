import merge from 'lodash.merge'
import * as styledComponents from 'styled-components'

import { theme as BaseTheme } from '@thg-commerce/gravity-theme'

import { theme as AccountHomeHeroBannerTheme } from './AccountHomeHeroBanner/theme'
import { theme as AccountRewardsTheme } from './AccountRewards/theme'
import { theme as AddedToBasketModalTheme } from './AddedToBasketModal/theme'
import { theme as CarouselTheme } from './Carousel/theme'
import { theme as ContentHeaderTheme } from './ContentHeader/theme'
import { theme as FooterTheme } from './Footer/theme'
import { theme as HeaderTheme } from './Header/theme'
import { theme as HorizontalScrollWrapperTheme } from './HorizontalScrollWrapper/theme'
import { theme as LoyaltyHeroBannerTheme } from './LoyaltyHeroBanner/theme'
import { theme as MarketedSpecialOfferTheme } from './MarketedSpecialOfferSummary/theme'
import { theme as PackagingDesignBlockThemeInterface } from './PackageDesignBlock/theme'
import {
  ManualRecomendationTheme,
  theme as ProductBlockTheme,
} from './ProductBlock/theme'
import { theme as ProductDescriptionAccordionTheme } from './ProductDescriptionAccordion/theme'
import { theme as QuickBuyModalTheme } from './QuickBuyModal/theme'
import { theme as ReadMoreTheme } from './ReadMore/theme'
import { theme as RewardCardImageTheme } from './RewardCardImage/theme'
import { theme as SimpleHeaderTheme } from './SimpleHeader/theme'
import { theme as StepperThemeInterface } from './Stepper/theme'
import { theme as StoreSearchInterfaceTheme } from './StoreSearch/theme'
import { theme as TabsInterfaceTheme } from './Tabs/theme'
import { CombinedThemeInterface, PatternThemeInterface } from './'

export const patternTheme: PatternThemeInterface = {
  patterns: {
    simpleHeader: SimpleHeaderTheme,
    header: HeaderTheme,
    footer: FooterTheme,
    contentHeader: ContentHeaderTheme,
    productDescriptionAccordion: ProductDescriptionAccordionTheme,
    accountHomeHeroBanner: AccountHomeHeroBannerTheme,
    loyaltyHeroBanner: LoyaltyHeroBannerTheme,
    rewardCardImage: RewardCardImageTheme,
    rewards: AccountRewardsTheme,
    imageGallery: {
      image: {
        aspectRatio: '1/1',
      },
      carouselControls: {
        display: 'flex',
      },
    },
    addedToBasketModal: AddedToBasketModalTheme,
    productBlock: ProductBlockTheme,
    manualRecommendations: ManualRecomendationTheme,
    readMore: ReadMoreTheme,
    horizontalScrollWrapper: HorizontalScrollWrapperTheme,
    marketedSpecialOffer: MarketedSpecialOfferTheme,
    quickBuyModal: QuickBuyModalTheme,
    tabs: TabsInterfaceTheme,
    carousel: CarouselTheme,
    storeSearch: StoreSearchInterfaceTheme,
    stepper: StepperThemeInterface,
    packagingDesignBlock: PackagingDesignBlockThemeInterface,
    miniBasket: {
      displayRrpPerUnit: false,
      showRrpInline: false,
    },
  },
  widgets: {
    stripBanner: {
      backgroundColor: '#efefff',
      hoverBackgroundColor: '#bfbdff',
      hoverTextColor: '#333333',
      border: {
        focusWidth: '3px',
      },
      font: {
        entry: 'small',
        style: 'default',
        transform: 'none',
        textColor: '#333333',
        textDecoration: 'none',
      },
      margin: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
    uspBar: {
      backgroundColor: '#f2f2f2',
      iconFill: '#333333',
      padding: 1,
      icon: {
        width: '24px',
        height: '24px',
        viewBox: '0 0 24 24',
        rewards: {
          svgPath: '',
          order: 1,
        },
        countDown: {
          svgPath: '',
          order: 3,
        },
        internationalDelivery: {
          svgPath: '',
          order: 4,
        },
        checkmark: {
          svgPath: '',
          order: 7,
        },
        trustpilot: {
          svgPath: '',
          order: 10,
        },
        calender: {
          svgPath: '',
          order: 9,
        },
        email: {
          svgPath: '',
          order: 6,
        },
        delivery: {
          svgPath: '',
          order: 0,
        },
        call: {
          svgPath: '',
          order: 5,
        },
        quality: {
          svgPath: '',
          order: 2,
        },
        clickAndCollect: {
          svgPath: '',
          order: 4,
        },
        store: {
          svgPath: '',
          order: 3,
        },
        returns: {
          svgPath: '',
          order: 8,
        },
      },
      font: {
        entry: 'small',
        style: 'default',
        transform: 'none',
        textColor: '#333333',
        textDecoration: 'none',
      },
      hoverFont: {
        entry: 'small',
        style: 'default',
        transform: 'none',
        textColor: '#333333',
        textDecoration: 'none',
      },
    },
  },
}

const theme = merge({}, BaseTheme, patternTheme)

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  useTheme,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<
  CombinedThemeInterface
>

export {
  createGlobalStyle,
  css,
  keyframes,
  styled,
  theme,
  ThemeProvider,
  useTheme,
  withTheme,
}
