import { Button, DrawFocus } from '@thg-commerce/gravity-elements'
import { LoyaltyPoints } from '@thg-commerce/gravity-patterns/LoyaltyPoints'
import { ProductBlock } from '@thg-commerce/gravity-patterns/ProductBlock'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { spacing, Text } from '@thg-commerce/gravity-theme'

export const StyledBasketViewContainer = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
`

export const StyledBasketViewText = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${spacing(2)};
`

export const QuantityContainer = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.basket.font.bodyText.entry,
      props.theme.patterns.header.dropdowns.basket.font.bodyText.style,
    )};
  text-decoration: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.bodyText.textDecoration};
  text-transform: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.bodyText.transform};
  color: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.bodyText.textColor};
  flex: 1;
`

export const BasketButton = styled(Button)`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.basket.font.cta.entry,
      props.theme.patterns.header.dropdowns.basket.font.cta.style,
    )};
  text-decoration: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.cta.textDecoration};
  text-transform: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.cta.transform};
  color: inherit;
  min-width: 174px;
  width: max-content;
  height: 48px;
  white-space: nowrap;
  margin: 0 ${spacing(2)} ${spacing(2)};
`

export const StyledShoppingButton = styled(Button)<{ empty?: boolean }>`
  margin: ${spacing(2)};
  ${(props) => props.empty && `margin-top:0;`}
`

export const StyledBasketEmptyText = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.basket.font.bodyText.entry,
      props.theme.patterns.header.dropdowns.basket.font.bodyText.style,
    )};
  text-decoration: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.bodyText.textDecoration};
  text-transform: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.bodyText.transform};
  color: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.bodyText.textColor};
  padding: ${spacing(2)};
`

export const StyledButtonText = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.basket.font.cta.entry,
      props.theme.patterns.header.dropdowns.basket.font.cta.style,
    )};
  text-decoration: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.cta.textDecoration};
  text-transform: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.cta.transform};
  color: inherit;
`

export const StyledProductContainer = styled.li<{ isFreeGift?: boolean }>`
  border: 2px solid transparent;

  &::after {
    content: '';
    border-bottom: 1px solid
      ${(props) => props.theme.colors.palette.greys.light};
    margin: 0 ${spacing(2)};
    width: calc(100% - ${spacing(4)});
    display: block;
  }

  &:hover {
    ${(props) =>
      !props.isFreeGift &&
      `
    background: ${props.theme.colors.palette.greys.lighter};`}
  }
  ${(props) =>
    props.isFreeGift &&
    `
      pointer-events:none;`}
`

export const TotalPriceText = styled.span`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.basket.font.alternateText.entry,
      props.theme.patterns.header.dropdowns.basket.font.alternateText.style,
    )};
  text-decoration: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.alternateText
      .textDecoration};
  text-transform: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.alternateText.transform};
  color: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.font.alternateText.textColor};
`

const MOBILE_DROPDOWN_TRIGGER_HEIGHT = 48
const BASKET_CONTAINER_HEADER_HEIGHT = 128

export const ProductsContainer = styled.div<{
  isMobile: boolean
}>`
  max-height: ${(props) =>
    props.isMobile
      ? `calc(100vh - ${MOBILE_DROPDOWN_TRIGGER_HEIGHT}px - ${BASKET_CONTAINER_HEADER_HEIGHT}px)`
      : `400px`};
  overflow-y: auto;

  @supports (height: 100dvh) {
    max-height: ${(props) =>
      props.isMobile
        ? `calc(100dvh - ${MOBILE_DROPDOWN_TRIGGER_HEIGHT}px - ${BASKET_CONTAINER_HEADER_HEIGHT}px)`
        : `400px`};
  }
`

export const StyledProductBlock = styled(ProductBlock)`
  padding: ${spacing(2)} ${spacing(2)} calc(${spacing(2)} + (${spacing(1)} / 2));

  ${DrawFocus()}
`

export const StyledLoyaltyPoints = styled(LoyaltyPoints)`
  margin: ${spacing(2)} ${spacing(2)} ${spacing(1)};
`
