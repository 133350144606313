import { styled, Text } from '@thg-commerce/gravity-theme'

export const Label = styled.label<{
  labelCustomComponent?: boolean
  labelStylingInline?: boolean
}>`
  display: ${(props) => (props.labelStylingInline ? 'inline' : 'flex')};
  flex-direction: ${(props) => (props.labelCustomComponent ? 'row' : 'column')};
  justify-content: ${(props) =>
    props.labelCustomComponent ? 'space-between' : 'flex-start'};

  ${(props) =>
    Text(props.theme.formgroup.label.scale, props.theme.formgroup.label.style)}
  cursor: pointer;

  &.disabled {
    color: ${(props) => props.theme.colors.palette.greys.light};
    cursor: not-allowed;
  }
`
