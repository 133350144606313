import { Property } from 'csstype'

import { ButtonEmphases } from '@thg-commerce/gravity-elements'
import {
  TextDecoration,
  TextEntry,
  TextStyle,
  TextTransform,
} from '@thg-commerce/gravity-theme'
import { BorderBox } from '@thg-commerce/gravity-theme/border'

import { IconStyling } from '../Header/theme'
import { Border } from '../Header/types'
import { TextStyling } from '../'

export enum SocialIcon {
  black = 'black',
  color = 'colour',
  grey = 'grey',
  whiteOpacity = 'whiteOpacity',
  whiteOutline = 'whiteOutline',
}

export enum CopyrightLogo {
  thg = 'thg',
  ingenuity = 'ingenuity',
  thgSimpleLogo = 'thgSimpleLogo',
  alternateCopyrightLogo = 'alternateCopyrightLogo',
}

type Separator = {
  color: Property.Color
  width: string
}

type DarkConfigTextStyling = {
  entry: TextEntry
  style: TextStyle
  transform: TextTransform
  textDecoration: TextDecoration
  textColor?: Property.Color
}

type Font = {
  entry: TextEntry
  style: TextStyle
  transform: TextTransform
  textDecoration: TextDecoration
}

export interface FooterThemeInterface {
  customerEngagement: {
    borderRadius?: BorderBox
    topSeparator: Separator
    ctaEmphasis: ButtonEmphases
    cta: Font
    socialIconType: SocialIcon
    headerText: TextStyling
    backgroundColor?: Property.Color
    socialIconsFill: {
      circle: Property.Color
      path: Property.Color
    }
    socialIconsOutline?: {
      color: string
    }
    socialIconsBorder: Border
    socialIconsPadding?: string
    socialIcon: {
      pinterest: {
        svgPath: string
      }
      facebook: {
        svgPath: string
      }
      whatsapp: {
        svgPath: string
      }
      width: string
      height: string
      viewBox: string
    }
    padding?: string
    newsletter: {
      fullWidthMobileButton: boolean
    }
  }
  chevronIcon: {
    fill?: string
  }
  navigation: {
    useBackgroundHover: boolean
    hoverBackgroundColor: Property.Color
    mobileSeparator: Separator
    headerBorder: Separator
    headerText: DarkConfigTextStyling
    linkText: DarkConfigTextStyling
    iconColor?: Property.Color
    backgroundColor?: Property.Color
    countrySelectorColor: Property.Color
    accordionText: TextStyling
    icons: {
      cookie?: IconStyling
      contact?: IconStyling
      delivery?: IconStyling
      help?: IconStyling
      internationalDelivery?: IconStyling
      returns?: IconStyling
      phone?: IconStyling
      locate?: IconStyling
      tick?: IconStyling
      box?: IconStyling
      giftCard?: IconStyling
      rewards?: IconStyling
      faqs?: IconStyling
      recall?: IconStyling
      storeLocator?: IconStyling
    }
  }
  copyrightAndPayments: {
    separator: Separator
    backgroundColor?: Property.Color
    copyrightLogo: CopyrightLogo
    logoFill?: Property.Color
    font: {
      copyright: TextStyling
      payment: TextStyling
    }
    paymentIcons: {
      GIFT_CARD?: IconStyling
      fixedWidth?: boolean
    }
    alternateCopyrightLogo?: {
      width: string
      height: string
      uri: string
    }
    svgContainer: {
      height: string
    }
  }
  footerNotes: {
    font: TextStyling
    separator: Separator
    backgroundColor?: Property.Color
    hoverBackgroundColor: Property.Color
    hoverTextColor: Property.Color
    hoverTextDecoration: TextDecoration
    focusTextColor: Property.Color
    borderFocusWidth: string
    borderFocusColor: Property.Color
  }
}

const separatorStyling: Separator = {
  color: '#CCCCCC',
  width: '1px',
}

const emptySvg = {
  svgPath: '',
  height: '',
  width: '',
  viewBox: '',
  color: '',
}

export const theme: FooterThemeInterface = {
  customerEngagement: {
    topSeparator: {
      color: '#CCCCCC',
      width: '0px',
    },
    ctaEmphasis: 'high',
    cta: {
      entry: 'bodyText',
      style: 'alternate',
      transform: 'none',
      textDecoration: 'none',
    },
    socialIconsFill: {
      circle: '#D8D8D8',
      path: '#333333',
    },
    socialIconsBorder: {
      display: false,
      width: '0px',
      color: '#333333',
    },
    socialIconType: SocialIcon.color,
    socialIcon: {
      pinterest: {
        svgPath: '',
      },
      facebook: {
        svgPath: '',
      },
      whatsapp: {
        svgPath: '',
      },
      width: '40px',
      height: '40px',
      viewBox: '0 0 24 24',
    },
    headerText: {
      entry: 'bodyText',
      style: 'alternate',
      transform: 'none',
      textDecoration: 'none',
      textColor: '',
    },
    backgroundColor: '#FFFFFF',
    newsletter: {
      fullWidthMobileButton: false,
    },
  },
  chevronIcon: {
    fill: '#333333',
  },
  navigation: {
    accordionText: {
      entry: 'bodyText',
      style: 'default',
      transform: 'none',
      textDecoration: 'none',
      textColor: '',
    },
    useBackgroundHover: false,
    hoverBackgroundColor: '#F2F2F2',
    mobileSeparator: separatorStyling,
    headerBorder: separatorStyling,
    headerText: {
      entry: 'small',
      style: 'alternate',
      transform: 'none',
      textDecoration: 'none',
      textColor: '',
    },
    linkText: {
      entry: 'small',
      style: 'default',
      transform: 'none',
      textDecoration: 'none',
      textColor: '',
    },
    iconColor: '',
    backgroundColor: '#FFFFFF',
    countrySelectorColor: '#333333',
    icons: {
      cookie: emptySvg,
      contact: emptySvg,
      delivery: emptySvg,
      help: emptySvg,
      internationalDelivery: emptySvg,
      returns: emptySvg,
      phone: emptySvg,
      locate: emptySvg,
      tick: emptySvg,
      box: emptySvg,
      giftCard: emptySvg,
      rewards: emptySvg,
      faqs: emptySvg,
      recall: emptySvg,
      storeLocator: emptySvg,
    },
  },
  copyrightAndPayments: {
    separator: separatorStyling,
    backgroundColor: '#FFFFFF',
    copyrightLogo: CopyrightLogo.thg,
    logoFill: '#333333',
    font: {
      copyright: {
        entry: 'bodyText',
        style: 'alternate',
        transform: 'none',
        textDecoration: 'none',
        textColor: '#757575',
      },
      payment: {
        entry: 'bodyText',
        style: 'alternate',
        transform: 'none',
        textDecoration: 'none',
        textColor: '#333333',
      },
    },
    paymentIcons: {},
    svgContainer: {
      height: '25px',
    },
  },
  footerNotes: {
    font: {
      entry: 'bodyText',
      style: 'alternate',
      transform: 'none',
      textDecoration: 'none',
      textColor: '#333333',
    },
    separator: separatorStyling,
    backgroundColor: '#FFFFFF',
    hoverBackgroundColor: '#FFFFFF',
    hoverTextColor: '#333333',
    hoverTextDecoration: 'none',
    focusTextColor: '#333333',
    borderFocusWidth: '2px',
    borderFocusColor: '#FFFFFF',
  },
}
