import * as React from 'react'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { useRouter } from 'next/compat/router'

import { ModalProvider } from '@thg-commerce/enterprise-modal'
import { Feature } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Extensions } from '@thg-commerce/enterprise-network/types'
import {
  EnterpriseThemeInterface,
  ThemeProvider,
} from '@thg-commerce/enterprise-theme'
import {
  createSubscribableRef,
  SubscribableRef,
} from '@thg-commerce/enterprise-utils/src/SubscribableRef'
import { PrefetchContextProvider } from '@thg-commerce/gravity-system/prefetch'

import { LiveAnnouncerProvider } from '../Announcer'
import { PageType } from '../App/types'
import {
  Configuration,
  Currency,
  CustomerLoginState,
  RequestConfig,
  ShippingDestination,
  SiteProperties,
} from '../ConfigurationLoader/types'
import { I18nComponent } from '../i18n'
import { Logger as LoggerInterface } from '../Logger/types'
import { ToastBannerProvider } from '../ToastBanner'
import { redirectToRemoveEncodedParams } from '../urlUtilities'

import { EnterpriseContext } from './EnterpriseContext'
import { EnterpriseContextInterface } from './types'

const prefetchCache = new Set<string>()

let supportsPrefetchValue: boolean | undefined
function supportsPrefetch() {
  if (typeof supportsPrefetchValue === 'undefined') {
    supportsPrefetchValue = document
      .createElement('link')
      .relList?.supports?.('prefetch')
  }
  return supportsPrefetchValue
}

const linkPrefetch = (url: string) => {
  const currentDomain = window.location.origin
  const urlDomain = new URL(url).origin
  if (urlDomain !== currentDomain) return

  if (prefetchCache.has(url)) return

  if (supportsPrefetch()) {
    const linkTag = document.createElement('link')
    linkTag.rel = 'prefetch'
    linkTag.href = url
    linkTag.as = 'document'
    linkTag.setAttribute('data-prefetch', 'true')

    document.head.appendChild(linkTag)
    prefetchCache.add(url)
  } else {
    fetch(url, {
      headers: {
        Purpose: 'prefetch',
      },
    })
      .then(() => {
        prefetchCache.add(url)
      })
      .catch(() => {
        // Suppressing fetch errors
      })
  }
  prefetchCache.add(url)
}

export const EnterpriseProvider = (props: {
  logger: LoggerInterface
  theme: EnterpriseThemeInterface
  previewId: string
  brand: string
  subsite: string
  currentLocation: string
  metricNonce: string
  showKeys?: boolean
  children?: React.ReactNode
  extensionsRef?: SubscribableRef<Extensions | undefined>
  config: Omit<Configuration, 'serverRuntimeConfig'>
  requestConfig: RequestConfig
  serviceContextProviders?: React.ComponentType<{ children: React.ReactNode }>[]
  shippingDestination: ShippingDestination
  currency: Currency
  appConfig: Omit<Configuration, 'publicRuntimeConfig' | 'serverRuntimeConfig'>
  horizonFeatures?: Feature[]
  siteProperties: SiteProperties
  pageType?: PageType
  client: ApolloClient<NormalizedCacheObject>
}) => {
  const {
    extensionsRef,
    requestConfig,
    serviceContextProviders = [],
    shippingDestination,
    appConfig,
    siteProperties,
  } = props
  const { siteDefinition } = props.config.publicRuntimeConfig

  const history = React.useRef<string[]>([])
  const router = useRouter()

  const updateHistory = () => {
    if (typeof window === 'undefined') {
      return
    }

    const referrer = history.current[history.current.length - 2] || ''
    if (referrer !== window.location.href) {
      history.current.push(window.location.href)
    }
  }

  const headerHeightRef = createSubscribableRef<number>(0)

  // This is used when extensions isn't defined.
  // Which happens during the Apollo render, but we need this ref to pass the data through and fix types.
  const fallbackExtensionsRef = createSubscribableRef<Extensions | undefined>(
    undefined,
  )

  const contextAppConfig: EnterpriseContextInterface['appConfig'] = {
    ...appConfig,
  }

  const cookieModalContainerRef = React.useRef<HTMLDivElement | null>(null)

  const value: EnterpriseContextInterface = {
    headerHeightRef,
    history,
    updateHistory,
    requestConfig,
    cookieModalContainerRef,
    shippingDestination,
    currency: props.currency,
    metricNonce: props.metricNonce,
    appConfig: contextAppConfig,
    extensionsRef: extensionsRef || fallbackExtensionsRef,
    logger: props.logger,
    config: props.config.publicRuntimeConfig,
    currentLocation: props.currentLocation,
    subsite: props.subsite,
    brand: props.brand,
    horizonFeatures: props.horizonFeatures,
    theme: props.theme,
    route: router?.pathname,
    headerHeight: 0,
    pageType: props.pageType,
  }

  const enableLinkPrefetch =
    props.config.publicRuntimeConfig.ENABLE_LINK_PREFETCH &&
    (props.config.publicRuntimeConfig.siteConfig.enableLinkPrefetch ||
      (props.config.publicRuntimeConfig.siteConfig
        .linkPrefetchPercentageRollout &&
        Math.random() * 100 <
          props.config.publicRuntimeConfig.siteConfig
            .linkPrefetchPercentageRollout))

  const currentURL =
    typeof window !== 'undefined' && new URL(window.location.href)

  currentURL && redirectToRemoveEncodedParams(currentURL.toString())

  return (
    <EnterpriseContext.Provider value={value}>
      <PrefetchContextProvider
        prefetch={(inputUrl) => {
          let url = inputUrl
          if (inputUrl.startsWith('/')) {
            url = siteDefinition.originUrl.concat(inputUrl)
          }
          if (enableLinkPrefetch) {
            linkPrefetch(url)
          }
        }}
      >
        <ThemeProvider theme={props.theme}>
          <ModalProvider>
            <ToastBannerProvider>
              <LiveAnnouncerProvider>
                <I18nComponent
                  showKeys={props.showKeys}
                  siteProperties={siteProperties}
                >
                  {serviceContextProviders.reduce<React.ReactNode>(
                    (
                      accumulator,
                      Provider: React.ComponentType<{
                        children: React.ReactNode
                      }>,
                    ) => {
                      return <Provider>{accumulator}</Provider>
                    },
                    props.children,
                  )}
                </I18nComponent>
              </LiveAnnouncerProvider>
            </ToastBannerProvider>
          </ModalProvider>
        </ThemeProvider>
      </PrefetchContextProvider>
    </EnterpriseContext.Provider>
  )
}
