import { gql } from 'apollo-boost'

export const buildAddToBasketQuery = (
  addToBasketItems: {
    sku: string
    quantity: number
  }[],
) => {
  return gql`query AddToBasket(
      $currency: Currency!
      $shippingDestination: Country!
      $clickAndCollectEnabled: Boolean = false
      $productContentKeys: [String!] = [""]
  ) {
      ${addToBasketItems
        .map(
          ({ sku, quantity }) => `addToBasket_${sku}: addToBasket(
        sku: ${parseInt(sku, 10)}
        quantity: ${quantity}
        currency: $currency
        shippingDestination: $shippingDestination
        clickAndCollectEnabled: $clickAndCollectEnabled
        productContentKeys: $productContentKeys
      ) @client {
          product {
            productCategory
            eligibleForFulfilmentMethods @include(if: $clickAndCollectEnabled)
            sku
            parentSku
            parentTitle
            title
            content(keys: $productContentKeys) {
              key
              value {
                ... on ProductContentStringListValue {
                    stringListValue
                }
                ... on ProductContentStringValue {
                    stringValue
                } 
             }
            }
            productType
            externalIdentifier
            parentExternalIdentifier
            url
            inStock
            variantCount
            price {
              amount
            }
            rrp {
              amount
            }
            brand
            quantity
          }
        }`,
        )
        .join('\n')}
  }`
}
