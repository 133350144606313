import * as React from 'react'
import Media from 'react-media'

import { styled, useTheme } from '@thg-commerce/gravity-patterns/theme'
import { Accordion, isMobileDevice } from '@thg-commerce/gravity-system'
import { AccordionBorderPlacement } from '@thg-commerce/gravity-system/components/Accordion'
import { mq, spacing, Text } from '@thg-commerce/gravity-theme'

import { FooterNavIcon } from './FooterNavIcon'

export interface FooterNavigationLink {
  url: string
  text: string
  openExternally: boolean
}

interface ActionProps {
  href: string
  children: React.ReactElement

  [key: string]: string | React.ReactElement | undefined
  as?: string
}

export interface FooterTopLevelNavigationItem {
  id: string
  subNavigation: FooterSubNavigationItem[]
  actionComponent: React.FunctionComponent<ActionProps>
  userAgent: string
  displayName: string
  type: string
  iconName?: string
  firstFooterNav?: boolean
  link?: FooterNavigationLink
  expandable?: boolean
}

export interface FooterSubNavigationItem {
  displayName: string
  type: string
  link?: FooterNavigationLink
  iconName?: string
}

const LINK_BORDER_WIDTH = 2

const FooterNavigationColumnContainer = styled.div<{
  firstFooterNav?: boolean
}>`
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: 0 ${spacing(2)};
  }
`

const StyledHeader = styled.h2`
  ${(props) =>
    Text(
      props.theme.patterns.footer.navigation.headerText.entry,
      props.theme.patterns.footer.navigation.headerText.style,
    )};
  background: transparent;
  ${(props) =>
    `text-transform: ${props.theme.patterns.footer.navigation.headerText.transform};
  text-decoration: ${props.theme.patterns.footer.navigation.headerText.textDecoration};
  color: ${props.theme.patterns.footer.navigation.headerText.textColor};`}
  border: 2px solid transparent;
  padding: ${spacing(2)} ${spacing(1)} ${spacing(1)} ${spacing(0)};
`

export const NavigationList = styled.ul`
  list-style-type: none;
`

export const LinkWrapper = styled.li<{ hasIcons: boolean }>`
  padding: ${(props) =>
    props.hasIcons
      ? 0
      : `calc(${spacing(1)} - ${LINK_BORDER_WIDTH}px) ${spacing(
          1,
        )} calc(${spacing(1)} - ${LINK_BORDER_WIDTH}px) 0`};
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  margin: 0;

  &:hover {
    ${(props) =>
      props.theme.patterns.footer.navigation.useBackgroundHover &&
      `background-color: ${props.theme.patterns.footer.navigation.hoverBackgroundColor};`}
  }
`

export const SubNavigationLink = styled.a`
  ${(props) =>
    Text(
      props.theme.patterns.footer.navigation.linkText.entry,
      props.theme.patterns.footer.navigation.linkText.style,
    )};
  border: 2px solid transparent;
  margin-left: -2px;

  ${(props) =>
    `
    text-transform: ${
      props.theme.patterns.footer.navigation.linkText.transform
    };
    text-decoration: ${
      props.theme.patterns.footer.navigation.linkText.textDecoration
    };
    color: ${props.theme.patterns.footer.navigation.linkText.textColor};
    outline: none;
    
    &:hover {
      ${
        !props.theme.patterns.footer.navigation.useBackgroundHover &&
        'text-decoration: underline;'
      }
      }
      
      &:active {
        ${Text(
          props.theme.patterns.footer.navigation.linkText.entry,
          'alternate',
        )};
        color: ${props.theme.patterns.footer.navigation.linkText.textColor};}
        `}

  &:focus-visible {
    border: ${LINK_BORDER_WIDTH}px solid
      ${(props) => props.theme.patterns.footer.navigation.linkText.textColor};
  }

  /* DO NOT GROUP WITH ABOVE BREAKS POLYFILL FOR MSOFT BROWSERS */
  /* &.focus-visible {
    border: ${LINK_BORDER_WIDTH}px solid
      ${(props) => props.theme.patterns.footer.navigation.linkText.textColor};
  } */
`

export const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  border: 2px solid transparent;
  margin-left: -${spacing(1)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const getSubnavigationLinks = (
  subNavigation: FooterSubNavigationItem[],
  iconColor?: string,
) =>
  subNavigation &&
  subNavigation.map((el, index) => {
    if (el.link) {
      return (
        <LinkWrapper
          hasIcons={el.iconName !== null}
          key={`subnavigation-link-${index}`}
          data-testid="subnavigation-link"
        >
          {el.iconName && (
            <IconWrapper data-testid="footer-navigation-icon">
              <FooterNavIcon
                iconName={el.iconName}
                fillColor={iconColor}
                focusable={false}
                ariaHidden={true}
              />
            </IconWrapper>
          )}
          <SubNavigationLink
            href={el.link?.url}
            target={el.link?.openExternally ? '_blank' : '_self'}
          >
            {el.displayName}
          </SubNavigationLink>
        </LinkWrapper>
      )
    }
    return null
  })
export const FooterNavigationColumn = (props: FooterTopLevelNavigationItem) => {
  const theme = useTheme()

  if (!props.expandable) {
    return (
      <FooterNavigationColumnContainer
        firstFooterNav={props.firstFooterNav}
        data-testid="footer-nav-column"
      >
        <StyledHeader id={props.id}>{props.displayName}</StyledHeader>
        <NavigationList>
          {getSubnavigationLinks(
            props.subNavigation,
            theme.patterns.footer.navigation.iconColor,
          )}
        </NavigationList>
      </FooterNavigationColumnContainer>
    )
  }

  return (
    <FooterNavigationColumnContainer
      firstFooterNav={props.firstFooterNav}
      data-testid="footer-nav-column"
    >
      <Media
        queries={{
          xs: `${mq(
            theme.breakpointUtils.map,
            'xs',
            true,
          )} and (max-width: calc(${theme.breakpointUtils.map.md}px - 1px))`,
        }}
        defaultMatches={{ xs: isMobileDevice(props.userAgent) }}
        render={() => (
          <Accordion
            title={props.displayName}
            id={props.id}
            headerTextColor={theme.patterns.footer.navigation.iconColor}
            themeOverride={{
              title: {
                textStyling: theme.patterns.footer.navigation.accordionText,
                padding: {
                  top: 1,
                  bottom: 1,
                  left: 1,
                  right: 1,
                },
              },
            }}
            chevronIconColor={theme.patterns.footer.chevronIcon.fill}
            borderPlacement={AccordionBorderPlacement.UNDER}
          >
            <NavigationList>
              {getSubnavigationLinks(
                props.subNavigation,
                theme.patterns.footer.navigation.iconColor,
              )}
            </NavigationList>
          </Accordion>
        )}
      />

      <Media
        queries={{ md: mq(theme.breakpointUtils.map, 'md', true) }}
        defaultMatches={{ md: !isMobileDevice(props.userAgent) }}
        render={() => (
          <React.Fragment>
            <StyledHeader id={props.id}>{props.displayName}</StyledHeader>
            <NavigationList>
              {getSubnavigationLinks(
                props.subNavigation,
                theme.patterns.footer.navigation.iconColor,
              )}
            </NavigationList>
          </React.Fragment>
        )}
      />
    </FooterNavigationColumnContainer>
  )
}
