import { Response } from 'express'
import { isUri, isWebUri } from 'valid-url'
import xss from 'xss'
import xssFilters from 'xss-filters'

import { defaults as siteProperties } from '@thg-commerce/enterprise-network/src/ApolloProvider/data/SiteProperties'
import { FacetInput } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  sanitizeParameter,
  sanitizeParameters,
} from '@thg-commerce/enterprise-utils'

import { safeDecodeURI } from '../Server/Middleware/sanitise-input'
import { EnterpriseRequest } from '../Server/types'

import {
  hasSessionSettingParameters,
  removeSessionSettingParamsFromReturnTo,
} from './sessionSettings'

const IFP_FILTERS = [
  'en_brand_content',
  'en_hbg_sizeFacet_content',
  'en_hbg_baseColour_content',
  'en_hbg_type_content',
]

const constructParamObjectFromReq = (req: EnterpriseRequest, parameter) => {
  if (req === null || typeof req === 'undefined') {
    return {}
  }
  if (typeof parameter === 'string') {
    if (parameter === '*') {
      return req.query
    }
    if (req.query[parameter] as string) {
      return {
        [parameter]: req.query[parameter],
      }
    }
    return {}
  }
  return parameter.reduce((parameterObject, currentParam) => {
    if (req.query[currentParam] as string) {
      parameterObject[currentParam] = req.query[currentParam] as string
    }
    return parameterObject
  }, {})
}

const constructParamObjectFromWindow = ($window: Window, parameter) => {
  if ($window === null || typeof $window === 'undefined') {
    console.warn(
      'window not defined in constructParamObjectFromWindow from urlUtilities',
    )
    return {}
  }
  const search = new URLSearchParams($window.location.search)
  if (typeof parameter === 'string') {
    if (parameter === '*') {
      return Array.from((search as any).entries()).reduce(
        (accumulatedSearchParams: object, entry: any) => {
          accumulatedSearchParams[entry[0]] = entry[1]
          return accumulatedSearchParams
        },
        {},
      )
    }
    if (search.get(parameter)) {
      return {
        [parameter]: search.getAll(parameter)[
          search.getAll(parameter).length - 1
        ],
      }
    }
    return {}
  }
  return parameter.reduce((parameterObject, currentParam) => {
    if (search.get(currentParam)) {
      parameterObject[currentParam] = search.getAll(currentParam)[
        search.getAll(currentParam).length - 1
      ]
    }
    return parameterObject
  }, {})
}

const constructParamObjectFromUrl = (urlString: string, parameter) => {
  if (!isUri(urlString)) {
    return {}
  }

  const url = new URL(urlString)
  const search = new URLSearchParams(url.search)

  if (typeof parameter === 'string') {
    if (parameter === '*') {
      return Array.from((search as any).entries()).reduce(
        (accumulatedSearchParams: object, entry: any) => {
          accumulatedSearchParams[entry[0]] = entry[1]
          return accumulatedSearchParams
        },
        {},
      )
    }
    if (search.get(parameter)) {
      return {
        [parameter]: search.getAll(parameter)[
          search.getAll(parameter).length - 1
        ],
      }
    }
    return {}
  }
  return parameter.reduce((parameterObject, currentParam) => {
    if (search.get(currentParam)) {
      parameterObject[currentParam] = search.getAll(currentParam)[
        search.getAll(currentParam).length - 1
      ]
    }
    return parameterObject
  }, {})
}

const constructParamObjectAsPath = (asPath: string, parameter) => {
  const pathComponents = asPath.split('?')
  if (pathComponents.length === 0) return {}

  const search = new URLSearchParams(pathComponents[1])

  if (typeof parameter === 'string') {
    if (parameter === '*') {
      return Array.from((search as any).entries()).reduce(
        (accumulatedSearchParams: object, entry: any) => {
          accumulatedSearchParams[entry[0]] = entry[1]
          return accumulatedSearchParams
        },
        {},
      )
    }
    if (search.get(parameter)) {
      return {
        [parameter]: search.getAll(parameter)[
          search.getAll(parameter).length - 1
        ],
      }
    }
    return {}
  }

  return parameter.reduce((parameterObject, currentParam) => {
    if (search.get(currentParam)) {
      parameterObject[currentParam] = search.getAll(currentParam)[
        search.getAll(currentParam).length - 1
      ]
    }
    return parameterObject
  }, {})
}

const queryParamTypeMapping = {
  error: 'boolean',
  affil: 'string',
  containsSubscriptionProduct: 'boolean',
  passwordReset: 'string', // (Looks Empty)
  accountLocked: 'boolean',
  accountServiceError: 'boolean',
  countrySelected: 'string', // "Y/N"
  csrfToken: 'number',
  disabledButtonFeedback: 'Empty', // e.g. &disabledButtonFeedback
  emailVerificationSuccess: 'boolean',
  from: 'string', // e.g. from=voteReviewPage
  fromEmail: 'boolean',
  hasReferrerCode: 'boolean',
  invalidUsername: 'string', // "yes"
  linkingAccounts: 'boolean',
  referringSite: 'string',
  feature: 'string',
  settingsSaved: 'string', // "Y/N"
  shippingcountry: 'string',
  switchcurrency: 'string',
  utm_campaign: 'string',
  utm_medium: 'string',
  utm_source: 'string',
  utm_content: 'string',
  utm_term: 'string',
  skeletonAccount: 'boolean',
  socialLoginError: 'string', // "declined/unknown"
  ecrmcid: 'string',
  shae: 'string',
  sendTime: 'number',
  returnTo: 'uri',
  paymentOption: 'string',
  subPaymentOptions: 'string',
  newsletterStatus: 'boolean',
  orderNumber: 'string',
  shipmentNumber: 'string',
  pageNumber: 'number',
  pageNumberPrevious: 'number',
  messageId: 'string',
  messageKey: 'string',
  messageType: 'string',
  sku: 'number',
  productId: 'number',
  path: 'object',
  subscriptionId: 'string',
  subscription: 'string',
  search: 'string',
  checkoutError: 'string',
  message: 'number',
  platformActionType: 'string',
  reviewAction: 'string', // "VotePositive, VoteNegative, Report"
  reviewId: 'number',
  facetFilters: 'string',
  sortOrder: 'string',
  addSKUToWishlist: 'number',
  redirect_behaviour: 'string',
  sort: 'string',
  sortType: 'string',
  address: 'string',
  editedAddress: 'boolean',
  newAddress: 'boolean',
  profileId: 'string',
  action: 'string',
  reset_request: 'string',
  wechatsetpassword: 'string',
  token: 'string',
  order: 'string',
  uuid: 'string',
  email: 'string',
  variation: 'number',
  rewrite_url: 'string',
  force: 'string',
  showAccountLoyaltyTabs: 'boolean',
  tab: 'number',
  manualRecommendationType: 'string',
  awc: 'string',
  reviewFilters: 'string',
  qb_opts: 'string',
  qb_placement_id: 'string',
  qb_mode: 'string',
  qb_campaign_id: 'string',
  qb_experience_id: 'string',
  qb_group: 'string',
  sign: 'string',
  pageVisitEventType: 'string',
  chumewe_user: 'string',
  chumewe_sess: 'string',
  location: 'string',
  customer_location: 'string',
  pr_page_id: 'number',
}

const removeIncorrectTypedParameters = (parameterKeyValueObject) => {
  return Object.keys(parameterKeyValueObject).reduce(
    (correctParams, currentParamKey) => {
      if (currentParamKey in queryParamTypeMapping) {
        switch (queryParamTypeMapping[currentParamKey]) {
          case 'number':
            if (!isNaN(parameterKeyValueObject[currentParamKey])) {
              correctParams[currentParamKey] = Number(
                parameterKeyValueObject[currentParamKey],
              )
            }
            break
          case 'boolean':
            if (
              parameterKeyValueObject[currentParamKey] === 'true' ||
              parameterKeyValueObject[currentParamKey] === 'false'
            ) {
              correctParams[currentParamKey] =
                parameterKeyValueObject[currentParamKey] === 'true'
            }
            break
          case 'uri':
            const decodedURI = decodeURIComponent(
              parameterKeyValueObject[currentParamKey],
            )
            if (isUri(decodedURI)) {
              correctParams[currentParamKey] =
                parameterKeyValueObject[currentParamKey]
            }
            break
          default:
            correctParams[currentParamKey] =
              parameterKeyValueObject[currentParamKey]
            break
        }
      }
      return correctParams
    },
    {},
  )
}

const removeUnsafeKnownParameters = <ParamsType = {}>(
  parameters: ParamsType,
): Partial<ParamsType> => {
  if (Object.keys(parameters).includes('messageKey')) {
    if (
      Object.keys(siteProperties).filter((key) => {
        return parameters['messageKey'] === key
      }).length === 0
    ) {
      delete parameters['messageKey']
    }
  }

  const safeUrlParams = sanitizeParameters(parameters)

  if (Object.keys(safeUrlParams).includes('returnTo')) {
    const returnTo = decodeURIComponent(safeUrlParams['returnTo']).replace(
      /&amp;/g,
      '&',
    )

    if (!isUri(safeUrlParams['returnTo'])) delete safeUrlParams['returnTo']

    const encodedReturnTo = encodeURIComponent(returnTo)

    safeUrlParams['returnTo'] = encodedReturnTo
  }

  if (
    Object.keys(safeUrlParams).includes('path') &&
    Array.isArray(parameters['path'])
  ) {
    safeUrlParams['path'] = sanitizeParameterArray(parameters['path'])
  }

  return safeUrlParams
}

const sanitizeParameterArray = (paramArray: string[]) => {
  return paramArray.map((param) => sanitizeParameter(param))
}

export const getSafeUrlParametersFromAsPath = (asPath: string, parameters) => {
  const urlParamObject = constructParamObjectAsPath(asPath, parameters)
  const correctlyTypedParamObject = removeIncorrectTypedParameters(
    urlParamObject,
  )

  return removeUnsafeKnownParameters(correctlyTypedParamObject)
}

export const getSafeUrlParametersFromWindow = <ParamsType = {}>(
  $window,
  parameters,
): Partial<ParamsType> => {
  const urlParamObject = constructParamObjectFromWindow($window, parameters)
  const correctlyTypedParamObject = removeIncorrectTypedParameters(
    urlParamObject,
  )

  return removeUnsafeKnownParameters(correctlyTypedParamObject)
}

export const getSafeUrlParametersFromUrlString = (
  urlString: string,
  parameters,
) => {
  const urlParamObject = constructParamObjectFromUrl(urlString, parameters)
  const correctlyTypedParamObject = removeIncorrectTypedParameters(
    urlParamObject,
  )

  return removeUnsafeKnownParameters(correctlyTypedParamObject)
}

export const getSafeUrlParametersFromReq = <ParamsType = {}>(
  req,
  parameters,
): Partial<ParamsType> => {
  const urlParamObject = constructParamObjectFromReq(req, parameters)
  const correctlyTypedParamObject = removeIncorrectTypedParameters(
    urlParamObject,
  )

  return removeUnsafeKnownParameters(correctlyTypedParamObject)
}

export const pageTypeFromUrl = (url: string) => {
  if (url.indexOf('.html') > 0 || url.includes('/product/')) {
    return 'product'
  }

  if (url.indexOf('.list') > 0) {
    return 'list'
  }

  if (
    url.indexOf('.reviews') > 0 ||
    (url.includes('/product/') && url.includes('/reviews'))
  ) {
    return 'reviews'
  }

  if (url.indexOf('.account') > 0) {
    return 'default'
  }

  if (url === '/' || url === '/home') {
    return 'home'
  }

  return ''
}

export const isSafeToRedirect = (returnToUrl: string, originUrl: string) => {
  const decodedReturnTo = decodeURIComponent(returnToUrl)
  const uri = decodeURIComponent(decodedReturnTo)
  if (!isWebUri(decodeURI(uri))) return false

  return new URL(uri).hostname === new URL(originUrl).hostname
}

export const safeRedirectUsingWindowForReturnToUrl = (
  originUrl: string,
  $window: Window,
  returnTo?: string,
  fallback?: string,
) => {
  const returnToUrl =
    returnTo && isSafeToRedirect(decodeURIComponent(returnTo), originUrl)
      ? decodeURIComponent(returnTo)
      : fallback
      ? `${originUrl}${fallback}`
      : originUrl

  $window.location.replace(returnToUrl)
}

export const safeRedirectUsingServerResponseForReturnToUrl = (
  req: EnterpriseRequest,
  res: Response,
  originUrl: string,
  returnTo?: string,
  fallback?: string,
) => {
  const returnToUrl =
    returnTo && isSafeToRedirect(returnTo, originUrl)
      ? decodeURIComponent(returnTo)
      : fallback
      ? `${originUrl}${fallback}`
      : originUrl

  if (hasSessionSettingParameters(req)) {
    return res.redirect(
      302,
      removeSessionSettingParamsFromReturnTo(returnToUrl).toString(),
    )
  }

  return res.redirect(302, decodeURIComponent(returnToUrl))
}

export const sanitizeUrlPath = (asPath: string) => {
  const browserUrlParams = getSafeUrlParametersFromAsPath(asPath, '*')

  const parsedURL = new URL(asPath, 'http://localhost')
  parsedURL.search = new URLSearchParams(browserUrlParams).toString()

  try {
    return xss(
      safeDecodeURI(
        xssFilters.inHTMLData(parsedURL.pathname + parsedURL.search),
      ),
      {
        whiteList: {},
        stripIgnoreTag: true,
        stripIgnoreTagBody: ['script'],
      },
    )
  } catch (_) {
    try {
      return safeDecodeURI(safeDecodeURI(parsedURL.pathname + parsedURL.search))
    } catch (e: any) {
      console.error(
        `[sanitizeBrowserUrl] error - failed to decodeURI: ${
          parsedURL.pathname + parsedURL.search
        }`,
        e?.message || '',
      )
      return parsedURL.pathname
    }
  }
}

export const getPageMessageValues = (req) => {
  const pageMessage: {
    messageKey?: string
    messageType?: string
    messageId?: string
  } = {}

  if (req) {
    const urlParams = getSafeUrlParametersFromReq<{
      messageKey: string
      messageType: string
      messageId: string
    }>(req, '*')
    urlParams.messageKey && (pageMessage.messageKey = urlParams.messageKey)
    urlParams.messageType && (pageMessage.messageType = urlParams.messageType)
    urlParams.messageId && (pageMessage.messageId = urlParams.messageId)
  }

  return {
    ...pageMessage,
  }
}

export const getIndexableFacetValue = (facetFilters: FacetInput[]) => {
  return (
    !!facetFilters &&
    facetFilters.length > 0 &&
    facetFilters.every(
      (param) =>
        IFP_FILTERS.includes(param.facetName) && param.selections.length === 1,
    )
  )
}

export const getIndexableFacetCanonicalUrl = (
  originUrl: string,
  pageParams?: { facetFilters?: FacetInput[]; pageNumber?: number },
) => {
  if (!originUrl || !pageParams?.facetFilters?.length) {
    return originUrl || ''
  }

  const indexableFacetValue = getIndexableFacetValue(pageParams.facetFilters)

  const urlParameters =
    (indexableFacetValue &&
      pageParams.facetFilters &&
      IFP_FILTERS.reduce<string[]>((accumulator, filter) => {
        pageParams?.facetFilters?.forEach((facet) => {
          if (filter === facet.facetName && facet.selections.length === 1) {
            accumulator.push(
              `${facet.facetName}:${facet.selections[0].optionName}`,
            )
          }
        })
        return accumulator
      }, [])
        .join('|')
        .concat('&IFP=true')) ||
    ''

  return `${originUrl}${urlParameters && `?facetFilters=${urlParameters}`}${
    pageParams?.pageNumber && pageParams?.pageNumber !== 1 && urlParameters
      ? `&pageNumber=${pageParams?.pageNumber}`
      : ''
  }`
}
