import * as React from 'react'

declare global {
  interface Window {
    unlockABTasty?: () => void
    lockABTastyTag?: boolean
  }
}

/**
 * ABTastyUnlocker - Component that unlocks ABTasty modifications after the page is fully loaded and hydrated
 *
 * It calls window.unlockABTasty() after the component mounts, which indicates that
 * the page is fully loaded and hydrated.
 */
export const ABTastyUnlocker = () => {
  React.useEffect(() => {
    // Ensure this only runs on the client side
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        if (typeof window.unlockABTasty === 'function') {
          window.unlockABTasty()
        } else {
          console.warn('ABTasty unlock function not found')
        }
      }, 100)
    }
  }, [])

  return null
}
