import React from 'react'

import { AmpLayoutType } from '@thg-commerce/gravity-amp'
import {
  css,
  mq,
  padding,
  styled,
  Text,
  TextEntry,
  TextStyle,
} from '@thg-commerce/gravity-theme'
import {
  BreakpointArray,
  BreakpointKey,
  breakpointUtils,
  mediaQueryRenderer,
} from '@thg-commerce/gravity-theme/breakpoints'
import { Padding } from '@thg-commerce/gravity-theme/padding'

export interface TagStyle {
  container: {
    padding: Padding
    width: BreakpointArray<string>
  }
  textStyling: {
    entry: TextEntry
    style: TextStyle
  }
}

type SourceSizeUnit = 'w' | 'px'
type DisplayStyle = 'square' | 'rounded'
type ImageStyle = 'cover' | 'contain'

export type ResponsiveDimensions = number | string | (string | number)[]

interface Source {
  url: string
  width?: number
  densityScale?: number
  breakpoint?: BreakpointKey
  imageSize?: number
}

export type FetchPriority = 'high' | 'low' | 'auto'

export interface ImageProps {
  src: Source[]
  style?: React.CSSProperties
  alt: string
  width: ResponsiveDimensions
  height?: ResponsiveDimensions
  maxHeight?: ResponsiveDimensions
  lazy?: boolean
  isAmp?: boolean
  ampLayout?: AmpLayoutType
  className?: string
  displayStyle?: DisplayStyle
  imageStyle?: ImageStyle
  onClick?: () => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  title?: string
  testId?: string
  wrapperTestId?: string
  __dangerouslyDisableImageStyleIE11Fix?: boolean
  crossOrigin?: 'anonymous' | 'use-credentials'
  tabIndex?: number
  aspectRatio?: string
  fetchPriority?: FetchPriority
  tagStyle?: TagStyle
  tag?: string
  imageClassName: string
}

const breakpointKeys = Object.keys(breakpointUtils.map)

const mapBreakpoint = (breakpoint: BreakpointKey) =>
  breakpointUtils.map[breakpointKeys[breakpointKeys.indexOf(breakpoint) + 1]]

const sourceWidth = (source: Source) => {
  let { width } = source
  const { breakpoint } = source
  if (!width && breakpoint) {
    const mappedBreakpoint = mapBreakpoint(breakpoint)
    width = mappedBreakpoint ?? Number.MAX_SAFE_INTEGER
  }
  return width ?? 0
}

const sortSources = (a: Source, b: Source) => {
  const aDensityScale = a.densityScale ?? null
  const bDensityScale = b.densityScale ?? null
  if (aDensityScale && bDensityScale) {
    return bDensityScale - aDensityScale
  }

  const aWidth = sourceWidth(a)
  const bWidth = sourceWidth(b)
  return aWidth - bWidth
}

const sourceSize = (
  { densityScale, width, breakpoint }: Source,
  widthUnit: SourceSizeUnit = 'w',
): string | null =>
  densityScale
    ? `${densityScale}x`
    : width
    ? `${width}${widthUnit}`
    : breakpoint
    ? mapBreakpoint(breakpoint)
      ? `${mapBreakpoint(breakpoint)}${widthUnit}`
      : null
    : null

const sourceWidthMediaQuery = (source: Source) =>
  source.imageSize
    ? `(max-width: ${source.imageSize}px)`
    : source.breakpoint && mapBreakpoint(source.breakpoint)
    ? `(max-width: ${mapBreakpoint(source.breakpoint)}px)`
    : null

const buildSourceSetArray = (sources: Source[]) => {
  if (sources.length <= 1) {
    return undefined
  }
  return sources.map((source) => buildSourceSet(source)).join(', ')
}

const buildSizesArray = (sources: Source[]) => {
  if (sources.length <= 1) {
    return undefined
  }
  const mappedSizes = sources.reduce((filtered, source, index) => {
    if (source.breakpoint || source.imageSize) {
      const size = buildSourceSize(source, index === sources.length - 1, 'px')
      if (size) {
        filtered.push(size)
      }
    }
    return filtered
  }, [] as string[])
  return mappedSizes.length > 0 ? mappedSizes.join(', ') : undefined
}

const buildSourceSet = (source: Source) => {
  const size = sourceSize(source)
  return `${source.url}${size ? ` ${size}` : ''}`
}

const buildSourceSize = (
  source: Source,
  last: boolean,
  sizeUnit: SourceSizeUnit = 'w',
) => {
  const mediaQuery = sourceWidthMediaQuery(source)
  const size = sourceSize(source, sizeUnit)
  if (!size) {
    return null
  }
  return `${!last && mediaQuery ? `${mediaQuery} ` : ''}${size}`
}

const buildImgProps = (
  props: ImageProps,
  sources: Source[],
  { url }: Source,
) => {
  const imageWidth = Array.isArray(props.width) ? props.width[0] : props.width

  return {
    className: `${props.className ? `${props.className} ` : ''}${
      props.imageClassName || ''
    }`,
    imageStyle: props.imageStyle,
    style: props.style,
    alt: props.alt,
    width: `${imageWidth}${typeof imageWidth === 'number' ? 'px' : ''}`,
    height: '100%',
    title: props.title,
    onClick: props.onClick,
    displayStyle: props.displayStyle,
    crossOrigin: props.crossOrigin,
    loading: props.lazy ? 'lazy' : 'eager',
    dimensions: {
      width: props.width,
      height: props.height,
      maxHeight: props.maxHeight,
    },
    aspectRatio: props.aspectRatio,
    src: url,
    srcSet: buildSourceSetArray(sources),
    sizes: buildSizesArray(sources),
  }
}

interface StyledImageProperties {
  displayStyle?: DisplayStyle
  imageStyle?: ImageStyle
  __dangerouslyDisableImageStyleIE11Fix?: boolean
  dimensions: {
    width: ResponsiveDimensions
    height?: ResponsiveDimensions
    maxHeight?: ResponsiveDimensions
  }
  aspectRatio?: string
  loading?: 'lazy' | 'eager'
  fetchpriority?: FetchPriority
}

const size = (
  width: ResponsiveDimensions,
  height?: ResponsiveDimensions,
  maxHeight?: ResponsiveDimensions,
  handleHeightWithPadding?: boolean, // this is a hack to display gray background when image is not loaded
) => {
  const preparedWidth = Array.isArray(width) ? width : [width]
  const preparedHeight = Array.isArray(height) ? height : [height]
  return css`
    ${maxHeight && `max-height: ${maxHeight};`}
    ${(props) =>
      props.theme.breakpointUtils.keys.map((key, index) => {
        if (preparedWidth[index] || preparedHeight[index]) {
          return `
            ${mq(props.theme.breakpointUtils.map, key as BreakpointKey)} {
              ${
                preparedWidth[index]
                  ? `width: ${preparedWidth[index]}${
                      typeof preparedWidth[index] === 'number' ? 'px' : ''
                    };`
                  : ''
              }

              ${
                preparedHeight[index]
                  ? `
                ${
                  preparedHeight[index] === 'auto' && handleHeightWithPadding
                    ? `
                      height: 0;
                      padding-bottom: 100%;
                    `
                    : `height: ${preparedHeight[index]}${
                        typeof preparedHeight[index] === 'number' ? 'px' : ''
                      };`
                }
                `
                  : ''
              }
          }`
        }
        return ''
      })}
  `
}

const StyledImage = styled('img').withConfig<StyledImageProperties>({
  shouldForwardProp: (prop, defaultValidate) =>
    prop === 'fetchpriority' || defaultValidate(prop),
})`
  vertical-align: top;

  ${({ displayStyle }) =>
    displayStyle === 'rounded' &&
    `
    border-radius: 50%;
  `}

  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio}`}}

  ${({ dimensions }) =>
    size(dimensions.width, dimensions.height, dimensions.maxHeight)}

  ${({ imageStyle, __dangerouslyDisableImageStyleIE11Fix }) =>
    imageStyle &&
    css`
      object-fit: ${imageStyle};

      ${!__dangerouslyDisableImageStyleIE11Fix &&
      css`
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: auto;
          height: auto;
          position: absolute;
          right: 50%;
          transform: translate(50%, 0);

          ${imageStyle === 'cover'
            ? `
            min-width: 100%;
            min-height: 100%;
          `
            : `
            max-width: 100%;
            max-height: 100%;
          `}
        }
      `}
    `}
`

interface ImageContainerProperties {
  displayStyle?: DisplayStyle
  dimensions: {
    width: ResponsiveDimensions
    height?: ResponsiveDimensions
    maxHeight?: ResponsiveDimensions
  }
  aspectRatio?: string
  displayBackgroundColour?: boolean
}

const ImageContainer = styled.div<ImageContainerProperties>`
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;

  ${(props) =>
    size(
      props.dimensions.width,
      props.dimensions.height,
      props.dimensions.maxHeight,
    )}

  ${(props) => props.aspectRatio && `aspect-ratio: ${props.aspectRatio};`}
  ${(props) =>
    props.displayBackgroundColour &&
    `background-color: ${props.theme.colors.palette.greys.lighter};`}
`

const StyledTag = styled.div<{
  tagStyle?: TagStyle
}>`
  position: absolute;
  bottom: 0;
  background: ${(props) => props.theme.colors.palette.greys.lighter};
  opacity: 90%;
  ${(props) =>
    props.tagStyle?.container
      ? padding(props.tagStyle.container.padding)
      : padding({ top: 0.5, right: 0.5, bottom: 0.5, left: 0.5 })};
  ${(props) =>
    props.tagStyle?.textStyling
      ? Text(props.tagStyle.textStyling.entry, props.tagStyle.textStyling.style)
      : Text('xsmall', 'alternate')}
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  ${(props) =>
    props.tagStyle?.container?.width &&
    mediaQueryRenderer(
      props.tagStyle.container.width,
      (width) => `width: ${width};`,
    )}
`

export const Image = (props: ImageProps) => {
  const { src } = props

  if (!src || (src.length || 0) === 0) {
    return (
      <ImageContainer
        displayStyle={props.displayStyle}
        onClick={props.onClick}
        onKeyDown={props.onKeyDown}
        className={props.className}
        dimensions={{
          width: props.width,
          height: props.height,
          maxHeight: props.maxHeight,
        }}
        aspectRatio={props.aspectRatio}
        displayBackgroundColour={true}
      />
    )
  }

  const orderedSources = src.sort(sortSources)
  const smallestSource = orderedSources[0]

  return (
    <ImageContainer
      displayStyle={props.displayStyle}
      dimensions={{
        width: props.width,
        height: props.height,
        maxHeight: props.maxHeight,
      }}
      onKeyDown={props.onKeyDown}
      tabIndex={props.tabIndex}
      data-testid={props.wrapperTestId || 'image-container'}
      aspectRatio={props.aspectRatio}
      className={props.className}
    >
      <StyledImage
        data-testid={props.testId || 'image'}
        {...buildImgProps(props, orderedSources, smallestSource)}
        imageStyle={props.imageStyle || 'cover'}
        aspectRatio={props.aspectRatio}
        // @ts-ignore
        fetchpriority={props.fetchPriority || 'auto'}
      />
      {props.tag && (
        <StyledTag tagStyle={props.tagStyle}>{props.tag}</StyledTag>
      )}
    </ImageContainer>
  )
}
