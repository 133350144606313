/* eslint-disable complexity */
import * as React from 'react'
import withHydrationOnDemand from 'react-hydration-on-demand'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'

import { createStore, FetchMode } from '@thg-commerce/enterprise-cache'
import {
  Header,
  InstantSearchProps,
  SearchI18nText,
} from '@thg-commerce/enterprise-components/Header'
import { FlyoutMenuI18nText } from '@thg-commerce/enterprise-components/Header/FlyoutMenu/FlyoutMenu'
import {
  HeaderI18nText,
  HeaderProps,
} from '@thg-commerce/enterprise-components/Header/Header'
import { NavigationEventData } from '@thg-commerce/enterprise-components/Header/Navigation/Navigation'
import { Brand } from '@thg-commerce/enterprise-components/Header/Navigation/NavigationContent/Brands/Brands'
import { NavigationTree } from '@thg-commerce/enterprise-components/Header/NavigationTree'
import { SubmenuI18nText } from '@thg-commerce/enterprise-components/Header/Submenu/Submenu'
import { PromotionalProducts } from '@thg-commerce/enterprise-components/Header/types'
import { NewHeader } from '@thg-commerce/enterprise-components/NewHeader'
import { HeaderSlotType } from '@thg-commerce/enterprise-config'
import { PageType } from '@thg-commerce/enterprise-core/src/App/types'
import { ESIComponent, withESIWrapper } from '@thg-commerce/enterprise-esi'
import { ESIRequestContext } from '@thg-commerce/enterprise-esi/src/types'
import { getColumboEventData } from '@thg-commerce/enterprise-metrics'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import {
  Feature,
  GlobalStripBanner,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  sanitizeCookie,
  sanitizeParameter,
} from '@thg-commerce/enterprise-utils'
import { AriaMessageType } from '@thg-commerce/gravity-elements'

import {
  LIST_EXTENSION,
  MessageArea,
  Routes,
  useEnterpriseContext,
  useExperiments,
  useExtensions,
  useHorizonFeature,
  useI18n,
  useRequestConfig,
  useSiteConfig,
  useSiteDefinition,
  useTransmit,
} from '../../index'
import pkg from '../../package.json'
import { AccountNav as AccountNavComponent } from '../AccountNav'
import { withCacheConfiguration } from '../cache'
import { loadConfiguration } from '../ConfigurationLoader/ConfigurationLoader'
import { NextConfig } from '../ConfigurationLoader/types'
import {
  removeSelectedTopLevelNavigationItem,
  SELECTED_TOP_LEVEL_NAVIGATION_ITEM_COOKIE_NAME,
  selectTopLevelNavigationItem,
} from '../Navigation'
import { EnterpriseRequest } from '../Server/types'
import { SessionSettingsToast } from '../ToastBanner'
import { WishlistNotification } from '../WishlistNotification'

import { Header as HEADER_QUERY } from './Header.graphql'
import { SessionSettingsModalFactory } from './SessionSettingsModal'
import { globalGA4 } from './utils'
import { WidgetBannerProps } from './WidgetBanner'
import { withBasket } from './WithBasket'

export interface HeaderGroupProps {
  header: NavigationTree & {
    eventSchema?: {
      [key: string]: {
        [key: string]: {
          propertyKey: string
          label: string
        }
      }
    }
    widgets: GlobalStripBanner[]
    searchBarConfiguration: {
      promotionalProducts?: PromotionalProducts[]
      trendingSearches?: {
        content: {
          terms: string[]
          header: string
          index: number
        }
      }[]
    }
    homepage?: {
      path?: string
    }
  }
  brands: Brand[]
  headerHeight: number
}

export interface HeaderGroupInitialProps {
  wishlistNotification: boolean
  stickyHeader: boolean
  showStripBanner: boolean
  flagPath: string
  userAgent: string
  isMobile: boolean
  pageType?: PageType
  children?: JSX.Element | JSX.Element[]
}

const WidgetBannerComponent = dynamic<WidgetBannerProps>(
  () => import('./WidgetBanner').then((mod) => mod.WidgetBanner),
  { ssr: true },
)

const AccountNav = withHydrationOnDemand({
  on: [
    [
      'hover',
      () => document.querySelector("[data-testid='account-trigger-wrapper']"),
    ],
  ],
})(AccountNavComponent)

const InstantSearchInjector = dynamic<{
  children: (props: InstantSearchProps) => React.ReactNode
}>(() =>
  import('./InstantSearchInjector').then((mod) => mod.InstantSearchInjector),
)

const SkipLinkComponent = dynamic<{}>(
  () => import('../SkipLink').then((mod) => mod.SkipLink),
  { ssr: true },
)

const SkipLink = withHydrationOnDemand({
  on: [['delay']],
})(SkipLinkComponent)

const WidgetBanner = withHydrationOnDemand({
  on: [['delay', 500], 'idle'],
})(WidgetBannerComponent)

const HeaderGroup: ESIComponent<
  HeaderGroupProps & HeaderGroupInitialProps,
  HeaderGroupInitialProps
> = (props) => {
  const transmit = useTransmit()
  const headerRef = React.useRef<HTMLElement | null>(null)
  const {
    defaultLocale,
    siteName,
    currencies,
    subsite,
    siteId,
    channel,
  } = useSiteDefinition()

  const {
    hasDynamicHeaderDropdown,
    countryDomainMap,
    hasSessionSettings,
    showResponsiveSubMenuFirstLink,
    showResponsiveSubMenuSecondLink,
    showResponsiveSubMenuThirdLink,
    showResponsiveSubMenuFourthLink,
    headerSlots,
    submenuSlots,
    hasWaterfallMenu,
    responsiveFlyoutMenu_hasStoreIcon,
    flyoutMenuShowWishlistIcon,
    secondHeaderSlotUrl,
    newHeader,
    newHeaderUseAlternateGrid,
    showExposedSearchBarOnMobile,
    enableThreeTierNav,
    enableAlternateNavGrid,
    headerPromotionUrl,
    headerPromotionUrlTwo,
    headerPromotionUrls,
    navSubLevelPromotionList,
    navLevelThreePromotionList,
    showResponsiveSubMenuStoreLocator,
    enableStoreLocatorIconOnMobileHeader,
    clearSelectedTopLevelNavigationItemOnLogoClick,
    primaryNavImages,
    showPowerReview,
    enableVipPrice,
    enablePromotionalSearch,
    enableTrendingSearch,
    enableRecentSearches,
    enablePersistentSearch,
    hideThreeTierTab,
    hideFocusOnLevelOneNav,
    enableMasonryGridMenu,
  } = useSiteConfig()

  const EnterpriseCtx = useEnterpriseContext()
  const experiments = useExperiments()

  const vipPriceEnabled =
    (useHorizonFeature(Feature.VipPricingEnabled) && enableVipPrice) || false
  const requestConfig = useRequestConfig()
  const extensions = useExtensions()

  const propertyArgs = React.useMemo(() => {
    return {
      defaultLocale,
      subsite,
      siteId,
      channel,
      customerLocation: EnterpriseCtx.currentLocation,
    }
  }, [EnterpriseCtx.currentLocation, channel, defaultLocale, siteId, subsite])

  const renderPromotionalSearch =
    enablePromotionalSearch ||
    experiments['web_search_bar_wave_01'] === 'v1|show_promotions'

  const renderTrendingSearches =
    enableTrendingSearch ||
    experiments['web_search_bar_wave_01'] === 'v2|show_trending_searches'

  React.useEffect(() => {
    function cb(e: MouseEvent) {
      const target = (e.target as HTMLElement).closest(
        'header a',
      ) as HTMLAnchorElement | null

      if (target) {
        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: `Clicked ${target.innerText}`,
            eventCategory: 'Navigation Header',
            eventLabel: target.href,
          },
        })
      }
    }
    const currentHeaderRef = headerRef.current

    currentHeaderRef?.addEventListener('click', cb)
    return () => currentHeaderRef?.removeEventListener('click', cb)
  })

  React.useEffect(() => {
    if (extensions?.experiments?.ely_2_header === 'v1|header_event') {
      transmit({
        type: 'columbo',
        payload: getColumboEventData({
          propertyArgs,
          argumentsObj: {
            '0': 'Header',
            '1': 'Shown',
          },
          requestArgs: {
            client_timestamp: Math.round(Date.now() / 1000),
            url: window.location.href,
          },
          eventData: {
            type: 'focus',
            subtype: 'header',
            contents: [{ html_element: 'header' }],
          },
          nonce: EnterpriseCtx.metricNonce,
        }),
      })
    }
  }, [
    EnterpriseCtx.metricNonce,
    extensions?.experiments?.ely_2_header,
    propertyArgs,
    transmit,
  ])

  const i18n = useI18n()
  const searchI18nText: SearchI18nText = {
    productsTitle: i18n('header.search.products.title'),
    searchResultsAria: i18n('header.search.results'),
    suggestionsTitle: i18n('header.search.suggestions.title'),
    correctionsTitle: i18n('header.search.corrections.title'),
    resultsAvailable: i18n('header.search.results.available'),
    lastResult: i18n('header.search.results.last'),
    fromText: i18n('product.price.from.text'),
  }

  const recentlySearchedI18nText = {
    recentlySearched: i18n('search.recent.items'),
    recentlySearchedText: i18n('search.recent.searched'),
    clearRecentSearches: i18n('product.list.facets.clearall.text'),
  }

  const i18nText: HeaderI18nText = {
    searchPlaceholder: i18n('header.search.placeholder'),
    logoAriaLabel: i18n('header.logo.arialabel', siteName),
    accountLabel: i18n('header.account.label'),
    basketLabel: i18n('header.basket.label'),
    searchButtonAriaLabel: i18n('header.search.button.arialabel'),
    menuButtonAriaLabel: i18n('header.menu.button.arialabel'),
    closeButtonAriaLabel: i18n('general.modal.close.button.arialabel'),
    clearAriaLabel: i18n('input.clear.arialabel'),
    basketButtonAriaLabel: i18n('header.basket.menu.arialabel'),
    accountButtonAriaLabel: i18n('header.account.nav.arialabel'),
    headerAriaLabel: i18n('header.arialabel'),
    wishlistAriaLabel: i18n('account.wishlist.view'),
    slotText: [
      {
        text: i18n('header.slot1.text'),
        url: i18n('header.slot1.link'),
      },
      {
        text: i18n('header.slot2.text'),
        url: secondHeaderSlotUrl,
      },
      {
        text: i18n('header.wishlist.slot.text'),
        url: i18n('header.wishlist.slot.link'),
      },
    ],
  }

  const submenuI18nText: SubmenuI18nText = {
    sessionSettingsChangeText: i18n('header.submenu.button.change.text'),
    sessionSettingsYourSettingsAriaLabel: i18n(
      'header.sessionsettings.modal.header',
    ),
    sessionSettingsButtonOpenAriaLabel: i18n(
      'header.sessionsettings.button.open.arialabel',
    ),
    linkOneText: i18n('header.submenu.link1.text'),
    linkOneHref: i18n('header.submenu.link1.link'),
    linkTwoText: i18n('header.submenu.link2.text'),
    linkTwoHref: i18n('header.submenu.link2.link'),
    ariaLabel: i18n('header.submenu.arialabel'),
    slotText: submenuSlots
      ?.filter(
        (submenuSlot) =>
          submenuSlot.type === HeaderSlotType.LINK &&
          submenuSlot.textProperty &&
          submenuSlot.urlProperty,
      )
      .map((slot, index) => ({
        text:
          i18n(`header.${slot.textProperty}`) ||
          i18n(`header.submenu.link${index + 1}.text`) ||
          '',
        url:
          ('urlProperty' in slot ? i18n(`header.${slot.urlProperty}`) : '') ||
          i18n(`header.submenu.link${index + 1}.link`) ||
          '',
      })),
  }

  const flyoutI18nText: FlyoutMenuI18nText = {
    homeLabel: i18n('header.logo.arialabel', siteName),
    sectionHome: i18n('header.flyout.sectionhome.text'),
    linkWithChildrenLabel: i18n('header.flyout.linkwithchildren.arialabel'),
    close: i18n('general.modal.close.button.arialabel'),
    back: i18n('header.flyout.button.back.text'),
    wishlist: i18n('account.wishlist.view'),
    headerText: i18n('header.slot2.text'),
  }

  const headerDropdownI18nText = {
    closeButtonAriaLabel: i18n('general.modal.close.button.arialabel'),
  }

  const sessionSettingsModalI18nText = {
    header: i18n('header.sessionsettings.modal.header'),
    shippingLabel: i18n('header.sessionsettings.selector.shipping.to.label'),
    regionLabel: i18n('header.sessionsettings.selector.region.label'),
    currenciesLabel: i18n('header.sessionsettings.selector.currencies.label'),
    submitButtonText: i18n(
      'header.sessionsettings.selector.submit.button.text',
    ),
  }

  const setHeaderHeightContext = (height: number) => {
    const {
      value: [_, setHeaderHeight],
    } = EnterpriseCtx.headerHeightRef
    setHeaderHeight(height)
  }

  const renderAnnouncer = (ariaLive: AriaMessageType, message: string) => (
    <MessageArea aria-live={ariaLive} message={message} />
  )

  const onSearchSubmit = (searchText: string) => {
    window.location.href = `${Routes.Search}?search=${
      searchText.length > 0
        ? encodeURIComponent(sanitizeParameter(searchText) || '')
        : ''
    }`
  }

  const searchFocusCallback = (event: React.FocusEvent) => {
    event.stopPropagation()

    const argumentsObj = {}
    for (const [_, attribute] of Object.entries(event.target.attributes)) {
      argumentsObj[attribute.name] = attribute.value
    }

    transmit({
      type: 'columbo',
      payload: getColumboEventData({
        propertyArgs,
        argumentsObj,
        requestArgs: {
          client_timestamp: Math.round(Date.now() / 1000),
          url: window.location.href,
        },
        eventData: {
          type: 'input-focus',
          subtype: 'headerSearch_input',
          contents: [{ widget_id: 0 }],
        },
        nonce: EnterpriseCtx.metricNonce,
      }),
    })
  }

  const sessionSettingsModal = SessionSettingsModalFactory(
    sessionSettingsModalI18nText,
    countryDomainMap,
    currencies,
  )

  const selectedTopLevelNavigationItemCookieValue = enableThreeTierNav
    ? sanitizeCookie(SELECTED_TOP_LEVEL_NAVIGATION_ITEM_COOKIE_NAME)
    : undefined

  const selectedTopLevelNavigationItemIndex = props.header?.navigation.topLevel.findIndex(
    (item) =>
      selectedTopLevelNavigationItemCookieValue?.replaceAll('%2F', '/') ===
      item.link?.url,
  )

  const canRenderRecentSearches =
    enableRecentSearches ||
    experiments['web_search_bar_wave_01'] === 'v3|show_recent_searches'

  const headerProps: HeaderProps = {
    withBasket,
    ...{
      hideThreeTierTab,
      primaryNavImages,
      hideFocusOnLevelOneNav,
      selectedTopLevelNavigationItemIndex,
      headerPromotionUrl,
      headerPromotionUrlTwo,
      headerPromotionUrls,
      defaultLocale,
      i18nText,
      flyoutI18nText,
      headerDropdownI18nText,
      hasDynamicHeaderDropdown,
      hasWaterfallMenu,
      headerRef,
      enableThreeTierNav,
      showResponsiveSubMenuStoreLocator,
      enableStoreLocatorIconOnMobileHeader,
      enablePersistentSearch,
      enableMasonryGridMenu,
      headerHeight: props.headerHeight,
      enableRecentSearches: canRenderRecentSearches,
      enablePromotionalSearch: renderPromotionalSearch,
      recentlySearchedTitle: recentlySearchedI18nText.recentlySearched,
      recentlySearchedText: recentlySearchedI18nText.recentlySearchedText,
      clearRecentSearch: recentlySearchedI18nText.clearRecentSearches,
      isMobileApp: requestConfig.isMobileApp || false,
      useAlternateHeaderGrid: newHeaderUseAlternateGrid || false,
      enableAlternateNavGrid: enableAlternateNavGrid || false,
      navSubLevelPromotionList: navSubLevelPromotionList || [],
      navLevelThreePromotionList: navLevelThreePromotionList || [],
      isMobile: props.isMobile,
      userAgent: props.userAgent,
      accountNav: () => <AccountNav />,
      responsiveFlyoutMenuIcons: {
        store: responsiveFlyoutMenu_hasStoreIcon,
        wishlist: flyoutMenuShowWishlistIcon,
      },
      headerSearch: {
        searchI18nText,
        renderAnnouncer,
        InstantSearchInjector,
        vipPriceEnabled,
        enableTrendingSearch: renderTrendingSearches,
        onSubmit: onSearchSubmit,
        autocompleteLink: `${Routes.Search}?autocomplete=`,
        currency: EnterpriseCtx.currency,
        shippingDestination: EnterpriseCtx.shippingDestination?.code,
        onFocus: searchFocusCallback,
        showExposedSearch: showExposedSearchBarOnMobile || false,
        showPowerReview: showPowerReview || false,
        promotionalProducts:
          props.header?.searchBarConfiguration.promotionalProducts,
        trendingTerms:
          props.header?.searchBarConfiguration?.trendingSearches?.[0].content,
      },
      submenu: {
        sessionSettingsModal,
        flagPath: props.flagPath,
        sessionSettingsTriggerText: `${EnterpriseCtx.subsite} - ${EnterpriseCtx.currency}`,
        showSessionSettings: hasSessionSettings,
        i18nText: submenuI18nText,
        showLinkOne: showResponsiveSubMenuFirstLink,
        showLinkTwo: showResponsiveSubMenuSecondLink,
        showLinkThree: showResponsiveSubMenuThirdLink,
        showLinkFour: showResponsiveSubMenuFourthLink,
        submenuSlots: submenuSlots || [],
      },
      headerSlots: headerSlots || [],
      setHeight: setHeaderHeightContext,
      navigationTree: props.header,
      logoLink: props.header?.homepage?.path ?? '/',
      logoOnClick: clearSelectedTopLevelNavigationItemOnLogoClick
        ? removeSelectedTopLevelNavigationItem
        : undefined,
      brands: {
        brandsData: { brands: props?.brands },
        i18nText: {
          viewAllBrands: i18n('header.brands.view.all.text'),
          navAriaLabel: i18n('header.brands.nav.aria.label'),
        },
        viewAllBrandsLink: Routes.Brands,
      },
      sticky: props.stickyHeader,
      routes: {
        accountHome: Routes.AccountHome,
        basket: Routes.Basket,
        listExtension: LIST_EXTENSION,
        wishlist: Routes.Wishlists,
      },
    },
    ...(props.wishlistNotification && {
      wishlistNotification: WishlistNotification,
    }),
    selectTopLevelNavigationItem: enableThreeTierNav
      ? selectTopLevelNavigationItem
      : undefined,
    navigationOnClickCallback: (event: NavigationEventData) =>
      props.header?.eventSchema &&
      Object.entries(props.header.eventSchema).length > 0 &&
      globalGA4(
        props.header?.eventSchema && props.header?.eventSchema?.navigation,
        {
          category: event.selectedTopLevelNavigation,
          sub_category: event.selectedSecondLevelNavigation,
          department: event.selectedThirdLevelNavigation,
        },
      ),
  }

  return (
    <React.Fragment>
      <SkipLink />
      {newHeader ? (
        <NewHeader {...headerProps} />
      ) : (
        <Header {...headerProps} data-testid="header" />
      )}
      <WidgetBanner
        userAgent={props.userAgent}
        showStripBanner={props.showStripBanner}
        widgetData={{ header: props.header }}
        pageType={props.pageType}
      />
      <SessionSettingsToast />
    </React.Fragment>
  )
}

const getHeaderData = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  config: { enablePromotionalSearch: boolean; enableTrendingSearch: boolean },
) => {
  const { data } = await apolloClient.query({
    query: HEADER_QUERY,
    variables: {
      concessionCode: '',
      concessionEnabled: false,
      enablePromotionalSearch: config.enablePromotionalSearch,
      enableTrendingSearch: config.enableTrendingSearch,
    },
  })
  return data
}

const store = createStore<
  {
    brand: string
    subsite: string
    apolloClient: ApolloClient<NormalizedCacheObject>
    config: { enablePromotionalSearch: boolean; enableTrendingSearch: boolean }
  },
  { header: any; brands: any }
>({
  key: (args) =>
    `graphql:core:${pkg.version}:${args.brand}:${args.subsite}:Header`,
  ttlSeconds: 300,
  fetchMode: FetchMode.BACKGROUND_IF_EXPIRED,
  lookup: async (args) => getHeaderData(args.apolloClient, args.config),
})

HeaderGroup.getInitialProps = withCacheConfiguration<
  HeaderGroupInitialProps,
  ESIRequestContext<HeaderGroupInitialProps & HeaderGroupProps>
>(async (context) => {
  const { siteDefinition } = context.config.publicRuntimeConfig

  const nextConfig = getConfig() as NextConfig
  const request = context.req as EnterpriseRequest
  const config = await loadConfiguration(nextConfig, request)

  const { publicRuntimeConfig } = config
  const { siteConfig } = publicRuntimeConfig

  const data = context.esi
    ? await getHeaderData(context.apolloClient, {
        enablePromotionalSearch: siteConfig.enablePromotionalSearch || false,
        enableTrendingSearch: siteConfig.enableTrendingSearch || false,
      })
    : await store.get({
        brand: siteDefinition.brand,
        subsite: siteDefinition.subsite,
        apolloClient: context.apolloClient,
        config: {
          enablePromotionalSearch: siteConfig.enablePromotionalSearch || false,
          enableTrendingSearch: siteConfig.enableTrendingSearch || false,
        },
      })

  const eventSchemaType = context.config?.dataLayerMap ?? {}

  return {
    ...context.props,
    header: { ...data.header, eventSchema: eventSchemaType ?? {} },
    brands: data.brands,
    cache: {
      ttl: 600,
      grace: 3600,
      config: {
        domain: siteDefinition.domain,
      },
    },
  }
})

const HeaderGroupESI = withESIWrapper(
  HeaderGroup,
  'enableLayoutESI',
  'ENABLE_LAYOUT_ESI',
)

export { HeaderGroup }

export default HeaderGroupESI
