import { cacheControl } from '@thg-commerce/enterprise-server'

export interface PageCacheConfiguration {
  tags?: string[]
  ttl?: number
  grace?: number
  vary?: string[]
  experiments?: string[]
  config: {
    domain: string
  }
}

const VARNISH_TAGS_HEADER = 'xkey'
const FASTLY_TAGS_HEADER = 'surrogate-key'

type GetPageProps<Props, Context> = (
  context: Context,
) => Promise<Props & { cache?: PageCacheConfiguration }>
type PagePropsContext = {
  req?: {
    brand?: string
    subsite?: string
    headers: { [key: string]: string | string[] | undefined }
  }
  res: {
    setHeader: (name: string, value: string) => void
  }
}

export const withCacheConfiguration = <
  Props extends object,
  Context extends PagePropsContext
>(
  getPageProps: GetPageProps<Props, Context>,
): ((context: Context) => Promise<Props>) => {
  return async (context) => {
    const props = await getPageProps(context)

    if (
      !context.res ||
      !context.req?.headers?.hasOwnProperty('fastly-client')
    ) {
      if (props.cache) {
        delete props.cache
      }
      return props
    }

    if (!props.cache) {
      return props
    }

    const cache = { ...props.cache }

    if (!cache.tags) {
      cache.tags = []
    }

    cache.tags.push(
      'Header',
      'Footer',
      `ServiceName:${process.env.SERVICE_NAME}`,
      `ServiceVersion:${process.env.SERVICE_VERSION}`,
      ...(context.req
        ? [`Brand:${context.req.brand}`, `Subsite:${context.req.subsite}`]
        : []),
    )

    const tagsHeader = context.req.headers['fastly-client']
      ? FASTLY_TAGS_HEADER
      : VARNISH_TAGS_HEADER

    context.res.setHeader(tagsHeader, cache.tags.join(' '))

    const cacheControlHeaders = cacheControl(cache)
    if (cacheControlHeaders) {
      context.res.setHeader('Cache-Control', cacheControlHeaders)
    }

    if (props.cache) {
      delete props.cache
    }
    if (cache.vary && cache.vary.length > 0) {
      context.res.setHeader('Vary', cache.vary.join(','))
    }
    return props
  }
}
